import { useState } from "react";
import {
  createStyles,
  Avatar,
  UnstyledButton,
  Group,
  Text,
  Menu,
  rem,
  Stack,
} from "@mantine/core";
import {
  IconLogout,
  IconChevronDown,
  IconUser,
  IconX,
} from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { PRIMARY_COLOR } from "../utils/config";
import { useAuthContext } from "../store/AuthContext";
import { getInitials } from "../utils/fns";

const useStyles = createStyles((theme) => ({
  header: {
    paddingTop: theme.spacing.sm,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[2]
    }`,
    marginBottom: rem(120),
  },

  mainSection: {
    paddingBottom: theme.spacing.sm,
  },

  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    },

    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("xs")]: {
      display: "none",
    },
  },

  userActive: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },

  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: "0 !important",
  },

  tab: {
    fontWeight: 500,
    height: rem(38),
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    },

    "&[data-active]": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      borderColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[2],
    },
  },
}));

const UserNavIcon = () => {
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { classes, cx } = useStyles();
  const { user, logout } = useAuthContext();
  return (
    <Menu
      width={260}
      position="bottom-end"
      transitionProps={{ transition: "pop-top-right" }}
      onClose={() => setUserMenuOpened(false)}
      onOpen={() => setUserMenuOpened(true)}
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
        >
          <Group spacing={7}>
            <Avatar color={PRIMARY_COLOR} size={32} radius="xl">
              {getInitials(user && user.name ? user.name : "N")}
            </Avatar>
            <IconChevronDown size={rem(12)} stroke={1.5} />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Stack m="sm">
          <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
            {user && user.name ? user.name : "-"}
          </Text>
          <Text size="xs" sx={{ lineHeight: 1 }} mr={3} color="gray">
            {user && user.email ? user.email : "-"}
          </Text>
        </Stack>

        <Menu.Divider />
        <Menu.Item disabled icon={<IconUser size="0.9rem" stroke={1.5} />}>
          My Profile
        </Menu.Item>
        <Menu.Item
          icon={<IconLogout size="0.9rem" color="red" stroke={1.5} />}
          onClick={async () => {
            try {
              console.log("Bye . . .");
              logout();
            } catch (error: any) {
              notifications.show({
                message:
                  error && error.data
                    ? error.data
                    : "error signing you you in, please try again",
                color: "red",
                icon: <IconX />,
              });
            }
          }}
          c="red"
        >
          Log out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserNavIcon;
