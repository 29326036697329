import { Group, Text, Title, Tooltip } from "@mantine/core";
import { resolveInfoItem } from "./HealthDetails";
import { IconInfoCircle } from "@tabler/icons-react";

interface IItemKeyInsights {
  title: string;
  quantity: string;
  isTooltip: boolean;
  infra: string;
}

const ItemKeyInsights = ({
  title,
  quantity,
  isTooltip,
  infra,
}: IItemKeyInsights) => {
  return (
    <Group position="apart">
      <Group>
        <Text fz="sm">{title}</Text>
        {isTooltip && (
          <Tooltip label={resolveInfoItem(infra)} multiline withArrow>
            <IconInfoCircle />
          </Tooltip>
        )}
      </Group>
      <Title order={5}>{quantity}</Title>
    </Group>
  );
};

export default ItemKeyInsights;
