import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import { fShortenNumber } from "../utils/formatNumber";
import { useMantineTheme } from "@mantine/core";
import { IChartType, ILineChartData } from "../utils/types";
import { TCurrentCard } from "../sections/Config";

interface ISummaryChart {
  dataset: number[];
  categories: string[];
  yaxisLabel: string;
  xAxisLabel: string;
  type: IChartType;
  colors: string[];
  currentCard: TCurrentCard;
  cardSixDataSet: ILineChartData[];
  nameOfSeries: string;
}

const convertToTreeMap = (
  keys: string[],
  values: number[]
): { x: string; y: number }[] => {
  if (keys.length !== values.length) return [];
  return keys.map((key, idx) => ({ x: key, y: values[idx] }));
};

function SectorSummaryColumnChart({
  dataset,
  yaxisLabel,
  xAxisLabel,
  categories,
  nameOfSeries,
  type,
  colors,
  currentCard,
  cardSixDataSet,
}: ISummaryChart) {
  const theme = useMantineTheme();
  const apexOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: true,
      },
      type: type,
      foreColor: theme.colors.gray[6],
      fontFamily: theme.fontFamily,
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.04,
        },
      },
      active: {
        filter: {
          type: "darken",
          value: 0.88,
        },
      },
    },
    fill: {
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    grid: {
      strokeDashArray: 3,
      borderColor: theme.colors.dark[4],
    },
    markers: {
      size: 0,
      strokeColors: theme.white,
    },
    legend: {
      show: true,
    },
    // RGB to be used in the colors array below 62,138,139
    colors: colors,

    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
      formatter: function (val, opt) {
        if (type === "treemap") {
          return `${[val + ": " + fShortenNumber(opt.value)]}`;
        }
        return typeof val === "number" ? fShortenNumber(val) : val.toString();
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: xAxisLabel,
      },
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      followCursor: true,
      enabled: true,
      x: {
        show: false,
      },
      y: {
        formatter: (value: any) => fShortenNumber(value),
      },
      marker: {
        show: false,
      },
      // custom: ({ series, seriesIndex, dataPointIndex }) => {
      //   const category = finalData[dataPointIndex].category;
      //   const value = finalData[dataPointIndex].value;
      //   return `<div className="apexcharts-tooltip-custom p-2 bold">
      //             ${category}: ${fShortenNumber(value)}
      //           </div>`;
      // },
    },
    yaxis: {
      title: {
        text: yaxisLabel,
      },
      labels: {
        formatter: function (val: any) {
          return fShortenNumber(val);
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
        borderRadius: 0,
      },
    },
    responsive: [
      {
        breakpoint: 767.98,
        options: {
          chart: {
            height: 500,
          },
          legend: {
            position: "bottom",
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      {currentCard === "Card 6" ? (
        <ReactApexChart
          options={apexOptions}
          series={cardSixDataSet}
          type={type}
          height={450}
        />
      ) : (
        <ReactApexChart
          options={apexOptions}
          series={[
            {
              name: nameOfSeries,
              data:
                type === "treemap"
                  ? convertToTreeMap(categories, dataset)
                  : dataset,
            },
          ]}
          type={type}
          height={450}
        />
      )}
    </div>
  );
}

export default SectorSummaryColumnChart;
