import { useState } from "react";
import { useForm } from "@mantine/form";
import {
  TextInput,
  Grid,
  Button,
  Group,
  Drawer,
  useMantineTheme,
  rem,
  Text,
  Chip,
} from "@mantine/core";
import {
  Dropzone,
  IMAGE_MIME_TYPE,
  PDF_MIME_TYPE,
  MIME_TYPES,
} from "@mantine/dropzone";
import { DOCUMENTS_KEY, MUTATE_DOCUMENT_KEY } from "../utils/types";
import {
  IconCheck,
  IconFileInvoice,
  IconFileSpreadsheet,
  IconFiles,
  IconPdf,
  IconPhoto,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { useAuthContext } from "../store/AuthContext";
import { INewDataProps } from "./NewSector";
import RichtextEditor from "../components/RichTextEditor";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { notifications } from "@mantine/notifications";

export function getIcon(mimeType: string): JSX.Element {
  if (mimeType.includes("image")) {
    return <IconPhoto size="3.2rem" color="green" />;
  } else if (mimeType.includes("pdf")) {
    return <IconPdf size="3.2rem" color="red" />;
  } else if (mimeType.includes("word")) {
    return <IconFileInvoice size="3.2rem" color="blue" />;
  } else if (
    [MIME_TYPES.xls.toString(), MIME_TYPES.xlsx.toString()].includes(mimeType)
  ) {
    return <IconFileSpreadsheet size="3.2rem" color="green" />;
  }
  return <IconFiles />;
}

const NewDocument = ({ closeDrawer, drawerOpened }: INewDataProps) => {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (newDoc: FormData) => {
      await axiosInstance.post(MUTATE_DOCUMENT_KEY, newDoc, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const [files, setFiles] = useState<File[]>([]);
  //const [projectMedia, setProjectMedia] = useState<INewDoc[]>([]);
  const theme = useMantineTheme();
  const [text, setText] = useState<string>("");

  const mediaForm = useForm({
    initialValues: {
      title: "",
      slug: "",
      tags: "",
      publication_year: "",
      publisher: "",
      content_type: "",
      language: "",
      access_level: "",
      date_posted: "",
      original_source: "",
      devolved_function: "",
      status: "",
    },
    validate: {
      title: (value) =>
        value.length < 6 ? "title must have at least 6 letters" : null,
      tags: (value) => (value.length === 0 ? "provide at least one tag" : null),
    },
  });

  // const handleFileRemove = (path: string) =>
  //   setFiles(files.filter((file) => file.path !== path));

  // const previews = files.map((file, index) => {
  //   return (
  //     <MediaPreview
  //       key={index}
  //       icon={getIcon(file.type)}
  //       name={file.name}
  //       size={formatFileSize(file.size)}
  //       path={file.path ? file.path : ""}
  //       handleRemove={handleFileRemove}
  //     />
  //   );
  // });

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="md"
      onClose={closeDrawer}
      title="Add Documents"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={mediaForm.onSubmit(async (values) => {
          const { publisher, tags, title } = values;

          const formData = new FormData();
          files.forEach((file, idx) => formData.append(`media[${idx}]`, file));
          formData.append("title", title);
          formData.append("publisher", publisher);
          formData.append("description", text);
          formData.append("tags", tags);

          // setProjectMedia((prev) => [
          //   ...prev,
          //   {
          //     access_level,
          //     content_type,
          //     date_posted,
          //     description: text,
          //     devolved_function,
          //     language,
          //     original_source,
          //     publication_year,
          //     publisher,
          //     slug,
          //     status,
          //     tags,
          //     title,
          //     media: files,
          //   },
          // ]);

          try {
            await mutation.mutate(formData, {
              onSuccess: () => {
                notifications.show({
                  title: "Success",
                  message: "Documents Added",
                  icon: <IconCheck />,
                  color: "teal",
                });
                mediaForm.reset();
                closeDrawer();
              },
              onSettled() {
                queryClient.invalidateQueries([DOCUMENTS_KEY]);
              },
              onError: (error: any) => {
                notifications.show({
                  title: "Error",
                  message:
                    error && error.data
                      ? error.data
                      : "An error occured, try again",
                  icon: <IconX />,
                  color: "red",
                });
              },
            });
          } catch (error) {}
        })}
      >
        <Grid>
          <Grid.Col span={12}>
            <Dropzone
              onDrop={setFiles}
              onReject={(files) => console.log("rejected files", files)}
              maxSize={3 * 1024 ** 2}
              accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
            >
              <Group
                position="center"
                spacing="xl"
                style={{ minHeight: rem(120), pointerEvents: "none" }}
              >
                <Dropzone.Accept>
                  <IconUpload
                    size="3.2rem"
                    stroke={1.5}
                    color={
                      theme.colors[theme.primaryColor][
                        theme.colorScheme === "dark" ? 4 : 6
                      ]
                    }
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    size="3.2rem"
                    stroke={1.5}
                    color={
                      theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                    }
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconPhoto size="3.2rem" stroke={1.5} />
                </Dropzone.Idle>

                <div>
                  <Text size="xl" inline>
                    Drag and drop your files here or browse your device
                  </Text>
                  <Text size="sm" color="dimmed" inline mt={7}>
                    {files.length === 0
                      ? "Attach as many files as you like, each file should not exceed 5mb"
                      : `${files.length} file${
                          files.length > 1 ? `s` : ""
                        } uploaded`}
                  </Text>
                </div>
              </Group>
            </Dropzone>
            {/* <SimpleGrid
              cols={1}
              breakpoints={[{ maxWidth: "sm", cols: 1 }]}
              mt={previews.length > 0 ? "xl" : 0}
            >
              {previews}
            </SimpleGrid> */}
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Resource Title"
              placeholder="Resource Title"
              {...mediaForm.getInputProps("title")}
            />
          </Grid.Col>
          {/* <Grid.Col span={12}>
            <TextInput
              label="Resource Slug"
              placeholder="Resource Slug"
              {...mediaForm.getInputProps("slug")}
            />
          </Grid.Col> */}
          <Grid.Col span={12}>
            <TextInput
              label="Resource Topics / Tags"
              description="Enter as many tags separated by a comma"
              placeholder="Resource topics"
              {...mediaForm.getInputProps("tags")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group spacing="xs">
              {mediaForm.values.tags.split(",").map((val, idx) => (
                <Chip key={idx} size="xs" variant="filled">
                  {val}
                </Chip>
              ))}
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <RichtextEditor
              setText={setText}
              placeholder="Resource Description"
            />
          </Grid.Col>
          {/* <Grid.Col sm={12} md={6}>
            <Select
              label="Publication Year"
              placeholder="Publication Year"
              {...mediaForm.getInputProps("publication_year")}
              data={generateYearRanges().map((year) => ({
                value: year,
                label: year,
              }))}
            />
          </Grid.Col> */}
          {/* <Grid.Col sm={12} md={6}>
            <TextInput
              label="Date Posted"
              placeholder="Date Posted"
              type="date"
              {...mediaForm.getInputProps("date_posted")}
            />
          </Grid.Col> */}
          <Grid.Col span={12}>
            <TextInput
              label="Publisher"
              placeholder="Publisher"
              {...mediaForm.getInputProps("publisher")}
            />
          </Grid.Col>
          {/* <Grid.Col sm={12} md={6}>
            <TextInput
              label="Original Source"
              placeholder="Original Source"
              {...mediaForm.getInputProps("original_source")}
            />
          </Grid.Col> */}
          {/* <Grid.Col sm={12} md={6}>
            <Select
              label="Content Type"
              placeholder="Content Type"
              {...mediaForm.getInputProps("content_type")}
              data={[
                { value: "pdf", label: "PDF" },
                { value: "image", label: "Images" },
              ]}
            />
          </Grid.Col> */}
          {/* <Grid.Col sm={12} md={6}>
                  <TextInput
                    mt="md"
                    label="Related County"
                    placeholder="Related County"
                    {...mediaForm.getInputProps('relatedCounty')}
                  />
                </Grid.Col> */}
          {/* <Grid.Col sm={12} md={6}>
            <Select
              label="Language"
              placeholder="Language"
              {...mediaForm.getInputProps("language")}
              data={[
                { value: "english", label: "English" },
                { value: "kiswahili", label: "Kiswahili" },
                { value: "other", label: "Other" },
              ]}
            />
          </Grid.Col> */}
          {/* <Grid.Col sm={12} md={6}>
            <Select
              label="Devolved Function"
              placeholder="Devolved Function"
              {...mediaForm.getInputProps("devolved_function")}
              data={[
                { value: "", label: "" },
                { value: "", label: "" },
              ]}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <Select
              label="Access Level"
              placeholder="Access Level"
              {...mediaForm.getInputProps("access_level")}
              data={[
                { value: "", label: "" },
                { value: "", label: "" },
              ]}
            />
          </Grid.Col> */}
          {/* <Grid.Col sm={12} md={12}>
            <Select
              label="Status"
              placeholder="Status"
              {...mediaForm.getInputProps("status")}
              data={[
                { value: "", label: "" },
                { value: "", label: "" },
              ]}
            />
          </Grid.Col> */}
          <Grid.Col sm={12} md={12}>
            <Group position="right">
              <Button
                type="submit"
                variant="outline"
                loading={mutation.isLoading}
              >
                Add
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Drawer>
  );
};

export default NewDocument;
