import { replace } from "lodash";
import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(n: number) {
  return numeral(n).format(Number.isInteger(n) ? "0,0" : "0,0.00");
}

export function fPercent(n: number) {
  return numeral(n / 100).format("0.0%");
}

export function fNumber(n: number) {
  return numeral(n).format();
}

export function fShortenNumber(n: number) {
  const num = Math.floor(n);
  if (num.toString().length > 3 && num.toString().length < 7) return fNumber(n);
  const shortened = replace(numeral(n).format("0.00a"), ".00", "");
  return shortened === "0" ? "-" : shortened;
}

export function fData(n: number) {
  return numeral(n).format("0.0 b");
}