import { useForm } from "@mantine/form";
import {
  TextInput,
  Grid,
  ColorInput,
  Button,
  Group,
  Drawer,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";

import {
  INewDepartment,
  DEPARTMENTS_KEY,
  IDepartment,
  MUTATE_DEPARTMENTS_KEY,
} from "../utils/types";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuthContext } from "../store/AuthContext";
import { INewDataProps } from "./NewSector";
import { useEffect } from "react";

export interface IUpdateDepartmentProps extends INewDataProps {
  foundDepartment: IDepartment | undefined;
}

const ViewDepartment = ({
  closeDrawer,
  drawerOpened,
  foundDepartment,
}: IUpdateDepartmentProps) => {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updatedDept: IDepartment) => {
      await axiosInstance.post(MUTATE_DEPARTMENTS_KEY, updatedDept, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const form = useForm({
    initialValues: {
      name: "",
      color: "",
    },
    validate: {
      name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      color: (value) =>
        value.length < 4 ? "Choose a color for this department" : null,
    },
  });

  useEffect(() => {
    if (foundDepartment) {
      form.setValues({
        name: foundDepartment.name ? foundDepartment.name : "",
        color: foundDepartment.color ? foundDepartment.color : "#000000",
      });
    }
  }, [foundDepartment]);

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="xl"
      onClose={closeDrawer}
      title="Modify Department"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            if (foundDepartment) {
              await mutation.mutate(
                {
                  ...values,
                  id: foundDepartment.id,
                  created_at: foundDepartment.created_at,
                  updated_at: foundDepartment.updated_at,
                },
                {
                  onSuccess: () => {
                    notifications.show({
                      title: "Success",
                      message: "Department Updated",
                      icon: <IconCheck />,
                      color: "teal",
                    });
                    form.reset();
                    closeDrawer();
                  },
                  onSettled(data, error, variables, context) {
                    queryClient.invalidateQueries([DEPARTMENTS_KEY]);
                  },
                  onError: (
                    error: any,
                    variables: INewDepartment,
                    context: unknown
                  ) => {
                    notifications.show({
                      title: "Error",
                      message:
                        error && error.data
                          ? error.data
                          : "An error occured, try again",
                      icon: <IconX />,
                      color: "red",
                    });
                  },
                }
              );
            }
          } catch (error) {}
        })}
      >
        <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <ColorInput
              format="hex"
              withAsterisk
              label="Color"
              swatches={[
                "#25262b",
                "#868e96",
                "#fa5252",
                "#e64980",
                "#be4bdb",
                "#7950f2",
                "#4c6ef5",
                "#228be6",
                "#15aabf",
                "#12b886",
                "#40c057",
                "#82c91e",
                "#fab005",
                "#fd7e14",
              ]}
              {...form.getInputProps("color")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group position="right" spacing="xl">
              <Button
                leftIcon={<IconPlus size="1rem" />}
                type="submit"
                variant="filled"
                loading={mutation.isLoading}
              >
                Submit
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Drawer>
  );
};

export default ViewDepartment;
