import { Alert, Divider, Loader } from "@mantine/core";
import {
  IconAccessible,
  IconAlertCircle,
  IconBath,
  IconFlame,
  IconMail,
  IconMapPin,
  IconPhone,
} from "@tabler/icons-react";
import { IconBulb } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import useFetchSabasiData from "../hooks/useFetchSabasiData";
import { IHealthDataApiResponse, SABASI_DATA_KEY } from "../utils/types";
import { useAuthContext } from "../store/AuthContext";
import { getHealthData, getUtilities } from "../utils/fns";
import { DropletFilled2 } from "tabler-icons-react";

export interface IHealthDetails {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  healthCode: number;
}

export const getQuality = (rating: number): string => {
  switch (rating) {
    case 1:
      return "Very Poor";
    case 2:
      return "Poor";
    case 3:
      return "Fair";
    case 4:
      return "Good";
    case 5:
      return "Very Good";
    default:
      return "-";
  }
};

export const getRating = (infrastructure: string): number => {
  switch (infrastructure) {
    case "Very Poor":
      return 1;
    case "Poor":
      return 2;
    case "Fair":
      return 3;
    case "Good":
      return 4;
    case "Excellent":
      return 5;
    default:
      return 0;
  }
};
// Incinerator
const resolveIcon = (util: string): JSX.Element => {
  if (util === "Clean Water") {
    return <DropletFilled2 color="white" size="2rem" />;
  } else if (util === "Electricity") {
    return <IconBulb color="white" size="2rem" />;
  } else if (util === "Washrooms") {
    return <IconBath color="white" size="2rem" />;
  } else if (util === "Incinerator") {
    return <IconFlame color="white" size="2rem" />;
  } else {
    return <IconAccessible size="2rem" color="white" />;
  }
};

/**
 ${getHealthData(fetchedHealthData).utilities.includes(util)? "opacity-10": "opacity-20"}
 */

export const resolveHours = (hours: string): JSX.Element => {
  if (hours === "24 hours") {
    return (
      <div className="rounded bg-gray-900 p-4 max-h-4 flex flex-col justify-center items-center ml-8">
        <p className="text-white text-xs text-center">
          24 <br></br> Hrs
        </p>
      </div>
    );
  } else if (hours === "8 am - 5 pm") {
    return (
      <div className="rounded bg-gray-900 p-4 max-h-6 flex flex-col justify-center items-center ml-8 h-full">
        <p className="text-white text-xs text-center">
          <span className="block">8 AM</span>
          <span className="block">-</span>
          <span className="block">5 PM</span>
        </p>
      </div>
    );
  } else {
    return (
      <div className="rounded bg-gray-900 p-4 max-h-4 flex flex-col justify-center items-center ml-8">
        <p className="text-white text-xs text-center">{hours}</p>
      </div>
    );
  }
};

const handleNoContactInfo = (contactInfo: string): string => {
  if (contactInfo.trim() === "-:-") {
    return "No information";
  }
  return contactInfo;
};

export const resolveInfoItem = (infra: string): string => {
  if (infra.toLowerCase() === "very poor") {
    return "Hospital infrastructure is severely lacking, outdated, and inadequate in various aspects.";
  } else if (infra.toLowerCase() === "poor") {
    return "Infrastructure quality is below acceptable standards, with noticeable deficiencies.";
  } else if (infra.toLowerCase() === "fair") {
    return "The hospital's infrastructure meets basic requirements but may have some issues or areas for improvement.";
  } else if (infra.toLowerCase() === "good") {
    return "Infrastructure quality is generally satisfactory, with well-maintained facilities and modern equipment.";
  } else if (infra.toLowerCase() === "excellent") {
    return " Hospital infrastructure is of high quality, state-of-the-art, and well-maintained";
  } else {
    return "-";
  }
};

const getUtils = (
  fetchedHealthData: IHealthDataApiResponse | undefined,
  util: string
) => {
  return !getHealthData(fetchedHealthData).utilities.includes(util);
};

const HealthDetails = ({ setIsVisible, healthCode }: IHealthDetails) => {
  const { token } = useAuthContext();
  const {
    fetchedData: fetchedHealthData,
    isError: isHealthDataError,
    // isFetching: isFetchingDepartments,
    isLoading: isLoadingHealthData,
  } = useFetchSabasiData<IHealthDataApiResponse>(
    `${SABASI_DATA_KEY}/${healthCode}`,
    token ? token : ""
  );

  if (isHealthDataError) {
    return (
      <Alert icon={<IconAlertCircle size="1rem" />} title="Error!" color="red">
        Error fetching facility data
      </Alert>
    );
  }

  return isLoadingHealthData ? (
    <Loader size="sm" />
  ) : (
    <div className="flex flex-col bg-white p-6">
      <div className="grid justify-items-end -mt-4 -mr-4">
        <IconX
          size={16}
          onClick={() => setIsVisible(false)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h3 className="mt-0 text-lg">
            {getHealthData(fetchedHealthData).facility}
          </h3>
          <p className="mt-0 text-sm">
            {getHealthData(fetchedHealthData).type}
          </p>
        </div>
        {resolveHours(getHealthData(fetchedHealthData).open_hours)}
      </div>
      <div className="mt-0 mb-2">
        <hr className="h-px bg-gray-300 border-0"></hr>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <div className="text-sm">
            {" "}
            Bed <br />
            Capacity{" "}
            <div className="text-xl font-bold mt-4">
              {getHealthData(fetchedHealthData).beds}
            </div>
          </div>
          <Divider orientation="vertical" />
          <div className="text-sm">
            {" "}
            Infrastructure <br />
            Quality{" "}
            <div className="text-xl font-bold mt-4">
              {getHealthData(fetchedHealthData).infrastructure}
            </div>
          </div>
          <Divider orientation="vertical" />
          <div className="text-sm">
            {" "}
            Distance <br />
            Coverage{" "}
            <div className="text-xl font-bold mt-4">
              {getHealthData(fetchedHealthData).population}
            </div>
          </div>
        </div>
        <div className="mt-2 mb-2">
          <hr className="h-px bg-gray-300 border-0 "></hr>
        </div>
        <div className="text-base mb-4 font-semibold">Available Utilities</div>
        <div className="grid grid-cols-4">
          {getUtilities("Electricity,Clean Water,Washrooms,Incinerator")
            .sort((a, b) => {
              const aIsActive = getUtils(fetchedHealthData, a) ? 1 : 0;
              const bIsActive = getUtils(fetchedHealthData, b) ? 1 : 0;

              // Sort in descending order based on whether the utility is active
              return aIsActive - bIsActive;
            })
            .map((util, idx) => (
              <div
                className={`${
                  getUtils(fetchedHealthData, util) && "opacity-20"
                } flex flex-col items-center`}
                key={idx}
              >
                <div
                  className={`bg-gray-900 h-auto p-3 flex items-center rounded`}
                >
                  {" "}
                  {resolveIcon(util)}
                </div>{" "}
                <p>{util}</p>
              </div>
            ))}
        </div>
        <div className="mt-0 mb-2">
          <hr className="h-px bg-gray-300 border-0 "></hr>
        </div>
        <div>
          <div className="text-base mb-2 font-semibold">Staff Cadres </div>
          <div className="text-sm">
            {getHealthData(fetchedHealthData).cadres}
          </div>
        </div>
        <div className="mt-2 mb-2">
          <hr className="h-px bg-gray-300 border-0 "></hr>
        </div>
        <div>
          <div className="text-base mb-2 font-semibold">Contacts </div>
          <div className="text-base flex flex-row mb-2 ">
            <div className="mr-4">
              <IconPhone />{" "}
            </div>
            {handleNoContactInfo(getHealthData(fetchedHealthData).phone)}
          </div>
          <div className="text-base flex flex-row mb-2 ">
            <div className="mr-4">
              <IconMail />{" "}
            </div>
            {handleNoContactInfo(getHealthData(fetchedHealthData).email)}
          </div>
          <div className="text-base flex flex-row mb-2 ">
            <div className="mr-4">
              <IconMapPin />{" "}
            </div>
            {getHealthData(fetchedHealthData).ward}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthDetails;
