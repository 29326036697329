import { createElement, useEffect, useState } from "react";
import * as Icons from "tabler-icons-react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid as VirtualizedGrid } from "react-window";
import { useForm } from "@mantine/form";
import { TextInput, Button, Grid, ColorInput, Drawer } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import { INewSector, SECTORS_KEY } from "../utils/types";
import { IconCheck, IconX, IconSend } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { PRIMARY_COLOR } from "../utils/config";
import { useAuthContext } from "../store/AuthContext";

const GUTTER_SIZE = 10;

export interface INewDataProps {
  drawerOpened: boolean;
  closeDrawer: () => void;
}

const NewSector = ({ closeDrawer, drawerOpened }: INewDataProps) => {
  const [filter, setFilter] = useState("");
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const [icons, setIcons] = useState(Object.entries(Icons));
  const [activeIcon, setActiveIcon] = useState<{
    name: string;
    icon: Icons.Icon;
  }>();
  const mutation = useMutation({
    mutationFn: async (newSector: INewSector) => {
      await axiosInstance.post(`/cms${SECTORS_KEY}`, newSector, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const form = useForm({
    initialValues: {
      name: "",
      color: "",
      icon: "",
    },
    validate: {
      name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      color: (value) =>
        value.length < 4 ? "Choose a color for this sector" : null,
    },
  });

  useEffect(() => {
    setIcons(
      Object.entries(Icons).filter(([name]) =>
        name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      )
    );
  }, [filter]);

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="xl"
      onClose={closeDrawer}
      title="Add Sector"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            await mutation.mutate(
              {
                name: values.name,
                color: values.color,
                icon: activeIcon ? activeIcon.name : "",
              },
              {
                onSuccess: () => {
                  form.reset();
                  closeDrawer();
                  notifications.show({
                    title: "Success",
                    message: "Sector created",
                    icon: <IconCheck />,
                    color: PRIMARY_COLOR,
                  });
                },
                onSettled(data, error, variables, context) {
                  queryClient.invalidateQueries([SECTORS_KEY]);
                },
                onError: (
                  error: any,
                  variables: INewSector,
                  context: unknown
                ) => {
                  notifications.show({
                    title: "Error",
                    message:
                      error && error.data
                        ? error.data
                        : "An error occured, try again",
                    icon: <IconX />,
                    color: "red",
                  });
                },
              }
            );
          } catch (error) {}
        })}
      >
        <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <ColorInput
              format="hex"
              withAsterisk
              label="Color"
              swatches={[
                "#25262b",
                "#868e96",
                "#fa5252",
                "#e64980",
                "#be4bdb",
                "#7950f2",
                "#4c6ef5",
                "#228be6",
                "#15aabf",
                "#12b886",
                "#40c057",
                "#82c91e",
                "#fab005",
                "#fd7e14",
              ]}
              {...form.getInputProps("color")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={12} lg={12}>
            <TextInput
              label="Icon"
              placeholder="Search for icons..."
              value={filter}
              onChange={(event) => setFilter(event.currentTarget.value)}
              sx={{
                marginBottom: "2rem",
              }}
            />
            <AutoSizer>
              {({ width }: { width: number }) => (
                <>
                  {/*@ts-ignore*/}
                  <VirtualizedGrid
                    columnCount={4}
                    columnWidth={width / 4 - GUTTER_SIZE / 2}
                    height={300}
                    rowCount={Math.ceil(icons.length / 4)}
                    rowHeight={94}
                    width={width}
                    itemData={icons}
                  >
                    {({ data, columnIndex, rowIndex, style }) => {
                      const index = rowIndex * 4 + columnIndex;

                      if (!data[index]) {
                        return null;
                      }

                      return (
                        <div
                          className={`icon-preview ${
                            activeIcon &&
                            activeIcon.name === data[index][0] &&
                            "active"
                          }`}
                          onClick={() =>
                            setActiveIcon({
                              name: data[index][0],
                              icon: data[index][1],
                            })
                          }
                          style={{
                            ...style,
                            left: Number(style.left) + GUTTER_SIZE,
                            top: Number(style.top) + GUTTER_SIZE,
                            width: Number(style.width) - GUTTER_SIZE,
                            height: Number(style.height) - GUTTER_SIZE,
                          }}
                        >
                          {createElement(data[index][1], {
                            size: 32,
                            color:
                              form.values.color.length > 0
                                ? form.values.color
                                : "black",
                          })}
                          <div>{data[index][0]}</div>
                        </div>
                      );
                    }}
                  </VirtualizedGrid>
                  <div className="flex flex-row items-center gap-6 mt-4">
                    <Button
                      leftIcon={<IconSend size="1rem" />}
                      type="submit"
                      variant="filled"
                      loading={mutation.isLoading}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </AutoSizer>
          </Grid.Col>
        </Grid>
      </form>
    </Drawer>
  );
};

export default NewSector;
