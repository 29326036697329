import {
  UnstyledButton,
  UnstyledButtonProps,
  Group,
  Text,
  createStyles,
  Tooltip,
} from "@mantine/core";
import { IconX } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  user: {
    display: "block",
    width: "100%",
    padding: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    textDecoration: "none",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.gray[0],
    },
  },
}));

export interface MediaPreviewProps extends UnstyledButtonProps {
  name: string;
  size: string;
  path: string;
  icon: JSX.Element;
  handleRemove: (path: string) => void;
  isUrl: boolean;
  link?: string;
  labelDelete?: boolean;
}

export function MediaPreview({
  name,
  size,
  path,
  icon,
  isUrl,
  link,
  labelDelete,
  handleRemove,
  ...others
}: MediaPreviewProps) {
  const { classes } = useStyles();

  return isUrl ? (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.user}
      {...others}
    >
      <Group>
        {icon}

        <div style={{ flex: 1 }}>
          <Text size="sm" weight={500}>
            {name}
          </Text>

          <Text color="dimmed" size="xs">
            {size}
          </Text>
        </div>

        {!isUrl && (
          <IconX
            size="0.9rem"
            stroke={1.5}
            onClick={(e) => handleRemove(path)}
          />
        )}
      </Group>
    </a>
  ) : (
    <UnstyledButton className={classes.user} {...others}>
      <Group>
        {icon}

        <div style={{ flex: 1 }}>
          <Text size="sm" weight={500}>
            {name}
          </Text>

          <Text color="dimmed" size="xs">
            {size}
          </Text>
        </div>

        <Tooltip
          label={
            labelDelete
              ? "Delete Media (this action cannot be undone)"
              : "Remove"
          }
        >
          <IconX
            size="0.9rem"
            stroke={1.5}
            onClick={(e) => handleRemove(path)}
          />
        </Tooltip>
      </Group>
    </UnstyledButton>
  );
}
