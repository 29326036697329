import { useQuery } from "@tanstack/react-query";
import { fetchApiData } from "../utils/fns";
import { IRawIndicatorApiResponse } from "../utils/types";

const useFetchIndicators = (url: string,
    token: string, indicator_id: string ) => {
  const {
    isFetching,
    isLoading,
    data: fetchedIndicators,
    isError,
  } = useQuery([url], {
    queryFn: async () =>
      fetchApiData<IRawIndicatorApiResponse>(
        `${url}`,
        token
      ),
      enabled: indicator_id.length > 0
      //enabled: token.length > 0
  })

  return { isFetching, isLoading, fetchedIndicators, isError };
};

export default useFetchIndicators;
