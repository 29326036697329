import { useQuery } from "@tanstack/react-query";
import { fetchApiData } from "../utils/fns";
import { IManyItemsApi } from "../utils/types";

const useFetchManyThings = (url: string, token: string, sector_id: string) => {
  const {
    isFetching,
    isLoading,
    data: fetchedManyThings,
    isError,
  } = useQuery([url], {
    queryFn: async () => fetchApiData<IManyItemsApi>(`${url}`, token),
    enabled: Number(sector_id) > 0,
  });

  return { isFetching, isLoading, fetchedManyThings, isError };
};

export default useFetchManyThings;
