import { Group, Paper, Text } from "@mantine/core";
import { Link } from "react-router-dom";

export interface IDashboardStatsCard {
  label: string;
  count: number | string;
  icon: JSX.Element;
  to: string;
}

const DashboardStatsCard = ({
  label,
  count,
  icon,
  to,
}: IDashboardStatsCard) => {
  return (
    <Paper withBorder radius="md" p="xl" shadow="md">
      <Group position="apart">
        <div>
          <Text fw={700} size="xl">
            {count}
          </Text>
          <Text
            c="dimmed"
            size="xs"
            tt="uppercase"
            fw={700}
            component={Link}
            to={to}
          >
            {label}
          </Text>
        </div>
        {icon}
      </Group>
    </Paper>
  );
};

export default DashboardStatsCard;
