import { useCallback, useMemo } from "react";
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
  MRT_Row,
} from "mantine-react-table";
import {
  ActionIcon,
  Button,
  Drawer,
  Grid,
  Group,
  MultiSelect,
  ScrollArea,
  Select,
  TextInput,
  Tooltip,
  Skeleton,
  Autocomplete,
  Stack,
  Text,
  Title,
  Divider,
  Avatar,
  Flex,
} from "@mantine/core";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useForm } from "@mantine/form";
import useFetchManyThings from "../hooks/useFetchManyThings";
import { useEffect, useState } from "react";
import {
  IconApps,
  IconArrowBack,
  IconEdit,
  IconEye,
  IconEyeEdit,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import {
  IIconsResponse,
  ILineChartData,
  IMutateSectorSummary,
  IRawIndicator,
  IRawIndicatorApiResponse,
  ISectorById,
  ISectorSummaryRequestBody,
  ISectors,
  ISectorsApiData,
  MUTATE_SECTORS_KEY,
  MUTATE_SECTOR_SUMMARY_KEY,
  SECTORS_KEY,
} from "../utils/types";
import useFetchIndicators from "../hooks/useFetchIndicators";
import { fShortenNumber } from "../utils/formatNumber";
import SectorSummaryColumnChart from "../components/Chart";
import { modals } from "@mantine/modals";
import { PRIMARY_COLOR, SECTOR_SUMMARY_IMAGES } from "../utils/config";
import { useAuthContext } from "../store/AuthContext";
import ViewSector from "./ViewSector";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import ImageViewer from "react-simple-image-viewer";
import useFetchData from "../hooks/useFetchBudgets";
import axios, { AxiosResponse } from "axios";

export interface ISelectValues {
  value: string;
  label: string;
}

interface IFoundCards {
  foundCardOnes: ICard[];
  foundCardTwos: ICharts | undefined;
  foundCardThrees: ICharts | undefined;
  foundCardFours: ICard[];
  foundCardFives: ICharts | undefined;
  foundCardSixs: ICardSixChart | undefined;
}

const getSummaryItems = (summary: ISectorSummaryRequestBody[]): IFoundCards => {
  let convCardTwo: ICharts | undefined = undefined;
  let convCardThree: ICharts | undefined = undefined;
  let convCardFive: ICharts | undefined = undefined;
  let convCardSix: ICardSixChart | undefined = undefined;
  const foundCardOnes: ICard[] = summary
    .filter((itm) => itm.item === "card_one")
    .map((items) => ({
      constituencies: items.constituencies,
      groups: items.groups,
      icon: items.icon ? items.icon : "",
      indicator_id: items.indicator_id,
      keys: items.keys,
      name: items.name,
      periods: items.periods,
      value: items.value ? items.value : 0,
      title: items.title ? items.title : "",
    }));
  const foundCardTwo = summary.filter((itm) => itm.item === "card_two")[0];
  if (foundCardTwo) {
    convCardTwo = {
      categories: foundCardTwo.categories,
      constituencies: foundCardTwo.constituencies,
      dataset: foundCardTwo.dataset,
      groups: foundCardTwo.groups,
      indicator_id: foundCardTwo.indicator_id,
      keys: foundCardTwo.keys,
      periods: foundCardTwo.periods,
      xAxisLabel: foundCardTwo ? foundCardTwo.title : "",
      yaxisLabel: "",
    };
  }
  const foundCardThree = summary.filter((itm) => itm.item === "card_three")[0];
  if (foundCardThree) {
    convCardThree = {
      categories: foundCardThree.categories,
      constituencies: foundCardThree.constituencies,
      dataset: foundCardThree.dataset,
      groups: foundCardThree.groups,
      indicator_id: foundCardThree.indicator_id,
      keys: foundCardThree.keys,
      periods: foundCardThree.periods,
      xAxisLabel: foundCardThree ? foundCardThree.title : "",
      yaxisLabel: "",
    };
  }
  const foundCardFours: ICard[] = summary
    .filter((itm) => itm.item === "card_four")
    .map((items) => ({
      constituencies: items.constituencies,
      groups: items.groups,
      icon: items.icon ? items.icon : "",
      indicator_id: items.indicator_id,
      keys: items.keys,
      name: items.name,
      periods: items.periods,
      value: items.value ? items.value : 0,
      title: items.title ? items.title : "",
    }));
  const foundCardFive = summary.filter((itm) => itm.item === "card_five")[0];
  if (foundCardFive) {
    convCardFive = {
      categories: foundCardFive.categories,
      constituencies: foundCardFive.constituencies,
      dataset: foundCardFive.dataset,
      groups: foundCardFive.groups,
      indicator_id: foundCardFive.indicator_id,
      keys: foundCardFive.keys,
      periods: foundCardFive.periods,
      xAxisLabel: foundCardFive ? foundCardFive.title : "",
      yaxisLabel: "",
    };
  }
  const foundCardSix = summary.filter((itm) => itm.item === "card_six")[0];
  if (foundCardSix) {
    convCardSix = {
      categories: foundCardSix.categories,
      constituencies: foundCardSix.constituencies,
      dataset: foundCardSix.dataset,
      groups: foundCardSix.groups,
      indicator_id: foundCardSix.indicator_id,
      keys: foundCardSix.keys,
      periods: foundCardSix.periods,
      xAxisLabel: foundCardSix ? foundCardSix.title : "",
      yaxisLabel: "",
    };
  }
  return {
    foundCardFives: convCardFive,
    foundCardFours,
    foundCardOnes,
    foundCardSixs: convCardSix,
    foundCardThrees: convCardThree,
    foundCardTwos: convCardTwo,
  };
};

function sumValuesByCriteria(
  data: IRawIndicatorApiResponse | undefined,
  keys: string[],
  constituencies: string[],
  periods: string[],
  groups: string[]
): number {
  if (!data) return 0;
  if (groups.length > 0) {
    return data.data.data.reduce((totalSum, item) => {
      if (
        keys.includes(item.key) &&
        constituencies.includes(item.constituency) &&
        periods.includes(item.period) &&
        groups.includes(item.group)
      ) {
        totalSum += item.value;
      }
      return totalSum;
    }, 0);
  } else if (constituencies.length > 0) {
    return data.data.data.reduce((totalSum, item) => {
      if (
        keys.includes(item.key) &&
        constituencies.includes(item.constituency) &&
        periods.includes(item.period)
      ) {
        totalSum += item.value;
      }
      return totalSum;
    }, 0);
  } else {
    return data.data.data.reduce((totalSum, item) => {
      if (keys.includes(item.key) && periods.includes(item.period)) {
        totalSum += item.value;
      }
      return totalSum;
    }, 0);
  }
}

function getValuesByCalcCriteria(
  data: IRawIndicatorApiResponse | undefined,
  keys: string[],
  constituencies: string[],
  periods: string[],
  groups: string[],
  toSumOn: "key" | "constituency"
): number[] {
  if (!data) return [];

  let filteredData: IRawIndicator[] = [];

  if (groups.length > 0 && keys.length > 0 && periods.length > 0) {
    filteredData = data.data.data.filter(
      (item) =>
        keys.includes(item.key) &&
        groups.includes(item.group) &&
        periods.includes(item.period)
    );
  } else if (groups.length > 0) {
    filteredData = data.data.data.filter(
      (item) =>
        keys.includes(item.key) &&
        constituencies.includes(item.constituency) &&
        periods.includes(item.period) &&
        groups.includes(item.group)
    );
  } else if (constituencies.length > 0) {
    filteredData = data.data.data.filter(
      (item) =>
        keys.includes(item.key) &&
        constituencies.includes(item.constituency) &&
        periods.includes(item.period)
    );
  } else {
    filteredData = data.data.data.filter(
      (item) => keys.includes(item.key) && periods.includes(item.period)
    );
  }

  const totalValues: { [key: string]: number } = {};

  filteredData.forEach((item) => {
    const key = toSumOn === "key" ? item.key : item.constituency;
    if (!totalValues[key]) {
      totalValues[key] = 0;
    }
    totalValues[key] += item.value;
  });
  // If toSumOn is 'key', return values based on keys; if 'constituency', return values based on constituencies.
  const resultKeys = toSumOn === "key" ? keys : constituencies;

  // Map the result keys to their respective values in totalValues or set to 0 if not found.
  const results = resultKeys.map((key) => totalValues[key] || 0);
  return results;
}

function getCardTwoValuesByCriteria(
  data: IRawIndicatorApiResponse | undefined,
  keys: string[],
  constituencies: string[],
  periods: string[],
  groups: string[]
): number[] {
  if (!data) return [];
  const totals: number[] = new Array(constituencies.length).fill(0); // Initialize totals with zeros
  if (groups.length > 0) {
    constituencies.forEach((constituencyToMatch, constituencyIndex) => {
      periods.forEach((periodToMatch) => {
        groups.forEach((groupToMatch) => {
          data.data.data.forEach((item) => {
            if (
              item.constituency === constituencyToMatch &&
              item.period === periodToMatch &&
              item.group === groupToMatch &&
              keys.includes(item.key)
            ) {
              totals[constituencyIndex] += item.value;
            }
          });
        });
      });
    });
  } else {
    constituencies.forEach((constituencyToMatch, constituencyIndex) => {
      periods.forEach((periodToMatch) => {
        data.data.data.forEach((item) => {
          if (
            item.constituency === constituencyToMatch &&
            item.period === periodToMatch &&
            keys.includes(item.key)
          ) {
            totals[constituencyIndex] += item.value;
          }
        });
      });
    });
  }

  return totals;
}

function getTrendDataByCriteria(
  data: IRawIndicatorApiResponse | undefined,
  keys: string[],
  constituencies: string[],
  periods: string[],
  groups: string[]
): number[] {
  if (!data) return [];
  const totals: number[] = new Array(periods.length).fill(0);

  if (groups.length > 0) {
    periods.forEach((periodToMatch, periodIndex) => {
      groups.forEach((groupToMatch) => {
        keys.forEach((keyToMatch) => {
          constituencies.forEach((constituencyToMatch) => {
            data.data.data.forEach((item) => {
              if (
                item.key === keyToMatch &&
                item.constituency === constituencyToMatch &&
                item.group === groupToMatch &&
                item.period === periodToMatch
              ) {
                totals[periodIndex] += item.value;
              }
            });
          });
        });
      });
    });
  } else {
    periods.forEach((periodToMatch, periodIndex) => {
      keys.forEach((keyToMatch) => {
        constituencies.forEach((constituencyToMatch) => {
          data.data.data.forEach((item) => {
            if (
              item.key === keyToMatch &&
              item.constituency === constituencyToMatch &&
              item.period === periodToMatch
            ) {
              totals[periodIndex] += item.value;
            }
          });
        });
      });
    });
  }

  return totals;
}

function getCardSixDataCriteria(
  data: IRawIndicatorApiResponse | undefined,
  keys: string[],
  constituencies: string[],
  periods: string[],
  groups: string[]
): ILineChartData[] {
  if (!data) return [];
  const results: ILineChartData[] = [];

  keys.forEach((keyToMatch) => {
    const keyData: number[] = new Array(periods.length).fill(0);

    if (groups.length > 0) {
      constituencies.forEach((constituencyToMatch, constituencyIndex) => {
        groups.forEach((groupToMatch) => {
          periods.forEach((periodToMatch, periodIndex) => {
            data.data.data.forEach((item) => {
              if (
                item.constituency === constituencyToMatch &&
                item.period === periodToMatch &&
                item.group === groupToMatch &&
                item.key === keyToMatch
              ) {
                keyData[periodIndex] += item.value;
              }
            });
          });
        });
      });
    } else {
      constituencies.forEach((constituencyToMatch, constituencyIndex) => {
        periods.forEach((periodToMatch, periodIndex) => {
          data.data.data.forEach((item) => {
            if (
              item.constituency === constituencyToMatch &&
              item.period === periodToMatch &&
              item.key === keyToMatch
            ) {
              keyData[periodIndex] += item.value;
            }
          });
        });
      });
    }
    results.push({ name: keyToMatch, data: keyData });
  });
  return results;
}

interface ICard {
  indicator_id: string;
  icon: string;
  name: string;
  title: string;
  value: number;
  constituencies: string[];
  keys: string[];
  periods: string[];
  groups: string[];
}

interface ICharts {
  indicator_id: string;
  dataset: number[];
  categories: string[];
  constituencies: string[];
  keys: string[];
  periods: string[];
  groups: string[];
  plotBy?: string;
  yaxisLabel: string;
  xAxisLabel: string;
}

interface ICardSixChart {
  indicator_id: string;
  dataset: ILineChartData[];
  categories: string[];
  constituencies: string[];
  keys: string[];
  periods: string[];
  groups: string[];
  yaxisLabel: string;
  xAxisLabel: string;
}

export type TCurrentCard =
  | "Card 1"
  | "Card 2"
  | "Card 3"
  | "Card 4"
  | "Card 5"
  | "Card 6"
  | "";

interface IConfigProps {
  openNewSectorDrawer: () => void;
}

export function ConfigLayout({ openNewSectorDrawer }: IConfigProps) {
  const [periods, setPeriods] = useState<ISelectValues[]>([]);
  const [icons, setIcons] = useState<IIconsResponse>();
  const [keys, setKeys] = useState<ISelectValues[]>([]);
  const [groups, setGroups] = useState<ISelectValues[]>([]);
  const [locations, setLocations] = useState<ISelectValues[]>([]);
  const [indicators, setIndicators] = useState<ISelectValues[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [iconValue, setIconValue] = useState("");
  const [sectorColor, setSectorColor] = useState("black");
  const [sectorName, setSectorName] = useState("black");
  const [hasSummary, setHasSummary] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const [foundSectorToUpdate, setFoundSectorToUpdate] = useState<
    ISectors | undefined
  >(undefined);
  const [foundSector, setFoundSector] = useState<ISectorById | undefined>(
    undefined
  );

  const [currentCard, setCurrentCard] = useState<TCurrentCard>("");

  const [cardOneTitle, setCardOneTitle] = useState("");
  const [cardOneItems, setCardOneItems] = useState<ICard[]>([]);

  const [cardTwoTitle, setCardTwoTitle] = useState("");
  const [cardTwoItems, setCardTwoItems] = useState<ICharts | undefined>(
    undefined
  );

  const [cardThreeTitle, setCardThreeTitle] = useState("");
  const [cardThreeItems, setCardThreeItems] = useState<ICharts | undefined>(
    undefined
  );

  const [cardFourTitle, setCardFourTitle] = useState("");
  const [cardFourItems, setCardFourItems] = useState<ICard[]>([]);

  const [cardFiveTitle, setCardFiveTitle] = useState("");
  const [cardFiveItems, setCardFiveItems] = useState<ICharts | undefined>(
    undefined
  );

  const [cardSixTitle, setCardSixTitle] = useState("");
  const [cardSixItems, setCardSixItems] = useState<ICardSixChart | undefined>(
    undefined
  );
  //const [chartSixColors, setChartSixColors] = useState<string[]>(["#008000"]);

  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [
    updateDrawerOpened,
    { open: openUpdateDrawer, close: closeUpdateDrawer },
  ] = useDisclosure(false);

  const form = useForm<{
    name: string;
    sector_id: string;
    indicator_id: string;
    plotBy: "constituency" | "key";
    period: string[];
    keys: string[];
    fn: string;
    groups: string[];
    locations: string[];
  }>({
    initialValues: {
      name: "",
      sector_id: "",
      indicator_id: "",
      period: [],
      keys: [],
      fn: "",
      plotBy: "constituency",
      groups: [],
      locations: [],
    },
    validate: {
      name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      sector_id: (value) => (value.length === 0 ? "Choose the sector" : null),
      indicator_id: (value) =>
        value.length === 0 ? "Choose the indicator" : null,
    },
  });

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const columns = useMemo<MRT_ColumnDef<ISectors>[]>(
    () => [
      {
        accessorKey: "name",
        enableEditing: false,
        header: "Sector",
        Cell: ({ row }) => {
          return (
            <Group spacing="sm">
              <Avatar size={32} src={row.original.icon} radius={26} />
              <Text size="sm" fw={500}>
                {row.original.name}
              </Text>
            </Group>
          );
        },
      },
      {
        accessorKey: "indicators_count",
        header: "Indicators",
      },
    ],
    []
  );

  const {
    fetchedData: fetchedSectors,
    isError: isLoadingSectorsError,
    isLoading: isLoadingSectors,
    isFetching: isFetchingSectors,
  } = useFetchData<ISectorsApiData>(SECTORS_KEY, token ? token : "");

  const { fetchedIndicators } = useFetchIndicators(
    `/indicator/${form.values.indicator_id}`,
    token ? token : "",
    form.values.indicator_id
  );

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await axiosInstance.delete(`${MUTATE_SECTORS_KEY}?delete=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const mutateSectorSummary = useMutation({
    mutationFn: async (sectorSummary: IMutateSectorSummary) => {
      await axiosInstance.post(MUTATE_SECTOR_SUMMARY_KEY, sectorSummary, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const mutatePutSectorSummary = useMutation({
    mutationFn: async (sectorSummary: IMutateSectorSummary) => {
      await axiosInstance.put(
        `${MUTATE_SECTOR_SUMMARY_KEY}/${form.values.sector_id}`,
        sectorSummary,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  const openDeleteConfirmModal = (row: MRT_Row<ISectors>) =>
    modals.openConfirmModal({
      title: "Delete Sector?",
      children: (
        <Text>
          Are you sure you want to delete {row.original.name} ? This action
          cannot be undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await deleteMutation.mutate(row.id, {
            onSuccess: () => {
              notifications.show({
                title: "Success",
                message: "Sector deleted",
                icon: <IconCheck />,
                color: "teal",
              });
            },
            onError(error: any, variables, context) {
              notifications.show({
                title: "Error",
                message:
                  error && error.data
                    ? error.data
                    : "An error occured, try again",
                icon: <IconX />,
                color: "red",
              });
            },
            onSettled(data, error, variables, context) {
              queryClient.invalidateQueries([SECTORS_KEY]);
            },
          });
        } catch (error) {}
      },
    });

  const handleUpdateSector = (row: MRT_Row<ISectors>) => {
    setFoundSectorToUpdate(row.original);
    openUpdateDrawer();
  };

  const table = useMantineReactTable({
    columns,
    data:
      fetchedSectors && Array.isArray(fetchedSectors.data)
        ? fetchedSectors.data
        : [],
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "cell", // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    getRowId: (row) => (row.id ? row.id.toString() : ""),
    initialState: {
      density: "xs", //set default density to compact
    },
    mantineToolbarAlertBannerProps: isLoadingSectorsError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Update">
          <ActionIcon
            color={PRIMARY_COLOR}
            onClick={() => handleUpdateSector(row)}
          >
            <IconEdit size={24} />
          </ActionIcon>
        </Tooltip>

        {row.original.has_summary ? (
          <Tooltip label="Modify Configuration">
            <ActionIcon
              color="indigo"
              disabled={isLoadingConfig}
              onClick={async () => {
                if (row.original.has_summary) {
                  try {
                    setIsLoadingConfig(true);
                    const {
                      data,
                    }: AxiosResponse<{
                      code: number;
                      data: ISectorById;
                    }> = await axios.get(
                      `${
                        process.env.REACT_APP_BASEURL
                      }/api/sectors/${row.id.toString()}`
                    );
                    if (data && data.data.summary) {
                      const {
                        foundCardFives,
                        foundCardFours,
                        foundCardOnes,
                        foundCardSixs,
                        foundCardThrees,
                        foundCardTwos,
                      } = getSummaryItems(data.data.summary.data);

                      setFoundSector(data.data);
                      setCardOneTitle(
                        foundCardOnes.length > 0 && foundCardOnes[0]
                          ? foundCardOnes[0].title
                          : ""
                      );
                      setCardOneItems(foundCardOnes);
                      setCardTwoTitle(
                        foundCardTwos ? foundCardTwos.xAxisLabel : ""
                      );
                      setCardTwoItems(foundCardTwos);
                      setCardThreeTitle(
                        foundCardThrees ? foundCardThrees.xAxisLabel : ""
                      );
                      setCardThreeItems(foundCardThrees);
                      setCardFourTitle(
                        foundCardFours.length > 0 && foundCardFours[0]
                          ? foundCardFours[0].title
                          : ""
                      );
                      setCardFourItems(foundCardFours);
                      setCardFiveTitle(
                        foundCardFives ? foundCardFives.xAxisLabel : ""
                      );
                      setCardFiveItems(foundCardFives);
                      setCardSixTitle(
                        foundCardSixs ? foundCardSixs.xAxisLabel : ""
                      );
                      setCardSixItems(foundCardSixs);
                    }
                  } catch (error) {}
                  form.setFieldValue("sector_id", row.id.toString());
                  setIsLoadingConfig(false);
                  setEditMode(true);
                } else {
                  setIsLoadingConfig(false);
                  notifications.show({
                    title: "Error",
                    message: "Invalid configuration found for this sector",
                    icon: <IconX />,
                    color: "red",
                  });
                }
              }}
            >
              <IconEyeEdit size={24} />
            </ActionIcon>
          </Tooltip>
        ) : (
          <Tooltip label="Configure">
            <ActionIcon
              color="indigo"
              onClick={() => {
                form.setFieldValue("sector_id", row.id.toString());
                setEditMode(true);
              }}
            >
              <IconApps size={24} />
            </ActionIcon>
          </Tooltip>
        )}

        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button onClick={openNewSectorDrawer} variant="filled" size="sm">
        Create New Sector
      </Button>
    ),
    state: {
      isLoading: isLoadingSectors,
      showAlertBanner: isLoadingSectorsError,
      showProgressBars: false,
    },
  });

  const { fetchedManyThings } = useFetchManyThings(
    `/sectors/dashboard/new/${form.values.sector_id}`,
    token ? token : "",
    form.values.sector_id
  );

  useEffect(() => {
    if (foundSector && form.values.sector_id.length > 0) {
      const i = foundSector.indicators.map((indicator) => ({
        value: indicator.index,
        label: `${indicator.index} - ${indicator.name}`,
      }));
      setIndicators(i);
    }
  }, [form.values.sector_id, foundSector]);

  useEffect(() => {
    if (
      form.values.sector_id.length > 0 &&
      fetchedSectors &&
      fetchedSectors.data
    ) {
      const foundS = fetchedSectors.data.filter(
        (dt) => dt.id === Number(form.values.sector_id)
      );
      if (foundS.length === 1) {
        setSectorColor(foundS[0].color ? foundS[0].color : "#008000");
        setSectorName(foundS[0].name ? foundS[0].name : "-");
        if (foundS[0].has_summary) {
          setHasSummary(true);
        }
      }
    }
  }, [form.values.sector_id, fetchedSectors]);

  useEffect(() => {
    if (
      form.values.indicator_id.length > 0 &&
      fetchedManyThings &&
      fetchedManyThings.data
    ) {
      if (
        fetchedManyThings.data.data.filter(
          (s) => s.indicator.index === form.values.indicator_id
        ).length > 0
      ) {
        const p = fetchedManyThings.data.data
          .filter((s) => s.indicator.index === form.values.indicator_id)[0]
          .period.filter((p) => p)
          .map((p) => ({
            value: p,
            label: p,
          }));
        setPeriods(p);
        const lns = fetchedManyThings.data.data
          .filter((s) => s.indicator.index === form.values.indicator_id)[0]
          .locations.filter((l) => l)
          .map((l) => ({
            value: l,
            label: l,
          }));
        setLocations(lns);
        const k = fetchedManyThings.data.data
          .filter((s) => s.indicator.index === form.values.indicator_id)[0]
          .keys.filter((k) => k)
          .map((k) => ({
            value: k,
            label: k,
          }));
        setKeys(k);

        const g = fetchedManyThings.data.data
          .filter((s) => s.indicator.index === form.values.indicator_id)[0]
          .groups.filter((g) => g)
          .map((g) => ({
            value: g,
            label: g,
          }));
        setGroups(g);
      }
    }
  }, [form.values.indicator_id, fetchedManyThings]);

  useEffect(() => {
    const fetchData = () => {
      fetch("https://api.iconify.design/collection?prefix=tabler")
        .then((response) => response.json())
        .then((resData) => {
          setIcons(resData);
        })
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  return editMode ? (
    <Grid>
      <Grid.Col span={12}>
        <Title order={4}>{`${sectorName} summary page configuration`}</Title>
        <Text fz="sm" c="dimmed">
          Click on a skeleton to add its page configuration for each card
        </Text>
      </Grid.Col>
      <Grid.Col span={12}>
        <Group position="right">
          <Button
            leftIcon={<IconEye />}
            onClick={() => {
              openImageViewer(0);
            }}
            variant="white"
          >
            View Samples
          </Button>
          <Button
            leftIcon={<IconPlus />}
            onClick={async () => {
              // console.log({
              //   cardOneItems,
              //   cardTwoItems,
              //   cardThreeItems,
              //   cardFourItems,
              //   cardFiveItems,
              //   cardSixItems,
              // });

              const pageConfig: ISectorSummaryRequestBody[] = [];

              const cardOneMutate: ISectorSummaryRequestBody[] =
                cardOneItems.map((cardOneItem) => ({
                  constituencies: cardOneItem.constituencies,
                  dataset: null,
                  title: cardOneTitle,
                  groups: cardOneItem.groups,
                  indicator_id: cardOneItem.indicator_id,
                  item: "card_one",
                  categories: [],
                  keys: cardOneItem.keys,
                  name: cardOneItem.name,
                  periods: cardOneItem.periods,
                  icon: cardOneItem.icon.length === 0 ? null : cardOneItem.icon,
                  value: cardOneItem.value,
                }));

              pageConfig.push(...cardOneMutate);

              if (cardTwoItems) {
                const cardTwoMutate: ISectorSummaryRequestBody = {
                  constituencies: cardTwoItems.constituencies,
                  dataset: cardTwoItems.dataset,
                  groups: cardTwoItems.groups,
                  title: cardTwoTitle,
                  indicator_id: cardTwoItems.indicator_id,
                  item: "card_two",
                  categories: cardTwoItems.categories,
                  keys: cardTwoItems.keys,
                  name: cardTwoTitle,
                  periods: cardTwoItems.periods,
                  icon: null,
                  value: null,
                };
                pageConfig.push(cardTwoMutate);
              }

              if (cardThreeItems) {
                const cardThreeMutate: ISectorSummaryRequestBody = {
                  constituencies: cardThreeItems.constituencies,
                  dataset: cardThreeItems.dataset,
                  title: cardThreeTitle,
                  groups: cardThreeItems.groups,
                  indicator_id: cardThreeItems.indicator_id,
                  item: "card_three",
                  keys: cardThreeItems.keys,
                  name: cardThreeTitle,
                  categories: cardThreeItems.categories,
                  periods: cardThreeItems.periods,
                  icon: null,
                  value: null,
                };
                pageConfig.push(cardThreeMutate);
              }

              const cardFourMutate: ISectorSummaryRequestBody[] =
                cardFourItems.map((cardFourItem) => ({
                  constituencies: cardFourItem.constituencies,
                  dataset: null,
                  title: cardFourTitle,
                  groups: cardFourItem.groups,
                  indicator_id: cardFourItem.indicator_id,
                  item: "card_four",
                  keys: cardFourItem.keys,
                  name: cardFourItem.name,
                  categories: [],
                  periods: cardFourItem.periods,
                  icon:
                    cardFourItem.icon.length === 0 ? null : cardFourItem.icon,
                  value: cardFourItem.value,
                }));
              pageConfig.push(...cardFourMutate);

              if (cardFiveItems) {
                const cardFiveMutate: ISectorSummaryRequestBody = {
                  constituencies: cardFiveItems.constituencies,
                  dataset: cardFiveItems.dataset,
                  title: cardFiveTitle,
                  groups: cardFiveItems.groups,
                  indicator_id: cardFiveItems.indicator_id,
                  item: "card_five",
                  keys: cardFiveItems.keys,
                  name: cardFiveTitle,
                  categories: cardFiveItems.categories,
                  periods: cardFiveItems.periods,
                  icon: null,
                  value: null,
                };
                pageConfig.push(cardFiveMutate);
              }

              if (cardSixItems) {
                const cardSixMutate: ISectorSummaryRequestBody = {
                  constituencies: cardSixItems.constituencies,
                  dataset: cardSixItems.dataset,
                  title: cardSixTitle,
                  groups: cardSixItems.groups,
                  indicator_id: cardSixItems.indicator_id,
                  item: "card_six",
                  keys: cardSixItems.keys,
                  name: cardSixTitle,
                  categories: cardSixItems.categories,
                  periods: cardSixItems.periods,
                  icon: null,
                  value: null,
                };
                pageConfig.push(cardSixMutate);
              }

              //console.log(pageConfig);

              try {
                if (hasSummary) {
                  await mutatePutSectorSummary.mutate(
                    {
                      data: pageConfig,
                      sector_id: Number(form.values.sector_id),
                    },
                    {
                      onSuccess: () => {
                        notifications.show({
                          title: "Success",
                          message: "Sector Summary Page Modified",
                          icon: <IconCheck />,
                          color: "teal",
                        });
                        setEditMode(false);
                        setCurrentCard("");
                        setCardOneTitle("");
                        setCardOneItems([]);
                        setCardTwoTitle("");
                        setCardTwoItems(undefined);
                        setCardThreeTitle("");
                        setCardThreeItems(undefined);
                        setCardFourTitle("");
                        setCardFourItems([]);
                        setCardFiveTitle("");
                        setCardFiveItems(undefined);
                        setCardSixTitle("");
                        setCardSixItems(undefined);
                        form.reset();
                      },
                      onSettled(data, error, variables, context) {
                        queryClient.invalidateQueries([SECTORS_KEY]);
                      },
                      onError: (
                        error: any,
                        variables: IMutateSectorSummary,
                        context: unknown
                      ) => {
                        notifications.show({
                          title: "Error",
                          message:
                            error && error.data
                              ? error.data
                              : "An error occured, try again",
                          icon: <IconX />,
                          color: "red",
                        });
                      },
                    }
                  );
                } else {
                  await mutateSectorSummary.mutate(
                    {
                      data: pageConfig,
                      sector_id: Number(form.values.sector_id),
                    },
                    {
                      onSuccess: () => {
                        notifications.show({
                          title: "Success",
                          message: "Sector Summary Page Configured",
                          icon: <IconCheck />,
                          color: "teal",
                        });
                      },
                      onSettled(data, error, variables, context) {
                        setEditMode(false);
                        setCurrentCard("");
                        setCardOneTitle("");
                        setCardOneItems([]);
                        setCardTwoTitle("");
                        setCardTwoItems(undefined);
                        setCardThreeTitle("");
                        setCardThreeItems(undefined);
                        setCardFourTitle("");
                        setCardFourItems([]);
                        setCardFiveTitle("");
                        setCardFiveItems(undefined);
                        setCardSixTitle("");
                        setCardSixItems(undefined);
                        form.reset();
                        queryClient.invalidateQueries([SECTORS_KEY]);
                      },
                      onError: (
                        error: any,
                        variables: IMutateSectorSummary,
                        context: unknown
                      ) => {
                        notifications.show({
                          title: "Error",
                          message:
                            error && error.data
                              ? error.data
                              : "An error occured, try again",
                          icon: <IconX />,
                          color: "red",
                        });
                      },
                    }
                  );
                }
              } catch (error) {}
            }}
            variant="outline"
            loading={mutateSectorSummary.isLoading}
            disabled={cardOneItems.length === 0 || !cardTwoItems}
          >
            Publish
          </Button>
          <Button
            leftIcon={<IconArrowBack />}
            onClick={() => {
              setEditMode(false);
              setCurrentCard("");
              setCardOneTitle("");
              setCardOneItems([]);
              setCardTwoTitle("");
              setCardTwoItems(undefined);
              setCardThreeTitle("");
              setCardThreeItems(undefined);
              setCardFourTitle("");
              setCardFourItems([]);
              setCardFiveTitle("");
              setCardFiveItems(undefined);
              setCardSixTitle("");
              setCardSixItems(undefined);
              form.reset();
            }}
            variant="white"
          >
            Back
          </Button>
        </Group>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={6}>
              {cardOneItems.length === 0 ? (
                <Skeleton
                  height={160}
                  radius="md"
                  animate={false}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCurrentCard("Card 1");
                    openDrawer();
                  }}
                ></Skeleton>
              ) : (
                <div>
                  <Text size="md" fw={700} mb="lg">
                    {cardOneTitle}
                  </Text>
                  <Group position="right">
                    <Tooltip label="Add Another">
                      <IconPlus
                        onClick={() => {
                          setCurrentCard("Card 1");
                          openDrawer();
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                    <Tooltip label="Clear All (Card 1)">
                      <IconTrash
                        color="red"
                        size="1rem"
                        onClick={() => setCardOneItems([])}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Group>
                  <Group mb="md">
                    <Group grow noWrap>
                      <Icon
                        icon={`tabler:${cardOneItems[0].icon}`}
                        width="6rem"
                        color={sectorColor}
                      />
                      <div>
                        <Group>
                          <Title order={1} style={{ color: sectorColor }}>
                            {fShortenNumber(cardOneItems[0].value)}
                          </Title>
                          <Tooltip
                            label="Remove item"
                            onClick={() =>
                              setCardOneItems((prev) =>
                                prev.filter(
                                  (v) => v.name !== cardOneItems[0].name
                                )
                              )
                            }
                          >
                            <IconTrash
                              color="red"
                              size="1rem"
                              style={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                        </Group>
                        <Text size="md" fw={700} mb="lg">
                          {cardOneItems[0].name}
                        </Text>
                      </div>
                    </Group>
                  </Group>

                  {cardOneItems.slice(1).map((val, idx) => {
                    return (
                      <div key={idx}>
                        <Group position="apart">
                          <Group>
                            <Text size="sm" fw={700}>
                              {val.name}
                            </Text>
                            <Tooltip
                              label="Remove item"
                              onClick={() =>
                                setCardOneItems((prev) =>
                                  prev.filter((v) => v.name !== val.name)
                                )
                              }
                            >
                              <IconTrash
                                color="red"
                                size="1rem"
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </Group>
                          <Text size="lg" fw="bold">
                            {fShortenNumber(val.value)}
                          </Text>
                        </Group>
                        <Divider />
                      </div>
                    );
                  })}
                </div>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6}>
              {cardTwoItems ? (
                <>
                  <Text size="md" fw={700} mb="lg">
                    {cardTwoTitle}
                  </Text>
                  <Group position="left">
                    <Tooltip label="Remove Chart(Card 2)">
                      <IconTrash
                        onClick={() => setCardTwoItems(undefined)}
                        style={{ cursor: "pointer" }}
                        color="red"
                      />
                    </Tooltip>
                  </Group>
                  <SectorSummaryColumnChart
                    nameOfSeries={form.values.name}
                    categories={
                      cardTwoItems.categories ? cardTwoItems.categories : []
                    }
                    dataset={cardTwoItems.dataset ? cardTwoItems.dataset : []}
                    xAxisLabel={
                      cardTwoItems.xAxisLabel ? cardTwoItems.xAxisLabel : ""
                    }
                    yaxisLabel={
                      cardTwoItems.yaxisLabel ? cardTwoItems.yaxisLabel : ""
                    }
                    colors={[sectorColor]}
                    cardSixDataSet={[]}
                    currentCard="Card 2"
                    type="bar"
                  />
                </>
              ) : (
                <Skeleton
                  height={160}
                  radius="md"
                  animate={false}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCurrentCard("Card 2");
                    openDrawer();
                  }}
                />
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12}>
              {cardThreeItems ? (
                <>
                  <Text size="md" fw={700} mt="lg">
                    {cardThreeTitle}
                  </Text>
                  <Group position="right">
                    <Tooltip label="Remove">
                      <IconTrash
                        onClick={() => setCardThreeItems(undefined)}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Group>
                  <SectorSummaryColumnChart
                    nameOfSeries={form.values.name}
                    categories={
                      cardThreeItems.categories ? cardThreeItems.categories : []
                    }
                    dataset={
                      cardThreeItems.dataset ? cardThreeItems.dataset : []
                    }
                    colors={[sectorColor]}
                    xAxisLabel={
                      cardThreeItems.xAxisLabel ? cardThreeItems.xAxisLabel : ""
                    }
                    yaxisLabel={
                      cardThreeItems.yaxisLabel ? cardThreeItems.yaxisLabel : ""
                    }
                    cardSixDataSet={[]}
                    currentCard="Card 3"
                    type="treemap"
                  />
                </>
              ) : (
                <Skeleton
                  height={160}
                  radius="md"
                  animate={false}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCurrentCard("Card 3");
                    openDrawer();
                  }}
                />
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6}>
              {cardFourItems.length === 0 ? (
                <Skeleton
                  height={160}
                  radius="md"
                  animate={false}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCurrentCard("Card 4");
                    openDrawer();
                  }}
                ></Skeleton>
              ) : (
                <div>
                  <Text size="md" fw={700} mb="lg">
                    {cardFourTitle}
                  </Text>
                  <Group position="right">
                    <Tooltip label="Add Another">
                      <IconPlus
                        onClick={() => {
                          setCurrentCard("Card 4");
                          openDrawer();
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                    <Tooltip label="Clear All (Card 4)">
                      <IconTrash
                        color="red"
                        size="1rem"
                        onClick={() => setCardFourItems([])}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Group>
                  <Group mb="md">
                    <Group grow noWrap>
                      <Icon
                        icon={`tabler:${cardFourItems[0].icon}`}
                        width="6rem"
                        color={sectorColor}
                      />
                      <div>
                        <Group>
                          <Title order={1} style={{ color: sectorColor }}>
                            {fShortenNumber(cardFourItems[0].value)}
                          </Title>
                          <Tooltip
                            label="Remove item"
                            onClick={() =>
                              setCardFourItems((prev) =>
                                prev.filter(
                                  (v) => v.name !== cardFourItems[0].name
                                )
                              )
                            }
                          >
                            <IconTrash
                              color="red"
                              size="1rem"
                              style={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                        </Group>
                        <Text size="md" fw={700} mb="lg">
                          {cardFourItems[0].name}
                        </Text>
                      </div>
                    </Group>
                  </Group>

                  {cardFourItems.slice(1).map((val, idx) => {
                    return (
                      <div key={idx}>
                        <Group position="apart">
                          <Group>
                            <Text size="sm" fw={700}>
                              {val.name}
                            </Text>
                            <Tooltip
                              label="Remove item"
                              onClick={() =>
                                setCardFourItems((prev) =>
                                  prev.filter((v) => v.name !== val.name)
                                )
                              }
                            >
                              <IconTrash
                                color="red"
                                size="1rem"
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </Group>
                          <Text size="lg" fw="bold">
                            {fShortenNumber(val.value)}
                          </Text>
                        </Group>
                        <Divider />
                      </div>
                    );
                  })}
                </div>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6}>
              {cardFiveItems ? (
                <>
                  <Text size="md" fw={700} mt="lg">
                    {cardFiveTitle}
                  </Text>
                  <Group position="right">
                    <Tooltip label="Remove">
                      <IconTrash
                        onClick={() => setCardFiveItems(undefined)}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Group>
                  <SectorSummaryColumnChart
                    nameOfSeries={form.values.name}
                    categories={
                      cardFiveItems.categories ? cardFiveItems.categories : []
                    }
                    dataset={cardFiveItems.dataset ? cardFiveItems.dataset : []}
                    xAxisLabel={
                      cardFiveItems.xAxisLabel ? cardFiveItems.xAxisLabel : ""
                    }
                    colors={[sectorColor]}
                    yaxisLabel={
                      cardFiveItems.yaxisLabel ? cardFiveItems.yaxisLabel : ""
                    }
                    cardSixDataSet={[]}
                    currentCard="Card 5"
                    type="line"
                  />
                </>
              ) : (
                <Skeleton
                  height={160}
                  radius="md"
                  animate={false}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCurrentCard("Card 5");
                    openDrawer();
                  }}
                />
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12}>
              {cardSixItems ? (
                <>
                  <Text size="md" fw={700} mt="lg">
                    {cardSixTitle}
                  </Text>
                  <Group position="right">
                    <Tooltip label="Remove">
                      <IconTrash
                        onClick={() => setCardSixItems(undefined)}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Group>
                  <SectorSummaryColumnChart
                    nameOfSeries={form.values.name}
                    categories={
                      cardSixItems.categories ? cardSixItems.categories : []
                    }
                    dataset={[]}
                    xAxisLabel={
                      cardSixItems.xAxisLabel ? cardSixItems.xAxisLabel : ""
                    }
                    colors={[
                      "#8a2525",
                      "#bda808",
                      "#09ab00",
                      "#001fab",
                      "#ff0000",
                      "#970399",
                      "#990303",
                    ]}
                    yaxisLabel={
                      cardSixItems.yaxisLabel ? cardSixItems.yaxisLabel : ""
                    }
                    cardSixDataSet={
                      cardSixItems.dataset ? cardSixItems.dataset : []
                    }
                    currentCard="Card 6"
                    type="line"
                  />
                </>
              ) : (
                <Skeleton
                  height={160}
                  radius="md"
                  animate={false}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCurrentCard("Card 6");
                    openDrawer();
                  }}
                />
              )}
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col span={12}>
        <Drawer
          opened={drawerOpened}
          position="right"
          size="lg"
          onClose={closeDrawer}
          title={currentCard}
          overlayProps={{ opacity: 0.5, blur: 4 }}
          scrollAreaComponent={ScrollArea.Autosize}
        >
          <form
            onSubmit={form.onSubmit((values) => {
              const { keys, locations, period, groups } = values;
              if (currentCard === "Card 1") {
                const value = sumValuesByCriteria(
                  fetchedIndicators,
                  keys,
                  locations,
                  period,
                  groups
                );
                const data: ICard = {
                  icon: iconValue,
                  name: form.values.name,
                  value,
                  constituencies: locations,
                  groups,
                  keys,
                  title: cardOneTitle,
                  periods: period,
                  indicator_id: form.values.indicator_id,
                };
                setCardOneItems((prev) => [...prev, data]);
                setIconValue("");
                form.setValues({
                  indicator_id: "",
                  name: "",
                  period: [],
                  groups: [],
                  keys: [],
                });
              }
              if (currentCard === "Card 2") {
                setCardTwoItems({
                  categories: locations,
                  dataset: getCardTwoValuesByCriteria(
                    fetchedIndicators,
                    keys,
                    locations,
                    period,
                    groups
                  ),
                  constituencies: locations,
                  keys,
                  periods: period,
                  groups,
                  xAxisLabel: cardTwoTitle,
                  yaxisLabel: "",
                  indicator_id: form.values.indicator_id,
                });
                form.setValues({
                  indicator_id: "",
                  name: "",
                  period: [],
                  groups: [],
                  keys: [],
                });
                closeDrawer();
              }

              if (currentCard === "Card 3") {
                setCardThreeItems({
                  categories: form.values.plotBy === "key" ? keys : locations,
                  dataset: getValuesByCalcCriteria(
                    fetchedIndicators,
                    keys,
                    locations,
                    period,
                    groups,
                    form.values.plotBy
                  ),
                  constituencies: locations,
                  keys,
                  periods: period,
                  groups,
                  plotBy: form.values.plotBy,
                  xAxisLabel: cardThreeTitle,
                  yaxisLabel: "",
                  indicator_id: form.values.indicator_id,
                });

                form.setValues({
                  indicator_id: "",
                  name: "",
                  period: [],
                  groups: [],
                  keys: [],
                });
                closeDrawer();
              }
              if (currentCard === "Card 4") {
                const value = sumValuesByCriteria(
                  fetchedIndicators,
                  keys,
                  locations,
                  period,
                  groups
                );
                const data: ICard = {
                  icon: iconValue,
                  name: form.values.name,
                  value,
                  constituencies: locations,
                  groups,
                  keys,
                  periods: period,
                  title: cardFourTitle,
                  indicator_id: form.values.indicator_id,
                };
                setCardFourItems((prev) => [...prev, data]);
                setIconValue("");
                form.setValues({
                  indicator_id: "",
                  name: "",
                  period: [],
                  groups: [],
                  keys: [],
                });
                // setCardOneTitle("");
              }
              if (currentCard === "Card 5") {
                setCardFiveItems({
                  categories: period,
                  dataset: getTrendDataByCriteria(
                    fetchedIndicators,
                    keys,
                    locations,
                    period,
                    groups
                  ),
                  constituencies: locations,
                  keys,
                  periods: period,
                  groups,
                  xAxisLabel: cardFiveTitle,
                  yaxisLabel: "",
                  indicator_id: form.values.indicator_id,
                });
                form.setValues({
                  indicator_id: "",
                  name: "",
                  period: [],
                  groups: [],
                  keys: [],
                });
                closeDrawer();
              }

              if (currentCard === "Card 6") {
                setCardSixItems({
                  categories: period,
                  dataset: getCardSixDataCriteria(
                    fetchedIndicators,
                    keys,
                    locations,
                    period,
                    groups
                  ),
                  constituencies: locations,
                  keys,
                  periods: period,
                  groups,
                  xAxisLabel: cardSixTitle,
                  yaxisLabel: "",
                  indicator_id: form.values.indicator_id,
                });
                form.setValues({
                  indicator_id: "",
                  name: "",
                  period: [],
                  groups: [],
                  keys: [],
                });
              }
              closeDrawer();
            })}
          >
            <Grid>
              {/* <Grid.Col span={12}>
                {form.values.keys.length === 3 && (
                  <SectorSummaryColumnChart
                    categories={form.values.keys}
                    dataset={[10, 20, 30]}
                    xAxisLabel={form.values.name}
                    yaxisLabel=""
                  />
                )}
              </Grid.Col> */}
              <Grid.Col span={12}>
                <TextInput
                  label={`${currentCard} Title`}
                  value={
                    currentCard === "Card 1"
                      ? cardOneTitle
                      : currentCard === "Card 2"
                      ? cardTwoTitle
                      : currentCard === "Card 3"
                      ? cardThreeTitle
                      : currentCard === "Card 4"
                      ? cardFourTitle
                      : currentCard === "Card 5"
                      ? cardFiveTitle
                      : cardSixTitle
                  }
                  onChange={(event) => {
                    if (currentCard === "Card 1") {
                      setCardOneTitle(event.currentTarget.value);
                      return;
                    }
                    if (currentCard === "Card 2") {
                      setCardTwoTitle(event.currentTarget.value);
                      return;
                    }
                    if (currentCard === "Card 3") {
                      setCardThreeTitle(event.currentTarget.value);
                      return;
                    }
                    if (currentCard === "Card 4") {
                      setCardFourTitle(event.currentTarget.value);
                      return;
                    }
                    if (currentCard === "Card 5") {
                      setCardFiveTitle(event.currentTarget.value);
                      return;
                    }
                    if (currentCard === "Card 6") {
                      setCardSixTitle(event.currentTarget.value);
                      return;
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Select
                  label="Sector"
                  placeholder="Sector"
                  disabled={isFetchingSectors}
                  withAsterisk
                  {...form.getInputProps("sector_id")}
                  data={
                    fetchedSectors && Array.isArray(fetchedSectors.data)
                      ? fetchedSectors.data.map((sector) => ({
                          value: sector.id.toString(),
                          label: sector.name,
                        }))
                      : []
                  }
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Select
                  label="Indicator"
                  placeholder="Indicator"
                  searchable
                  disabled={indicators.length === 0}
                  withAsterisk
                  {...form.getInputProps("indicator_id")}
                  data={indicators}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  label="Name"
                  placeholder="Name"
                  {...form.getInputProps("name")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Autocomplete
                  label="Icon"
                  value={iconValue}
                  limit={20}
                  onChange={setIconValue}
                  placeholder="Start typing to see options"
                  data={icons ? icons.uncategorized : []}
                />
                <Stack align="start">
                  {iconValue.length > 0 && (
                    <Icon
                      icon={`tabler:${iconValue}`}
                      width="3rem"
                      color={sectorColor}
                    />
                  )}
                </Stack>
              </Grid.Col>
              <Grid.Col span={12}>
                <MultiSelect
                  label="Periods"
                  placeholder="Periods"
                  searchable
                  disabled={periods.length === 0}
                  withAsterisk
                  multiple
                  {...form.getInputProps("period")}
                  data={periods}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <MultiSelect
                  label="Groups"
                  placeholder="Groups"
                  searchable
                  disabled={groups.length === 0}
                  withAsterisk
                  multiple
                  {...form.getInputProps("groups")}
                  data={groups}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <MultiSelect
                  label="Keys"
                  placeholder="Keys"
                  searchable
                  disabled={keys.length === 0}
                  withAsterisk
                  multiple
                  {...form.getInputProps("keys")}
                  data={keys}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <MultiSelect
                  label="Locations"
                  placeholder="Locations"
                  disabled={locations.length === 0}
                  withAsterisk
                  searchable
                  multiple
                  {...form.getInputProps("locations")}
                  data={locations}
                />
              </Grid.Col>
              {currentCard === "Card 3" && (
                <Grid.Col span={12}>
                  <Select
                    label="Plot Category"
                    placeholder="Plot Category"
                    description="Chose values to plot on treemap"
                    withAsterisk
                    {...form.getInputProps("plotBy")}
                    data={["constituency", "key"]}
                  />
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <Group position="right">
                  <Button type="submit">Add</Button>
                </Group>
              </Grid.Col>
            </Grid>
          </form>
        </Drawer>
      </Grid.Col>
      <Grid.Col span={12}>
        {isViewerOpen && (
          <ImageViewer
            src={SECTOR_SUMMARY_IMAGES}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </Grid.Col>
    </Grid>
  ) : (
    <Grid>
      <Grid.Col span={12}>
        <Title className="styled-heading styled-heading-large">{`Sectors`}</Title>
      </Grid.Col>
      <Grid.Col span={12}>
        <MantineReactTable table={table} />
      </Grid.Col>
      <Grid.Col span={12}>
        <ViewSector
          closeDrawer={closeUpdateDrawer}
          drawerOpened={updateDrawerOpened}
          foundSector={foundSectorToUpdate}
        />
      </Grid.Col>
    </Grid>
  );
}
