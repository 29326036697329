import {
  Button,
  Chip,
  Collapse,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  SimpleGrid,
  Text,
  TextInput,
  Title,
  Tooltip,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  DELETE_SINGLE_MEDIA,
  DOCUMENTS_KEY,
  IDocMetaData,
  MUTATE_DOCUMENT_KEY,
} from "../utils/types";
import { IconEdit, IconUpload } from "@tabler/icons-react";
import { PRIMARY_COLOR } from "../utils/config";
import { useDisclosure } from "@mantine/hooks";
import { MediaPreview } from "./MediaPreview";
import { formatFileSize } from "../utils/fns";
import { getIcon } from "../sections/NewDocument";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import RichtextEditor from "./RichTextEditor";
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from "@mantine/dropzone";
import { useAuthContext } from "../store/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { IconPhoto } from "@tabler/icons-react";

const ItemDoc = ({ doc }: { doc: IDocMetaData }) => {
  const theme = useMantineTheme();
  const [opened, { toggle }] = useDisclosure(false);
  const [text, setText] = useState<string>("");
  const [openedUpdateDrawer, { open, close }] = useDisclosure(false);
  const [files, setFiles] = useState<File[]>([]);

  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (newDoc: FormData) => {
      await axiosInstance.post(`${MUTATE_DOCUMENT_KEY}/${doc.id}`, newDoc, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const deleteDocMutate = useMutation({
    mutationFn: async (id: string) => {
      await axiosInstance.delete(`${DELETE_SINGLE_MEDIA}/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const deleteMutate = useMutation({
    mutationFn: async (id: number) => {
      await axiosInstance.delete(`${MUTATE_DOCUMENT_KEY}/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const mediaForm = useForm({
    initialValues: {
      title: "",
      tags: "",
      publisher: "",
    },
    validate: {
      title: (value) =>
        value.length < 6 ? "title must have at least 6 letters" : null,
      tags: (value) => (value.length === 0 ? "provide at least one tag" : null),
    },
  });

  useEffect(() => {
    if (doc) {
      mediaForm.setValues({
        title: doc.title,
        publisher: doc.publisher ? doc.publisher : "",
        tags: doc.tags && doc.tags.length > 0 ? doc.tags : "",
      });
      setText(doc.description);
    }
  }, [doc]);

  return (
    <Grid gutterXs="md" gutterSm="md">
      <Grid.Col span={12}>
        <Group>
          <div>
            <Group mb="sm">
              {/* <Anchor href={`${MEDIA.link}/${doc.id}`}>{doc.title}</Anchor> */}
              <Text fz="lg" color={PRIMARY_COLOR}>
                {doc.title}
              </Text>
              <Tooltip label="Manage" ml="sm">
                <IconEdit
                  color="red"
                  size="0.8rem"
                  style={{ cursor: "pointer" }}
                  onClick={open}
                />
              </Tooltip>
            </Group>
            <Group>
              <Text
                fz="xs"
                dangerouslySetInnerHTML={{ __html: doc.description }}
              />
            </Group>
            <Group mt="md">
              <Text fz="xs">{new Date(doc.created_at).toDateString()}</Text>
              <Divider orientation="vertical" />
              {doc.tags &&
                doc.tags.length > 0 &&
                doc.tags.split(",").length > 0 &&
                doc.tags.split(",").map((tag, idx) => (
                  <Chip defaultChecked size="xs" key={idx}>
                    {tag}
                  </Chip>
                ))}
            </Group>
            <Flex mt="md">
              <Button variant="white" onClick={toggle}>
                {`${doc.media.length} Documents`}
              </Button>
              <Collapse in={opened}>
                <SimpleGrid
                  cols={4}
                  breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                  mt={doc.media.length > 0 ? "xl" : 0}
                >
                  {doc.media.map((file, index) => {
                    return (
                      <MediaPreview
                        key={index}
                        icon={getIcon(file.mime_type)}
                        name={file.name}
                        size={formatFileSize(file.size)}
                        path={file.original_url ? file.original_url : ""}
                        handleRemove={() => {}}
                        isUrl
                        link={file.original_url}
                      />
                    );
                  })}
                </SimpleGrid>
              </Collapse>
            </Flex>
          </div>
        </Group>
      </Grid.Col>
      <Grid.Col span={12}>
        <Divider mb="sm" />
      </Grid.Col>
      <Grid.Col span={12}>
        <Drawer
          opened={openedUpdateDrawer}
          onClose={close}
          title={doc.title}
          position="right"
          size="xl"
          overlayProps={{ opacity: 0.5, blur: 4 }}
        >
          <Grid.Col span={12}>
            <form
              onSubmit={mediaForm.onSubmit(async (values) => {
                const { publisher, tags, title } = values;

                const formData = new FormData();
                if (files.length > 0) {
                  files.forEach((file, idx) =>
                    formData.append(
                      `media[${idx + doc.media.length - 1}]`,
                      file
                    )
                  );
                }

                formData.append("title", title);
                formData.append("publisher", publisher);
                formData.append("description", text);
                formData.append("tags", tags);
                formData.append("_method", "PUT");

                try {
                  await mutation.mutate(formData, {
                    onSuccess: () => {
                      close();
                      notifications.show({
                        title: "Success",
                        message: "Documents Updated",
                        icon: <IconCheck />,
                        color: "teal",
                      });
                    },
                    onSettled() {
                      queryClient.invalidateQueries([DOCUMENTS_KEY]);
                    },
                    onError: (error: any) => {
                      notifications.show({
                        title: "Error",
                        message:
                          error && error.data
                            ? error.data
                            : "An error occured, try again",
                        icon: <IconX />,
                        color: "red",
                      });
                    },
                  });
                } catch (error) {}
              })}
            >
              <Grid>
                <Grid.Col span={12}>
                  <Dropzone
                    onDrop={setFiles}
                    onReject={(files) => console.log("rejected files", files)}
                    maxSize={3 * 1024 ** 2}
                    accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
                  >
                    <Group
                      position="center"
                      spacing="xl"
                      style={{ minHeight: rem(120), pointerEvents: "none" }}
                    >
                      <Dropzone.Accept>
                        <IconUpload
                          size="3.2rem"
                          stroke={1.5}
                          color={
                            theme.colors[theme.primaryColor][
                              theme.colorScheme === "dark" ? 4 : 6
                            ]
                          }
                        />
                      </Dropzone.Accept>
                      <Dropzone.Reject>
                        <IconX
                          size="3.2rem"
                          stroke={1.5}
                          color={
                            theme.colors.red[
                              theme.colorScheme === "dark" ? 4 : 6
                            ]
                          }
                        />
                      </Dropzone.Reject>
                      <Dropzone.Idle>
                        <IconPhoto size="3.2rem" stroke={1.5} />
                      </Dropzone.Idle>

                      <div>
                        <Text size="xl" inline>
                          Drag and drop your files here or browse your device
                        </Text>
                        <Text size="sm" color="dimmed" inline mt={7}>
                          {files.length === 0
                            ? "Attach as many files as you like, each file should not exceed 5mb"
                            : `${files.length} file${
                                files.length > 1 ? `s` : ""
                              } uploaded`}
                        </Text>
                      </div>
                    </Group>
                  </Dropzone>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    label="Resource Title"
                    placeholder="Resource Title"
                    {...mediaForm.getInputProps("title")}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    label="Resource Topics / Tags"
                    description="Enter as many tags separated by a comma"
                    placeholder="Resource topics"
                    {...mediaForm.getInputProps("tags")}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Group spacing="xs">
                    {mediaForm.values.tags.split(",").map((val, idx) => (
                      <Chip key={idx} size="xs" variant="filled">
                        {val}
                      </Chip>
                    ))}
                  </Group>
                </Grid.Col>
                <Grid.Col span={12}>
                  <RichtextEditor
                    setText={setText}
                    placeholder="Resource Description"
                    initialContent={text}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    label="Publisher"
                    placeholder="Publisher"
                    {...mediaForm.getInputProps("publisher")}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Title
                    order={4}
                  >{`Found Documents (${doc.media.length})`}</Title>
                  <SimpleGrid
                    cols={1}
                    breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                    mt={doc.media.length > 0 ? "xl" : 0}
                  >
                    {doc.media.map((file, index) => {
                      return (
                        <MediaPreview
                          key={index}
                          icon={getIcon(file.mime_type)}
                          name={file.name}
                          size={formatFileSize(file.size)}
                          labelDelete
                          path={file.original_url ? file.original_url : ""}
                          handleRemove={async () => {
                            try {
                              await deleteDocMutate.mutate(file.uuid, {
                                onSuccess: () => {
                                  close();
                                  notifications.show({
                                    title: "Success",
                                    message: "The document has been deleted",
                                    icon: <IconCheck />,
                                    color: "teal",
                                  });
                                },
                                onSettled() {
                                  queryClient.invalidateQueries([
                                    DOCUMENTS_KEY,
                                  ]);
                                },
                                onError: (error: any) => {
                                  notifications.show({
                                    title: "Error",
                                    message:
                                      error && error.data
                                        ? error.data
                                        : "An error occured, try again",
                                    icon: <IconX />,
                                    color: "red",
                                  });
                                },
                              });
                            } catch (error) {}
                          }}
                          isUrl={false}
                          link={file.original_url}
                        />
                      );
                    })}
                  </SimpleGrid>
                </Grid.Col>

                <Grid.Col sm={12} md={12}>
                  <Group position="apart">
                    <Button
                      loading={deleteMutate.isLoading}
                      color="red"
                      onClick={async () => {
                        try {
                          await deleteMutate.mutate(doc.id, {
                            onSuccess: () => {
                              close();
                              notifications.show({
                                title: "Success",
                                message:
                                  "The document and metadata have been deleted",
                                icon: <IconCheck />,
                                color: "teal",
                              });
                            },
                            onSettled() {
                              queryClient.invalidateQueries([DOCUMENTS_KEY]);
                            },
                            onError: (error: any) => {
                              notifications.show({
                                title: "Error",
                                message:
                                  error && error.data
                                    ? error.data
                                    : "An error occured, try again",
                                icon: <IconX />,
                                color: "red",
                              });
                            },
                          });
                        } catch (error) {}
                      }}
                    >
                      Delete
                    </Button>
                    <Button type="submit" loading={mutation.isLoading}>
                      Update
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </form>
          </Grid.Col>
        </Drawer>
      </Grid.Col>
    </Grid>
  );
};

export default ItemDoc;
