import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stepper,
  Button,
  Group,
  Grid,
  useMantineTheme,
  Text,
  rem,
  Stack,
  Alert,
  Center,
  Flex,
  Tooltip,
  ActionIcon,
  SimpleGrid,
  Select,
} from "@mantine/core";
import {
  MantineReactTable,
  // createRow,
  type MRT_ColumnDef,
  useMantineReactTable,
  MRT_Cell,
} from "mantine-react-table";
import {
  IconUpload,
  IconPhoto,
  IconX,
  IconAlertCircle,
  IconCheck,
  IconTrash,
  IconDownload,
} from "@tabler/icons-react";
import { Dropzone, FileWithPath, MS_EXCEL_MIME_TYPE } from "@mantine/dropzone";

import { PRIMARY_COLOR, SECTORS } from "../utils/config";
import {
  arrayKeysToSnakeCase,
  downloadCSV,
  excelDataToJson,
  formatFileSize,
  getIndicatorLabelFromIndex,
  getIndidicatorFromIndex,
  readExcelFile,
  validateRequired,
} from "../utils/fns";
import {
  IBulkIndicatorUpload,
  IExtraSelectValues,
  IIndicatorDataApiResponse,
  INDICATORS_KEY,
  INDICATOR_KEY,
  ISectorByIdApiResponse,
  ISectorsApiData,
  MUTATE_BULK_INDICATORS_KEY,
  SECTORS_KEY,
  TableData,
} from "../utils/types";
import { MediaPreview } from "../components/MediaPreview";
import { notifications } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuthContext } from "../store/AuthContext";
import { getIcon } from "../sections/NewDocument";
import useFetchData from "../hooks/useFetchBudgets";
import axios, { AxiosResponse } from "axios";
import useFetchIndicatorData from "../hooks/useFetchIndicatorData";
import { getIndicatorData } from "./IndicatorData";

const BulkIndicatorUpload = () => {
  const theme = useMantineTheme();
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [indicators, setIndicators] = useState<IExtraSelectValues[]>([]);
  const [selectedSector, setSelectedSector] = useState<string | null>(null);
  const [selectedIndicator, setSelectedIndicator] = useState<string | null>(
    null
  );
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
  const [indicatorData, setIndicatorData] = useState<any[]>([]);
  const [excelData, setExcelData] = useState<TableData>([]);
  const [indicatorTableData, setIndicatorTableData] = useState<any[]>([]);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  //keep track of rows that have been edited
  const [editedIndicators, setEditedIndicators] = useState<Record<string, any>>(
    {}
  );

  const {
    fetchedData: fetchedSectors,
    //isError: isLoadingProjectsError,
    //isLoading: isLoadingProjects
    isFetching: isFetchingSectors,
  } = useFetchData<ISectorsApiData>(SECTORS_KEY, token ? token : "");

  useEffect(() => {
    if (
      fetchedSectors &&
      fetchedSectors.data &&
      selectedSector &&
      selectedSector.length > 0
    ) {
      const s = fetchedSectors.data.filter(
        (s) => s.id === Number(selectedSector)
      )[0];
      if (s) {
        axios
          .get(
            `${process.env.REACT_APP_BASEURL}/api/sectors/${s.id.toString()}`
          )
          .then((res: AxiosResponse<ISectorByIdApiResponse>) => {
            if (res.data && res.data.data) {
              const i = res.data.data.indicators.map((indicator) => ({
                value: indicator.index,
                label: `${indicator.index} - ${indicator.name}`,
                name: indicator.index,
              }));
              setIndicators(i);
            }
          });
      }
    }
  }, [selectedSector, fetchedSectors]);

  const bulkMutation = useMutation({
    mutationFn: async (newBulkUpload: IBulkIndicatorUpload) => {
      await axiosInstance.post(MUTATE_BULK_INDICATORS_KEY, newBulkUpload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const indicatorTableColumns = useMemo<MRT_ColumnDef<any>[]>(
    () =>
      excelData.length > 0
        ? Object.keys(excelDataToJson(excelData).slice(0, 1)[0]).map((key) => ({
            accessorKey: key,
            header: key,
            id: key,
            mantineEditTextInputProps: ({ cell, row }) => ({
              type: "text",
              required: true,
              error: validationErrors?.[cell.id],
              //store edited indicator in state to be saved later
              onBlur: (event) => {
                const validationError = !validateRequired(
                  event.currentTarget.value
                )
                  ? "Required"
                  : undefined;
                setValidationErrors({
                  ...validationErrors,
                  [cell.id]: validationError,
                });
                setEditedIndicators({
                  ...editedIndicators,
                  [row.id]: row.original,
                });
                handleSaveCell(cell, event.currentTarget.value);
              },
            }),
          }))
        : [],
    [editedIndicators, validationErrors, excelData]
  );

  useEffect(() => {
    if (excelData.length > 0 && active < 2) {
      const dt = excelDataToJson(excelData);
      setIndicatorTableData(dt);
    }
  }, [excelData, active]);

  const indicatorsTable = useMantineReactTable({
    columns: indicatorTableColumns,
    data: indicatorTableData,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "table", // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    getRowId: (row, idx) => idx.toString(),
    // mantineToolbarAlertBannerProps: isLoadingIndicatorError
    //   ? {
    //       color: 'red',
    //       children: 'Error loading data',
    //     }
    //   : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    initialState: {
      pagination: { pageSize: 5, pageIndex: 0 },
      density: "xs",
    },
    renderRowActions: ({ row }) => (
      <Tooltip label="Delete">
        <ActionIcon color="red" onClick={() => console.log(row)}>
          <IconTrash />
        </ActionIcon>
      </Tooltip>
    ),
  });

  const handleSaveCell = (cell: MRT_Cell<any>, value: string) => {
    //@ts-ignore
    setIndicatorTableData((prevItems) => {
      // Create a new array with the updated item
      const updatedItems = [...prevItems];
      updatedItems[+cell.row.index] = {
        ...updatedItems[+cell.row.index],
        [cell.column.id]: value,
      };
      return updatedItems;
    });
  };

  const handleFileRemove = (path: string) =>
    setUploadedFiles(uploadedFiles.filter((file) => file.path !== path));

  const {
    fetchedData: fetchedIndicatorData,
    isFetching: isLoadingIndicatorData,
    // isLoading: isLoadingIndicatorData,
  } = useFetchIndicatorData<IIndicatorDataApiResponse>(
    `${INDICATOR_KEY}/${selectedIndicator}`,
    token ? token : "",
    !!selectedIndicator
  );

  return (
    <>
      <Stepper
        active={active}
        onStepClick={setActive}
        breakpoint="sm"
        size="sm"
        my="xl"
      >
        <Stepper.Step
          label="Import CSV"
          description="Load data from CSV"
          allowStepSelect={false}
        >
          <Grid>
            <Grid.Col sm={12} md={6} lg={6}>
              <Select
                label="Sector"
                placeholder="Sector"
                disabled={isFetchingSectors}
                withAsterisk
                value={selectedSector}
                onChange={setSelectedSector}
                data={
                  fetchedSectors && Array.isArray(fetchedSectors.data)
                    ? fetchedSectors.data.map((sector) => ({
                        value: sector.id.toString(),
                        label: sector.name,
                      }))
                    : []
                }
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={6}>
              <Select
                label="Indicator"
                placeholder="Indicator"
                searchable
                disabled={indicators.length === 0}
                withAsterisk
                value={selectedIndicator}
                onChange={setSelectedIndicator}
                data={indicators}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={12} lg={12}>
              <Group position="right">
                <Button
                  leftIcon={<IconDownload />}
                  variant="subtle"
                  onClick={() => {
                    if (!selectedIndicator) {
                      notifications.show({
                        title: "Error",
                        message: "Please select an indicator",
                        icon: <IconX />,
                        color: "red",
                      });
                      return;
                    }
                    const data = getIndicatorData(fetchedIndicatorData);
                    const ind = indicators.filter(
                      (indicator) => indicator.value === selectedIndicator
                    );

                    downloadCSV(
                      data.map((dt) => ({
                        "Indicator Index": selectedIndicator,
                        "Sub County": dt.constituency,
                        Period: dt.period,
                        "Period Type": dt.period_type,
                        Key: dt.key,
                        Value: dt.value,
                        "Value Type": dt.value_type,
                        Group: dt.group,
                        Measurement: dt.measurement,
                        Unit: dt.unit,
                        Ward: dt.ward,
                      })),
                      ind.length > 0
                        ? `${ind[0].label}-${new Date().toISOString()}`
                        : `indicator-data-${new Date().toISOString()}`
                    );
                  }}
                  disabled={
                    isLoadingIndicatorData ||
                    getIndicatorData(fetchedIndicatorData).length === 0
                  }
                >
                  Download current indicator data
                </Button>
              </Group>
            </Grid.Col>
            <Grid.Col sm={12} md={12} lg={12}>
              <Dropzone
                onDrop={async (files) => {
                  setUploadedFiles(files);
                  try {
                    const data = await readExcelFile(files[0]);
                    const jsonData = excelDataToJson(data);
                    setExcelData(data);
                    setIndicatorData(jsonData);
                  } catch (error) {}
                }}
                onReject={(files) => {
                  for (let i = 0; i < files[0].errors.length; i++) {
                    notifications.show({
                      title: "Error",
                      message:
                        files[0].errors[i].code === "file-too-large"
                          ? "File is larger than 20Mb"
                          : "Error uploading your file, check the file format",
                      icon: <IconX />,
                      color: "red",
                    });
                  }
                }}
                maxSize={20 * 1024 ** 2}
                accept={[...MS_EXCEL_MIME_TYPE, "text/csv"]}
                maxFiles={1}
                my="lg"
              >
                <Group
                  position="center"
                  spacing="xl"
                  style={{ minHeight: rem(220), pointerEvents: "none" }}
                >
                  <Dropzone.Accept>
                    <IconUpload
                      size="3.2rem"
                      stroke={1.5}
                      color={
                        theme.colors[theme.primaryColor][
                          theme.colorScheme === "dark" ? 4 : 6
                        ]
                      }
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX
                      size="3.2rem"
                      stroke={1.5}
                      color={
                        theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                      }
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <IconPhoto size="3.2rem" stroke={1.5} />
                  </Dropzone.Idle>

                  <div>
                    <Text size="md" inline>
                      Drag indicator files here or click to select files
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}>
                      {`${
                        uploadedFiles.length > 0
                          ? `${uploadedFiles.length} file uploaded`
                          : "Each file should not exceed 20mb"
                      }`}
                    </Text>
                  </div>
                </Group>
              </Dropzone>
              <SimpleGrid
                cols={1}
                breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                mt={uploadedFiles.length > 0 ? "xl" : 0}
              >
                {uploadedFiles.map((file, index) => {
                  return (
                    <MediaPreview
                      key={index}
                      icon={getIcon(file.type)}
                      name={file.name}
                      size={formatFileSize(file.size)}
                      path={file.path ? file.path : ""}
                      handleRemove={handleFileRemove}
                      isUrl={false}
                    />
                  );
                })}
              </SimpleGrid>
            </Grid.Col>
          </Grid>
        </Stepper.Step>
        <Stepper.Step
          label="Preview Indicators"
          description="View uploaded indicator data"
          allowStepSelect={false}
        >
          <Stack spacing="xl">
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title="Indicator"
              color={PRIMARY_COLOR}
              variant="light"
            >
              {getIndicatorLabelFromIndex(indicators, selectedIndicator)}
            </Alert>
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              color={PRIMARY_COLOR}
              variant="light"
            >
              Click on a table field item to edit
            </Alert>
            <MantineReactTable table={indicatorsTable} />
          </Stack>
        </Stepper.Step>
        <Stepper.Step
          label="Import Indicator Data"
          description="Create indicator data from uploaded data"
          allowStepSelect={false}
        >
          <Center mt="xl">
            <Flex
              gap="lg"
              justify="center"
              align="center"
              direction="column"
              wrap="wrap"
            >
              <Group mt="xl" spacing="sm">
                <IconAlertCircle size="2rem" />
                <Text fz="xl">
                  <span
                    style={{ fontWeight: "bolder" }}
                  >{`${indicatorData.length}`}</span>{" "}
                  items will be uploaded
                </Text>
              </Group>
              <Text fz="sm">
                The total number of data items found in the CSV file.
              </Text>
            </Flex>
          </Center>
        </Stepper.Step>
        <Stepper.Completed>
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title=""
            color="violet"
            variant="light"
          >
            All data wes successfully uploaded
          </Alert>
        </Stepper.Completed>
      </Stepper>
      {active === 3 ? (
        <Group position="center" mt="xl">
          <Button
            leftIcon={<IconCheck />}
            onClick={() => navigate(SECTORS.indicators.link)}
          >
            Finish
          </Button>
        </Group>
      ) : (
        <Group position="right" mt="xl">
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
          <Button
            disabled={
              (active === 0 && uploadedFiles.length === 0) ||
              !selectedSector ||
              !selectedIndicator
            }
            onClick={async () => {
              const res = arrayKeysToSnakeCase(indicatorTableData);
              if (active === 1) {
                if (!selectedIndicator) {
                  notifications.show({
                    title: "Missing Data",
                    message: `Please select an indicator`,
                    icon: <IconX />,
                    color: "red",
                  });
                  return;
                }
                for (let i = 0; i < res.length - 1; i++) {
                  if (!res[i].indicator_index) {
                    notifications.show({
                      title: "Missing Data",
                      message: `the row on index ${
                        i + 1
                      } has no indicator index`,
                      icon: <IconX />,
                      color: "red",
                    });
                    return;
                  }
                  //
                  const indIdx = getIndidicatorFromIndex(
                    indicators,
                    selectedIndicator
                  );
                  if (typeof indIdx === "string" && indIdx.length === 0) {
                    notifications.show({
                      title: "Missing Data",
                      message: `We could not find the indicator index for the indicator selected`,
                      icon: <IconX />,
                      color: "red",
                    });
                    return;
                  }

                  if (selectedIndicator !== res[i].indicator_index.toString()) {
                    console.log(res[i].indicator_index.toString());
                    notifications.show({
                      title: "Indicator Mismatch",
                      message: `the selected indicator index ${selectedIndicator} does not match the indicator at row ${
                        i + 1
                      }`,
                      icon: <IconX />,
                      color: "red",
                    });
                    return;
                  }
                }
              }
              if (active === 2) {
                try {
                  if (!selectedIndicator) {
                    notifications.show({
                      title: "Missing Data",
                      message: `Please select an indicator`,
                      icon: <IconX />,
                      color: "red",
                    });
                    return;
                  }
                  await bulkMutation.mutate(
                    {
                      id: selectedIndicator,
                      payload: res.map((iData) => ({
                        group: iData.group ? iData.group : "",
                        indicator_index: iData.indicator_index
                          ? iData.indicator_index.toString()
                          : "",
                        key: iData.key ? iData.key : "",
                        measurement: iData.measurement ? iData.measurement : "",
                        period: iData.period ? iData.period : "",
                        period_type: iData.period_type ? iData.period_type : "",
                        sub_county: iData.sub_county ? iData.sub_county : "",
                        unit: iData.unit ? iData.unit : "",
                        value: iData.value ? iData.value : "",
                        value_type: iData.value_type ? iData.value_type : "",
                        ward: iData.ward ? iData.ward : "",
                      })),
                    },
                    {
                      onSuccess: () => {
                        notifications.show({
                          title: "Success",
                          message: "All data has been uploaded",
                          icon: <IconCheck />,
                          color: "teal",
                        });
                        nextStep();
                      },
                      onSettled(data, error, variables, context) {
                        queryClient.invalidateQueries([INDICATORS_KEY]);
                      },
                      onError: (
                        error: any,
                        variables: IBulkIndicatorUpload,
                        context: unknown
                      ) => {
                        notifications.show({
                          title: "Error",
                          message:
                            error && error.data
                              ? error.data
                              : "An error occured, try again",
                          icon: <IconX />,
                          color: "red",
                        });
                      },
                    }
                  );
                } catch (error: any) {
                  notifications.show({
                    title: "Error",
                    message:
                      error && error.data
                        ? error.data
                        : "An error occured, try again",
                    icon: <IconX />,
                    color: "red",
                  });
                }
              } else {
                nextStep();
              }
            }}
          >
            Next step
          </Button>
        </Group>
      )}
    </>
  );
};

export default BulkIndicatorUpload;
