import { MantineColor } from "@mantine/core";
import { ISubMenu, ISubMenuLinks } from "./types";
import {
  IconChartDots,
  IconChartInfographic,
  IconEditCircle,
  IconFileSearch,
  IconFileUpload,
  IconInfoCircle,
  IconMapPin,
  IconMapPlus,
  IconMoneybag,
  IconUsersGroup,
} from "@tabler/icons-react";
import { IconUsers } from "@tabler/icons-react";

export const BASE_URL = process.env.REACT_APP_BASEURL || "";
export const REPORTS_URL = process.env.REACT_APP_NGROK || "";
export const ROOT_PATH = "";
export const DASHBOARD_PATH = "/";
export const HEALTH_DASHBOARD_PATH = "/health";

export const PROJECTS = {
  view: {
    category: "projects",
    link: `${ROOT_PATH}/projects`,
  },
  add: {
    category: "projects",
    link: `${ROOT_PATH}/projects/new`,
  },
};

export const MEDIA = {
  category: "media",
  link: `${ROOT_PATH}/documents`,
};

export const BUDGETS = {
  revenue: {
    category: "budgets",
    link: `${ROOT_PATH}/budgets/revenue`,
  },
  expenditure: {
    category: "expenditure",
    link: `${ROOT_PATH}/budgets/expenditure`,
  },
  home: {
    category: "expenditure",
    link: `${ROOT_PATH}/budgets`,
  },
};

export const SECTORS = {
  sectors: {
    category: "sectors",
    link: `${ROOT_PATH}/sectors`,
    add: `${ROOT_PATH}/sectors/new`,
    edit: (id: string) => `${ROOT_PATH}/sectors/${id}`,
  },
  indicators: {
    category: "indicators",
    link: `${ROOT_PATH}/indicators`,
    add: `${ROOT_PATH}/indicators/new`,
    manage_data: `${ROOT_PATH}/indicators/data`,
    edit: (id: string) => `${ROOT_PATH}/indicators/${id}`,
  },
};

export const SETTINGS = {
  users: {
    category: "settings",
    link: `${ROOT_PATH}/users`,
    add: `${ROOT_PATH}/users/new`,
    edit: (id: string) => `${ROOT_PATH}/users/${id}`,
  },
  departments: {
    category: "departments",
    link: `${ROOT_PATH}/departments`,
    add: `${ROOT_PATH}/departments/new`,
    edit: (id: string) => `${ROOT_PATH}/departments/${id}`,
  },
  constituencies: {
    category: "constituencies",
    link: `${ROOT_PATH}/constituencies`,
    add: `${ROOT_PATH}/constituencies/new`,
    edit: (id: string) => `${ROOT_PATH}/constituencies/${id}`,
  },
  wards: {
    category: "wards",
    link: `${ROOT_PATH}/wards`,
    add: `${ROOT_PATH}/wards/new`,
    edit: (id: string) => `${ROOT_PATH}/wards/${id}`,
  },
  about: {
    category: "settings",
    link: `${ROOT_PATH}/county-information`,
    add: `${ROOT_PATH}/county-information/new`,
  },
};

// nav list menu
export const PROJECT_MENU_ITEMS: ISubMenu[] = [
  {
    title: "Add Projects",
    description: "Bulk upload projects",
    category: PROJECTS.view.category,
    link: `${PROJECTS.add.link}`,
  },
  {
    title: "Manage Projects",
    description: "Manage single projects",
    category: PROJECTS.view.category,
    link: PROJECTS.view.link,
  },
];

export const BUDGET_MENU_ITEMS: ISubMenu[] = [
  {
    title: "Revenue",
    description: "Manage County revenue",
    category: BUDGETS.revenue.category,
    link: `${BUDGETS.revenue.link}`,
  },
  {
    title: "Expenditure",
    description: "Manager county expenditure",
    category: BUDGETS.expenditure.category,
    link: BUDGETS.expenditure.link,
  },
];

export const SECTOR_MENU_ITEMS: ISubMenu[] = [
  {
    title: "Sectors",
    description: "Manage Sectors",
    category: SECTORS.sectors.category,
    link: `${SECTORS.sectors.link}`,
  },
  {
    title: "Indicators",
    description: "Manage Indicators",
    category: SECTORS.indicators.category,
    link: `${SECTORS.indicators.link}`,
  },
];

export const MEDIA_MENU_ITEMS: ISubMenu[] = [
  {
    title: "Media",
    description: "Manage County Media",
    category: MEDIA.category,
    link: `${MEDIA.link}`,
  },
];

export const SETTINGS_MENU_ITEMS: ISubMenu[] = [
  {
    title: "Users",
    description: "Manage Users",
    category: SETTINGS.users.link,
    link: SETTINGS.users.link,
  },
  {
    title: "County Information",
    description: "Manage County Information",
    category: SETTINGS.about.category,
    link: SETTINGS.about.link,
  },
];

export const submenuItems: {
  projects: ISubMenuLinks[];
  budgets: ISubMenuLinks[];
  media: ISubMenuLinks[];
  settings: ISubMenuLinks[];
  indicators: ISubMenuLinks[];
} = {
  projects: [
    {
      title: "Manage Projects",
      description: "Manage all projects",
      icon: IconMapPin,
      link: PROJECTS.view.link,
    },
    {
      title: "Bulk Upload Projects",
      description: "Add multiple projects via a CSV file",
      icon: IconMapPlus,
      link: `${PROJECTS.add.link}`,
    },
  ],
  budgets: [
    {
      title: "Revenue",
      description: "Manage County revenue",
      icon: IconMoneybag,
      link: `${BUDGETS.revenue.link}`,
    },
    {
      title: "Expenditure",
      description: "Manager county expenditure",
      icon: IconChartDots,
      link: BUDGETS.expenditure.link,
    },
  ],
  media: [
    {
      title: "Media",
      description: "Manage County Media",
      icon: IconFileSearch,
      link: `${MEDIA.link}`,
    },
  ],
  indicators: [
    {
      title: "Indicators",
      description: "Manage Indicators",
      icon: IconChartInfographic,
      link: `${SECTORS.indicators.link}`,
    },
    {
      title: "Upload Indicator Data",
      description: "Add indicator data via a CSV file",
      icon: IconFileUpload,
      link: `${SECTORS.indicators.add}`,
    },
    {
      title: "Manage Indicator Data",
      description: "Manage data added via indicator upload",
      icon: IconEditCircle,
      link: `${SECTORS.indicators.manage_data}`,
    },
  ],
  settings: [
    {
      title: "Users",
      description: "Manage Users",
      icon: IconUsers,
      link: SETTINGS.users.link,
    },
    // {
    //   title: "Constituencies",
    //   description: "Manage Constituencies",
    //   icon: IconMap2,
    //   link: SETTINGS.constituencies.link,
    // },
    // {
    //   title: "Wards",
    //   description: "Manage Wards",
    //   icon: IconMap2,
    //   link: SETTINGS.wards.link,
    // },
    {
      title: "Departments",
      description: "Manage Departments",
      icon: IconUsersGroup,
      link: SETTINGS.departments.link,
    },
    {
      title: "County Information",
      description: "Manage County Information",
      icon: IconInfoCircle,
      link: SETTINGS.about.link,
    },
  ],
};

export const SECTOR_SUMMARY_IMAGES = [
  "./sector-summary.png",
  "./sector-summary-2.png",
  "./sector-summary-3.png",
  "./sector-summary-4.png",
  "./sector-summary-5.png",
  "./sector-summary-6.png",
];

export const PRIMARY_COLOR: MantineColor = "green";
