import { useMemo } from "react";
import {
  MRT_Table as MinifiedTable, //import alternative sub-component if we do not want toolbars
  type MRT_ColumnDef,
  useMantineReactTable,
  MRT_Row,
} from "mantine-react-table";
import {
  ActionIcon,
  Avatar,
  Group,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import {
  COUNTY_INFO_KEY,
  Leader,
  MUTATE_LEADERS_INFO_KEY,
} from "../utils/types";
import { formatColumnName, getInitials } from "../utils/fns";
import { IconCheck, IconTrash, IconX } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuthContext } from "../store/AuthContext";
import { notifications } from "@mantine/notifications";

export const LeadersTable = ({ leaders }: { leaders: Leader[] }) => {
  const { colorScheme } = useMantineTheme();
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await axiosInstance.delete(`${MUTATE_LEADERS_INFO_KEY}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const columns = useMemo<MRT_ColumnDef<Leader>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        Cell: ({ row }) => (
          <Group spacing="sm">
            {row.original.avatar ? (
              <Avatar src={row.original.avatar} size={32} radius={26} />
            ) : (
              <Avatar size={32} radius={26}>
                {getInitials(row.original.name)}
              </Avatar>
            )}
            <Text size="sm" fw={500}>
              {row.original.name}
            </Text>
          </Group>
        ),
      },
      {
        accessorKey: "key",
        header: "Designation",
        Cell: ({ row }) => <Text>{formatColumnName(row.original.key)}</Text>,
      },
      {
        accessorKey: "twitter",
        header: "Social Media",
      },
    ],
    []
  );

  const openDeleteConfirmModal = (row: MRT_Row<Leader>) =>
    modals.openConfirmModal({
      title: `Delete ${row.original.name}`,
      children: (
        <Text>Confirm delete action. This action cannot be undone.</Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await deleteMutation.mutate(row.id, {
            onSuccess: () => {
              notifications.show({
                title: "Success",
                message: "Item deleted successfully",
                icon: <IconCheck />,
                color: "green",
              });
            },
            onError(error: any, variables, context) {
              notifications.show({
                title: "Error",
                message:
                  error && error.data
                    ? error.data
                    : "An error occured, try again",
                icon: <IconX />,
                color: "red",
              });
            },
            onSettled(data, error, variables, context) {
              queryClient.invalidateQueries([COUNTY_INFO_KEY]);
            },
          });
        } catch (error) {}
      },
    });

  const table = useMantineReactTable({
    columns,
    data: leaders,
    enableColumnActions: true,
    getRowId: (row) => row.id.toString(),
    positionActionsColumn: "last",
    enableEditing: true,
    enableSorting: false,
    renderRowActions: ({ row }) => (
      <div className="flex flex-row">
        {/* <Tooltip label="Edit">
          <ActionIcon onClick={() => console.log(row.original.key)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip> */}
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </div>
    ),
    mantineTableProps: {
      highlightOnHover: false,
      withColumnBorders: true,
      withBorder: colorScheme === "light",
      sx: {
        "thead > tr": {
          backgroundColor: "inherit",
        },
        "thead > tr > th": {
          backgroundColor: "inherit",
        },
        "tbody > tr > td": {
          backgroundColor: "inherit",
        },
      },
    },
  });

  //using MRT_Table instead of MantineReactTable if we do not want any of the toolbar features
  return <MinifiedTable table={table} />;
};

export default LeadersTable;
