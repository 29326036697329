import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  PasswordInput,
  Flex,
  Progress,
  Text,
  Popover,
  Box,
} from "@mantine/core";
import { IconCheck, IconX, IconUserQuestion } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { validateEmail } from "../utils/fns";
import { AxiosResponse } from "axios";
import axiosInstance from "../utils/axios";
import { PRIMARY_COLOR } from "../utils/config";
import useFetchCountyLogo from "../hooks/useFetchCountyLogo";

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text
      color={meets ? "teal" : "red"}
      sx={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? <IconCheck size="0.9rem" /> : <IconX size="0.9rem" />}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: "Includes number" },
  { re: /[a-z]/, label: "Includes lowercase letter" },
  { re: /[A-Z]/, label: "Includes uppercase letter" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
];

function getStrength(password: string) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [value, setValue] = useState("");
  const [confirmValue, setConfirmValue] = useState("");
  const { logo, isLoading: isLoadingCountyLogo } = useFetchCountyLogo();

  const [params, setParams] = useSearchParams();

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));

  const strength = getStrength(value);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <Flex
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
        >
          {!isLoadingCountyLogo && (
            <img
              src={logo}
              alt="cms"
              className="max-w-xs max-h-44 w-auto mx-auto object-cover"
            />
          )}
          <div className="max-w-sm break-words">
            <p className="text-center text-sm text-gray-500">
              {`New password ${
                params.get("email") ? `for account: ${params.get("email")}` : ""
              }`}
            </p>
          </div>
        </Flex>
        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
          <Popover
            opened={popoverOpened}
            position="bottom"
            width="target"
            transitionProps={{ transition: "pop" }}
          >
            <Popover.Target>
              <div
                onFocusCapture={() => setPopoverOpened(true)}
                onBlurCapture={() => setPopoverOpened(false)}
                className="mb-4 flex flex-col gap-6"
              >
                <PasswordInput
                  withAsterisk
                  label="New password"
                  placeholder="Your password"
                  value={value}
                  onChange={(event) => setValue(event.currentTarget.value)}
                />
                <PasswordInput
                  withAsterisk
                  label="Confirm password"
                  placeholder="Confirm Password"
                  value={confirmValue}
                  onChange={(event) =>
                    setConfirmValue(event.currentTarget.value)
                  }
                />
                <Button
                  leftIcon={<IconUserQuestion size="1rem" />}
                  type="submit"
                  variant="filled"
                  fullWidth
                  loading={isLoading}
                  onClick={async () => {
                    if (value !== confirmValue) {
                      notifications.show({
                        message: "passwords do not match",
                        color: "red",
                      });
                      return;
                    }

                    const email = params.get("email");

                    if (!email) {
                      notifications.show({
                        message:
                          "unable to reset your password, please open the link sent on mail then try again",
                        color: "red",
                        icon: <IconX />,
                      });
                      return;
                    }

                    if (!validateEmail(email)) {
                      notifications.show({
                        message:
                          "unable to reset your password, please open the link sent on mail then try again",
                        color: "red",
                        icon: <IconX />,
                      });
                      return;
                    }

                    const token = params.get("token");

                    if (!token) {
                      notifications.show({
                        message:
                          "unable to reset your password, please open the link sent on mail then try again",
                        color: "red",
                        icon: <IconX />,
                      });
                      return;
                    }
                    setIsLoading(true);
                    try {
                      const {
                        data,
                        status,
                      }: AxiosResponse<{ message: string }> =
                        await axiosInstance.post(`/password/reset`, {
                          email,
                          password: value,
                          password_confirmation: confirmValue,
                          token,
                        });
                      if (status !== 200) {
                        notifications.show({
                          message:
                            "unable to reset your password, please try again",
                          color: "red",
                          icon: <IconX />,
                        });
                        return;
                      }
                      notifications.show({
                        message: data.message,
                        icon: <IconCheck />,
                        color: PRIMARY_COLOR,
                      });
                      setIsLoading(false);
                      navigate("/login", { replace: true });
                      //setLoginClicked(true);
                    } catch (error: any) {
                      setIsLoading(false);
                      notifications.show({
                        message:
                          error && error.data && error.data.message
                            ? error.data.message
                            : "An error occured, try again",
                        icon: <IconX />,
                        color: "red",
                      });
                    }
                  }}
                >
                  Submit
                </Button>
              </div>
            </Popover.Target>
            <Popover.Dropdown>
              <Progress color={color} value={strength} size={5} mb="xs" />
              <PasswordRequirement
                label="Includes at least 6 characters"
                meets={value.length > 5}
              />
              {checks}
            </Popover.Dropdown>
          </Popover>
          <p className="mt-10 text-center text-sm text-gray-500">
            Go to{" "}
            <Link
              to="/login"
              className="font-semibold leading-6 text-teal-600 hover:text-teal-500"
            >
              login
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
