import {
  Alert,
  Button,
  Center,
  Grid,
  Group,
  Loader,
  TextInput,
  Title,
} from "@mantine/core";
import DocItem from "../components/DocItem";
import { DOCUMENTS_KEY, IDocApiResponse } from "../utils/types";
import { useAuthContext } from "../store/AuthContext";
import { IconAlertCircle, IconPlus } from "@tabler/icons-react";
import { getDocs } from "../utils/fns";
import { useState } from "react";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import NewDocument from "../sections/NewDocument";
import useFetchData from "../hooks/useFetchBudgets";

const Documents = () => {
  const { token } = useAuthContext();
  const [value, setValue] = useState("");
  const [debounced] = useDebouncedValue(value, 200);
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const {
    fetchedData: fetchedDocs,
    isError,
    // isFetching: isFetchingDepartments,
    isLoading,
  } = useFetchData<IDocApiResponse>(DOCUMENTS_KEY, token ? token : "");

  if (isError)
    return (
      <Alert icon={<IconAlertCircle size="1rem" />} title="Error!" color="red">
        We were unable to retreive the documents
      </Alert>
    );

  return isLoading ? (
    <Center>
      <Loader />
    </Center>
  ) : (
    <Grid>
      <Grid.Col span={12}>
        <Title order={4}>{`${
          getDocs(fetchedDocs).length
        } documents found`}</Title>
      </Grid.Col>
      <Grid.Col span={12}>
        <Group position="apart">
          <TextInput
            label="Search for document"
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
          />
          <Button leftIcon={<IconPlus />} onClick={openDrawer}>
            Add Documents
          </Button>
        </Group>
      </Grid.Col>
      {/* <ScrollArea h={600} offsetScrollbars mt="md"> */}
      <Grid.Col span={12}>
        {getDocs(fetchedDocs)
          .filter((doc) =>
            doc.title.toLowerCase().includes(debounced.toLowerCase().trim())
          )
          .map((document) => (
            <DocItem key={document.id} doc={document} />
          ))}
      </Grid.Col>
      {/* </ScrollArea> */}
      <Grid.Col span={12}>
        <NewDocument closeDrawer={closeDrawer} drawerOpened={drawerOpened} />
      </Grid.Col>
    </Grid>
  );
};

export default Documents;
