import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "@mantine/form";
import { TextInput, Button, Flex } from "@mantine/core";
import { IconCheck, IconUserQuestion } from "@tabler/icons-react";
import axiosInstance from "../utils/axios";
import { AxiosResponse } from "axios";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import { PRIMARY_COLOR } from "../utils/config";
import useFetchCountyLogo from "../hooks/useFetchCountyLogo";

const ForgotPassword = () => {
  const { logo, isLoading: isLoadingCountyLogo } = useFetchCountyLogo();
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "provide a valid email",
    },
  });

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <Flex
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
        >
          {!isLoadingCountyLogo && (
            <img
              src={logo}
              alt="cms"
              className="max-w-xs max-h-44 w-auto mx-auto object-cover"
            />
          )}
          <div className="max-w-sm break-words">
            <p className="text-center text-sm text-gray-500">
              Enter the email address associated with your account and we'll
              send you a link to reset your password
            </p>
          </div>
        </Flex>
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="mb-2 w-80 max-w-screen-lg sm:w-96"
            onSubmit={form.onSubmit(async ({ email }) => {
              setIsLoading(true);
              try {
                const {
                  data,
                  status,
                }: AxiosResponse<{
                  data: {
                    message: string;
                  };
                }> = await axiosInstance.post(
                  "/password/email",
                  { email },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );
                if (status === 200) {
                  notifications.show({
                    message:
                      data && data.data && data.data.message
                        ? data.data.message
                        : "Please check your email for a password reset link",
                    icon: <IconCheck />,
                    color: PRIMARY_COLOR,
                  });
                }
                setIsLoading(false);
              } catch (error: any) {
                setIsLoading(false);
                notifications.show({
                  message:
                    error && error.data && error.data.message
                      ? error.data.message
                      : "An error occured, try again",
                  icon: <IconX />,
                  color: "red",
                });
              }
            })}
          >
            <div className="mb-4 flex flex-col gap-6">
              <TextInput
                mt="md"
                label="Email"
                placeholder="Email"
                {...form.getInputProps("email")}
              />
            </div>
            <Button
              leftIcon={<IconUserQuestion size="1rem" />}
              type="submit"
              variant="filled"
              fullWidth
              loading={isLoading}
            >
              Submit
            </Button>
          </form>
          <p className="mt-10 text-center text-sm text-gray-500">
            Go to{" "}
            <Link
              to="/login"
              className={`font-semibold leading-6 text-${PRIMARY_COLOR}-700 hover:text-${PRIMARY_COLOR}-700`}
            >
              login
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
