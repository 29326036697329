import { useMemo } from "react";
import {
  MRT_Table as MinifiedTable,
  type MRT_ColumnDef,
  useMantineReactTable,
  MRT_Row,
} from "mantine-react-table";
import {
  ActionIcon,
  Group,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import {
  Icon24Hours,
  IconAddressBook,
  IconCheck,
  IconEdit,
  IconMailCheck,
  IconPhoneCall,
  IconTrash,
  IconWorldWww,
  IconX,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuthContext } from "../store/AuthContext";
import { notifications } from "@mantine/notifications";

const resolveContactIcon = (name: string, size: number): JSX.Element => {
  if (name === "phone") return <IconPhoneCall size={size} color="green" />;
  if (name === "email") return <IconMailCheck size={size} color="red" />;
  if (name === "website") return <IconWorldWww size={size} color="blue" />;
  if (name === "address") return <IconAddressBook size={size} color="indigo" />;
  return <Icon24Hours size={size} />;
};

interface IContactArry {
  name: string;
  value: string;
}

export const ContactsTable = ({ contacts }: { contacts: IContactArry[] }) => {
  const { colorScheme } = useMantineTheme();
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await axiosInstance.delete(`${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const columns = useMemo<MRT_ColumnDef<IContactArry>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Contact",
        Cell: ({ row }) => (
          <Group spacing="sm">
            {resolveContactIcon(row.original.name, 32)}
            <Text size="sm" fw={500} transform="capitalize">
              {row.original.name}
            </Text>
          </Group>
        ),
      },
      {
        accessorKey: "value",
        header: "Information",
        Cell: ({ row }) => <Text>{row.original.value}</Text>,
      },
    ],
    []
  );

  const openDeleteConfirmModal = (row: MRT_Row<IContactArry>) =>
    modals.openConfirmModal({
      title: `Delete ${row.original.name}`,
      children: (
        <Text>Confirm delete action. This action cannot be undone.</Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await deleteMutation.mutate(row.id, {
            onSuccess: () => {
              notifications.show({
                title: "Success",
                message: "Item deleted successfully",
                icon: <IconCheck />,
                color: "green",
              });
            },
            onError(error: any, variables, context) {
              notifications.show({
                title: "Error",
                message:
                  error && error.data
                    ? error.data
                    : "An error occured, try again",
                icon: <IconX />,
                color: "red",
              });
            },
            onSettled(data, error, variables, context) {
              queryClient.invalidateQueries([""]);
            },
          });
        } catch (error) {}
      },
    });

  const table = useMantineReactTable({
    columns,
    data: contacts,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    renderRowActions: ({ row }) => (
      <div className="flex flex-row">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => console.log(row.original.name)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </div>
    ),
    mantineTableProps: {
      highlightOnHover: false,
      withColumnBorders: true,
      withBorder: colorScheme === "light",
      sx: {
        "thead > tr": {
          backgroundColor: "inherit",
        },
        "thead > tr > th": {
          backgroundColor: "inherit",
        },
        "tbody > tr > td": {
          backgroundColor: "inherit",
        },
      },
    },
  });

  //using MRT_Table instead of MantineReactTable if we do not want any of the toolbar features
  return <MinifiedTable table={table} />;
};

export default ContactsTable;
