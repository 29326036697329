import { useMemo, useState } from "react";
import {
  Button,
  Text,
  Tooltip,
  ActionIcon,
  Grid,
  ColorSwatch,
  Title,
} from "@mantine/core";

import {
  DEPARTMENTS_KEY,
  IDepartment,
  IDepartmentsApiResponse,
  MUTATE_DEPARTMENTS_KEY,
} from "../utils/types";
import { IconCheck, IconEdit } from "@tabler/icons-react";
import { useAuthContext } from "../store/AuthContext";
import { useDisclosure } from "@mantine/hooks";
import {
  MRT_ColumnDef,
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import NewDepartment from "../sections/NewDepartment";
import { PRIMARY_COLOR } from "../utils/config";
import ViewDepartment from "../sections/ViewDepartment";
import useFetchData from "../hooks/useFetchBudgets";

const Departments = () => {
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [
    updateDrawerOpened,
    { open: openUpdateDrawer, close: closeUpdateDrawer },
  ] = useDisclosure(false);
  const [foundDepartment, setFoundDepartment] = useState<
    IDepartment | undefined
  >(undefined);
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await axiosInstance.delete(`${MUTATE_DEPARTMENTS_KEY}?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const {
    fetchedData: fetchedDepartments,
    isError: isLoadingDepartmentsError,
    // isFetching: isFetchingDepartments,
    isLoading: isLoadingDepartments,
  } = useFetchData<IDepartmentsApiResponse>(
    DEPARTMENTS_KEY,
    token ? token : ""
  );

  const openDeleteConfirmModal = (row: MRT_Row<IDepartment>) =>
    modals.openConfirmModal({
      title: `Delete ${row.original.name}`,
      children: (
        <Text>
          Are you sure you want to delete this department? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await deleteMutation.mutate(row.id, {
            onSuccess: () => {
              notifications.show({
                title: "Success",
                message: "Department deleted",
                icon: <IconCheck />,
                color: "teal",
              });
            },
            onError(error: any, variables, context) {
              notifications.show({
                title: "Error",
                message:
                  error && error.data
                    ? error.data
                    : "An error occured, try again",
                icon: <IconX />,
                color: "red",
              });
            },
            onSettled(data, error, variables, context) {
              queryClient.invalidateQueries([MUTATE_DEPARTMENTS_KEY]);
            },
          });
        } catch (error) {}
      },
    });

  const handleUpdateDepartment = (row: MRT_Row<IDepartment>) => {
    setFoundDepartment(row.original);
    openUpdateDrawer();
  };

  const columns = useMemo<MRT_ColumnDef<IDepartment>[]>(
    () => [
      {
        accessorKey: "id",
        enableEditing: false,
        header: "Color",
        Cell: ({ row }) => {
          return (
            <div className="flex items-center gap-3">
              <ColorSwatch
                color={row.original.color ? row.original.color : "#000000"}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        enableEditing: false,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data:
      fetchedDepartments && Array.isArray(fetchedDepartments.data)
        ? fetchedDepartments.data
        : [],
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "cell", // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    getRowId: (row) => (row.id ? row.id.toString() : ""),
    initialState: {
      density: "xs", //set default density to compact
    },
    mantineToolbarAlertBannerProps: isLoadingDepartmentsError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row }) => (
      <div className="flex flex-row">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => handleUpdateDepartment(row)}>
            <IconEdit color={PRIMARY_COLOR} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </div>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="p-2">
        <Button variant="filled" size="sm" onClick={openDrawer}>
          Add Department
        </Button>
      </div>
    ),
    state: {
      isLoading: isLoadingDepartments,
      isSaving: false,
      showAlertBanner: isLoadingDepartmentsError,
      showProgressBars: false,
    },
  });

  return (
    <Grid>
      <Grid.Col span={12}>
        <Title className="styled-heading styled-heading-large">
          Departments
        </Title>
      </Grid.Col>
      <Grid.Col span={12}>
        <MantineReactTable table={table} />
      </Grid.Col>
      <Grid.Col span={12}>
        <NewDepartment closeDrawer={closeDrawer} drawerOpened={drawerOpened} />
      </Grid.Col>
      <Grid.Col span={12}>
        <ViewDepartment
          closeDrawer={closeUpdateDrawer}
          drawerOpened={updateDrawerOpened}
          foundDepartment={foundDepartment}
        />
      </Grid.Col>
    </Grid>
  );
};

export default Departments;
