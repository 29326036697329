import { FileWithPath } from "@mantine/dropzone";
import { TablerIconsProps } from "@tabler/icons-react";
import { ISelectValues } from "../sections/Config";

export interface ISubMenuLinks {
  icon: (props: TablerIconsProps) => JSX.Element;
  title: string;
  description: string;
  link: string;
}

export interface ISubMenu {
  title: string;
  description: string;
  category: string;
  link: string;
}

export interface IManyItemsApi {
  code: number;
  data: Data;
}

export interface Data {
  sector: Sector;
  data: Daum[];
}

export interface Sector {
  id: number;
  name: string;
  icon: string;
}

export interface Daum {
  trend: boolean;
  indicator: Indicator;
  function: string;
  title: string;
  period: string[];
  groups: any[];
  locations: string[];
  keys: string[];
  values: any[];
}

export interface Indicator {
  id: number;
  index: string;
  name: string;
}

export interface IIndicator {
  created_at: string;
  description: string;
  id: number;
  index: string;
  method: string;
  name: string;
  sector_id: number;
  source: string;
  updated_at: string;
}

export interface IBulkIndicatorUpload {
  id: number | string;
  payload: {
    key: string;
    unit: string;
    ward: string;
    group: string;
    value: string;
    period: string;
    value_type: string;
    measurement: string;
    period_type: string;
    sub_county: string;
    indicator_index: string;
  }[];
}

export interface INewIndicatorData {
  index: string;
  constituency_id: number;
  period: string;
  period_type: string;
  key: string;
  value: number;
  value_type: string;
  group?: string;
  measurement?: string;
  unit?: string;
}

export interface ISectors {
  id: number;
  name: string;
  icon: string;
  color: string;
  summary?: {
    data: ISummary;
  };
  indicators_count: number;
  has_summary: boolean;
}

export interface ISectorById {
  id: number;
  name: string;
  icon: string;
  color: string;
  summary?: {
    data: ISectorSummaryRequestBody[];
  };
  indicators: IIndicator[];
}

export interface ISectorByIdApiResponse {
  code: number;
  data: ISectorById;
}

export interface ISummary {
  id: number;
  sector_id: number;
  data: ISectorSummaryRequestBody[];
  created_at: string;
  updated_at: string;
}

export interface ISectorsApiData {
  code: number;
  data: ISectors[];
}

export interface IIconsResponse {
  prefix: string;
  total: number;
  title: string;
  uncategorized: string[];
  hidden: string[];
}

export interface IRawIndicatorApiResponse {
  code: number;
  data: {
    data: IRawIndicator[];
  };
}

export interface IRawIndicator {
  constituency: string;
  group: any;
  id: number;
  key: string;
  location_product: string;
  measurement: any;
  period: string;
  period_type: string;
  unit: any;
  value: number;
  value_type: string;
}

export interface ILineChartData {
  name: string;
  data: number[];
}

// ----- Comment: Sector --------

export interface ISectorsApiResponse {
  data: ISector[];
}

export interface ISector {
  id: number;
  name: string;
  color: string;
  icon: string;
  summary?: any;
  created_at: string;
  updated_at: string;
}

export interface INewSector {
  name: string;
  color: string;
  icon: string;
}

export interface ISectorSummaryRequestBody {
  indicator_id: string;
  title: string;
  item:
    | "card_one"
    | "card_two"
    | "card_three"
    | "card_four"
    | "card_five"
    | "card_six"; //name of card, card 1, card 2 etc
  icon: string | null; // name of icon, only applies to the first element in the array of card 1 and 4
  name: string;
  value: number | null;
  constituencies: string[];
  groups: string[];
  categories: string[]; // to be used in plotting charts - otherwise an empty array in card 1 and 3
  keys: string[];
  periods: string[];
  dataset: any; // to be used in plotting the charts
  //config: any; // the whole page config as JSON - this is what is used to render elements on that card item
}

export interface IMutateSectorSummary {
  id?: number;
  sector_id: number;
  data: any;
}

//-------------------------------

//------------Comment: Indicators --------------

export interface INewIndicator {
  sector_id: number;
  index: string;
  name: string;
  description: string;
  source: string;
  method: string;
}

export interface IIndicatorsApiResponse {
  data: IIndicator[];
}

export interface IIndicator {
  id: number;
  sector_id: number;
  index: string;
  name: string;
  description: string;
  source: string;
  sector: ISector;
  created_at: string;
  updated_at: string;
}

export interface IIndicatorByIdApiResponse {
  data: IIndicator;
}

//----------------------------------------------

// ----- Comment: Departments --------

export interface IDepartmentsApiResponse {
  code: number;
  data: IDepartment[];
}

export interface IDepartment {
  id: number;
  name: string;
  color: string;
  created_at: string;
  updated_at: string;
}

export interface IDepartmentByIdApiResponse {
  code: number;
  data: IDepartment;
}

export interface INewDepartment {
  name: string;
  color: string;
}

// -----------------------------------

// ----- Comment: Constituency --------

export interface IConstituencyApiResponse {
  data: IConstituency[];
}

export interface IConstituency {
  id: number;
  name: string;
  color: string;
  created_at: string;
  updated_at: string;
}

export interface IConstituencyByIdApiResponse {
  data: IConstituency;
}

export interface INewConstituency {
  name: string;
  color: string;
}

// -----------------------------------

// ----- Comment: Ward --------

export interface IWardApiResponse {
  data: IWard[];
}

export interface IWard {
  id: number;
  constituency_id: number;
  constituency?: {
    name: string;
  };
  name: string;
  color: string;
  created_at: string;
  updated_at: string;
}

export interface IWardByIdApiResponse {
  data: IWard;
}

export interface INewWard {
  name: string;
  color: string;
  constituency_id: number;
}

// -----------------------------------

// -------- Comment: Auth -------------
export interface ILoginApiResponse {
  code: number;
  data: ILoginResponse;
}

export interface ILoginResponse {
  token: string;
  user: IUser;
}

export interface IUserApiResponse {
  code: number;
  data: IUser[];
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  email_verified_at: any;
  created_at: string;
  updated_at: string;
}

export interface INewUser {
  name: string;
  email: string;
}
// ------------------------------------

// ---------Comment: Project ----------

export interface IProjectsApiResponse {
  data: IProject[];
}

export interface IProjectByIdApiResponse {
  data: IProject;
}

export interface ICompareProjectsTable {
  name: string;
  project_status: string;
  ward: IWard | null;
  constituency: IConstituency | null;
  period: string;
  amount: string;
  contractor: string;
  coordinates: Coordinates;
  department: IDepartment | null;
  start_date: string;
}

export interface IProjectMutate {
  id: number;
  constituency_id: number;
  ward_id: number;
  department_id: number;
  name: string;
  amount: number;
  payment_status: string;
  status: string;
  activities: string;
  year: string;
  contractor: string;
  description: string;
  objective: string;
  impact: string;
  feedback: string;
  geo: string | null;
  status_description: string;
}

export interface IProject {
  id: number;
  constituency_id: number;
  ward_id: number;
  department_id: number;
  name: string;
  amount: number;
  start_date: string;
  end_date: string;
  payment_status: string;
  project_status: string;
  activities: string;
  year: string;
  status: string;
  contractor: string;
  coordinates: Coordinates;
  description: string;
  objective: string;
  impact: string;
  feedback: string;
  geo: string | null;
  status_description: string;
  constituency: IConstituency | null;
  ward: IWard | null;
  department: IDepartment | null;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface AdditionalFields {
  [key: string]: string;
}

export interface INewProject {
  activities: string;
  amount: number;
  constituency_id: number;
  contractor: string;
  department_id: number;
  description: string;
  feedback: string;
  geo: string;
  impact: string;
  name: string;
  payment_status: string;
  status: string;
  status_description: string;
  ward_id: number;
  year: string;
}

export interface ILatLng {
  lat: number;
  lng: number;
}

export interface TableData extends Array<any> {}

export interface INewBulkUploadProject {
  name: string;
  amount: number;
  start_date: string;
  end_date: string;
  payment_status: string;
  project_status: string;
  activities: string;
  period: string;
  status: string;
  contractor: string;
  coordinates: Coordinates;
  description: string;
  objective: string;
  impact: string;
  feedback: string;
  additional_fields: AdditionalFields;
}

export interface IBulkProjectUpload {
  projects: any[];
}

//-------------------------------------

// ------------ Comment: Documents --------------

export interface IDocApiResponse {
  code: number;
  data: IDocMetaData[];
}

export interface IDocMetaData {
  id: number;
  user_id: any;
  path?: string;
  title: string;
  publisher: string | null;
  description: string;
  tags: string | null;
  created_at: string;
  updated_at: string;
  media: IDoc[];
}

export interface IDoc {
  id: number;
  model_type: string;
  model_id: number;
  uuid: string;
  collection_name: string;
  name: string;
  file_name: string;
  mime_type: string;
  disk: string;
  conversions_disk: string;
  size: number;
  manipulations: any[];
  custom_properties: any[];
  generated_conversions: any[];
  responsive_images: any[];
  order_column: number;
  created_at: string;
  updated_at: string;
  original_url: string;
  preview_url: string;
}

export interface INewDoc {
  title: string;
  slug: string;
  description: string;
  tags: string;
  publication_year: string;
  date_posted: string;
  publisher: string;
  original_source: string;
  content_type: string;
  language: string;
  devolved_function: string;
  access_level: string;
  status: string;
  media: FileWithPath[];
}
// ----------------------------------------

// ----------- Comment: Boundary --------------

export interface IBoundaryApiResponse {
  code: number;
  data: IBoundary[];
}

export interface IBoundary {
  id: number;
  name: string;
  color: string;
  wards: IWard[];
}
//---------------Comment: CountyInfo-----------------------------
export interface ICountyInfoApiResponse {
  code: number;
  data: CountyInfo;
}

export interface CountyInfo {
  name: string;
  code: string;
  area: string;
  population: {
    year: string;
    total: string;
  }[];
  contacts: { name: string; value: string }[];
  logo?: string;
  leaders: Leader[];
  description: string;
}

export interface Population {
  year: string;
  total: string;
  // male: string;
  // female: string;
  // intersex: string;
}

export interface Contacts {
  phone: string;
  email: string;
  website: string;
  address: string;
}

export interface Leader {
  id: number;
  key: string;
  name: string;
  age: any;
  twitter: any;
  avatar: any;
}

// ------------------------------------------

//---------------Comment: RevenueStream-----------------------------

export interface IRevenueStreamApiResponse {
  code: number;
  data: IRevenueStream[];
}

export interface IRevenueStream {
  id?: number;
  name: string;
}

export interface IRevenueStreamByIdApiResponse {
  code: number;
  data: IRevenueStream;
}
// -----------------------------------------------------------------

// ---------------- Comment: Budgets -------------------------------

export interface IRevenueApiResponse {
  code: number;
  data: IRevenue[];
}

export interface IBudgetWithKeys {
  keys: string[];
  data: IBudget[];
}

export interface IBudget {
  id?: number;
  period: string;
  period_type: string;
  type: string;
  recurrent?: string;
  national?: string;
  local?: string;
  bfw?: string;
  conditional_grants?: string;
  other_revenue?: string;
  late_exchequer_issue_recurr?: string;
  late_exchequer_issue_development?: string;
}

export interface IRevenue {
  id?: number;
  revenue_id: number;
  period: string;
  amount: number;
  Revenue?: string;
  revenue: {
    id: number;
    name: string;
  };
}

// -----------------------------------------------------------------

// ------------------ Comments: Expenditure -------------------------

export interface IExpenditureApiResponse {
  code: number;
  data: IExpenditure[];
}

export interface IExpenditure {
  id?: number;
  expenditure_id: number;
  period: string;
  recurrent_allocation: number;
  development_allocation: number;
  recurrent_exchequer: number;
  development_exchequer: number;
  recurrent_expenditure: number;
  development_expenditure: number;
  Expenditure?: string;
  expenditure: {
    id: number;
    name: string;
  };
}

// -------------------------------------------------------------------

export interface IExtraSelectValues extends ISelectValues {
  name: string;
}

// ---------------Comment: Health--------------------

export interface ICoordinates {
  latitude: number;
  longitude: number;
}

export interface IHealth {
  id: number;
  name: string;
  description: string;
  coordinates: ICoordinates;
}

// -----------------------
export interface IHealthGeoApiResponse {
  code: number;
  data: IHealthGeo[];
}

export interface IHealthGeo {
  code: number;
  sector: string;
  facility: string;
  type: string;
  gps: string;
  created_at: string;
}

//-------------------------

// ---------------------
export interface IHealthDataApiResponse {
  code: number;
  data: IHealthData;
}

export interface IHealthData {
  id: number;
  ward: string;
  sector: string;
  facility: string;
  type: string;
  owner: string;
  nature: string;
  status: string;
  open_hours: string;
  cadres: string;
  population: string;
  departments: string;
  utilities: string;
  infrastructure: string;
  beds: string;
  phone: string;
  email: string;
  respondent: string;
  respondent_position: string;
  gps: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
}

export interface IHealthDataCountiesApiResponse {
  code: number;
  data: {
    data: IHealthData[];
  };
}
// -------------------------------------------

// ------------------- Indicator Data --------------

export interface IIndicatorData {
  id: number;
  data_id: number;
  period: string;
  period_type: string;
  key: string;
  value: number;
  value_type: string;
  group: any;
  unit: any;
  measurement: any;
  created_at: string;
  updated_at: string;
  constituency: string;
  ward: any;
  location_product: string;
}

export interface IIndicatorMeta {
  id: number;
  sector_id: number;
  index: string;
  name: string;
  method: string;
  description: string;
  source: string;
  created_at: string;
  updated_at: string;
  sector: Sector;
}

export interface IIndicatorDataItems {
  indicator: Indicator;
  has_data: boolean;
  metadata: IIndicatorMeta;
  data: IIndicatorData[];
}

export interface IIndicatorDataApiResponse {
  code: number;
  data: IIndicatorDataItems;
}

export interface IIndicatorTemplate {
  "Sub County": string;
  Ward: string;
  "Indicator Index": string;
  Period: string;
  "Period Type": string;
  Key: string;
  Value: number;
  "Value Type": string;
  Group: string | null;
  Unit: string | null;
  Measurement: string | null;
}

export const USERS_KEY = "/users";
export const PERMISSIONS_KEY = "/permissions";
export const PROJECTS_KEY = "/projects";
export const MUTATE_PROJECTS_KEY = "/cms/projects";
export const BOUNDARY_KEY = "/boundary";
export const SECTORS_KEY = "/sectors";
export const MUTATE_SECTOR_SUMMARY_KEY = "/cms/sector-summary";
export const MUTATE_SECTORS_KEY = "/cms/sectors";
export const INDICATORS_KEY = "/indicators";
export const INDICATOR_KEY = "/indicator";
export const DEPARTMENTS_KEY = "/departments";
export const MUTATE_DEPARTMENTS_KEY = "/cms/config/department";
export const MUTATE_INDICATORS_KEY = "/cms/indicators";
export const INDICATOR_DATA_KEY = "/cms/indicators/data";
export const NEW_INDICATOR_DATA_KEY = "/cms/trends";
export const MUTATE_INDICATOR_DATA_KEY = "/cms/indicators/data";
export const MUTATE_BULK_INDICATORS_KEY = "/cms/file/upload";
export const CONSTITUENCY_KEY = "/constituencies";
export const WARDS_KEY = "/wards";
export const DOCUMENTS_KEY = "/documents";
export const MUTATE_DOCUMENT_KEY = "/cms/documents";

export const EXPENDITURE_SOURCES_KEY = "/cms/budgets/expenditure_departments";
export const REVENUE_STREAM_KEY = "/cms/budgets/streams";
export const BUDGETS_KEY = "/cms/budgets/budgets";
export const REVENUE_KEY = "/cms/budgets/revenue";
export const EXPENDITURE_KEY = "/cms/budgets/expenditure";
export const EXPENDITURE_DATA_KEY = "/cms/budgets/expenditure_data";
export const REVENUE_DATA_KEY = "/cms/budgets/revenue_data";

export const COUNTY_INFO_KEY = "/about";
export const MUTATE_COUNTY_INFO_KEY = "/cms/about";
export const MUTATE_LEADERS_INFO_KEY = "/cms/leaders";
export const DELETE_SINGLE_MEDIA = "/cms/media";

// sabasi
export const SABASI_GEO_KEY = "/geo";
export const SABASI_DATA_KEY = "/data";

export type IChartType =
  | "area"
  | "line"
  | "bar"
  | "pie"
  | "donut"
  | "radialBar"
  | "scatter"
  | "bubble"
  | "heatmap"
  | "candlestick"
  | "boxPlot"
  | "radar"
  | "polarArea"
  | "rangeBar"
  | "rangeArea"
  | "treemap"
  | undefined;
