import { COUNTY_INFO_KEY, ICountyInfoApiResponse } from "../utils/types";
import useFetchData from "./useFetchBudgets";

const getCountyLogo = (
  fetchedCountyInfo: ICountyInfoApiResponse | undefined
): string => {
  if (fetchedCountyInfo && fetchedCountyInfo.data) {
    if (fetchedCountyInfo.data.logo && fetchedCountyInfo.data.logo.length > 0) {
      return fetchedCountyInfo.data.logo;
    }
  }
  return "/cms.jpg";
};

const useFetchCountyLogo = () => {
  const { fetchedData: fetchedCountyInfo, isLoading } =
    useFetchData<ICountyInfoApiResponse>(COUNTY_INFO_KEY, "");

  return { logo: getCountyLogo(fetchedCountyInfo), isLoading };
};

export default useFetchCountyLogo;
