import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stepper,
  Button,
  Group,
  Grid,
  useMantineTheme,
  Text,
  rem,
  Stack,
  Alert,
  Center,
  Flex,
  Tooltip,
  ActionIcon,
  SimpleGrid,
} from "@mantine/core";
import {
  MantineReactTable,
  // createRow,
  type MRT_ColumnDef,
  useMantineReactTable,
  MRT_Cell,
} from "mantine-react-table";
import {
  IconUpload,
  IconPhoto,
  IconX,
  IconAlertCircle,
  IconCheck,
  IconTrash,
} from "@tabler/icons-react";
import { Dropzone, FileWithPath, MS_EXCEL_MIME_TYPE } from "@mantine/dropzone";

import { PROJECTS } from "../utils/config";
import {
  excelDataToJson,
  formatFileSize,
  readExcelFile,
  validateRequired,
} from "../utils/fns";
import { IBulkProjectUpload, TableData } from "../utils/types";
import { MediaPreview } from "../components/MediaPreview";
import { notifications } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuthContext } from "../store/AuthContext";
import { MUTATE_PROJECTS_KEY } from "../utils/types";
import { getIcon } from "../sections/NewDocument";

const getGeo = (loc: string | null): string => {
  if (!loc) return "";

  if (loc.split(",")[0] && loc.split(",")[1]) {
    return `${loc.split(",")[0]}, ${loc.split(",")[1]}`;
  }

  return "";
};

const BulkUpload = () => {
  const theme = useMantineTheme();
  const { token } = useAuthContext();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
  const [projectsData, setProjectsData] = useState<any[]>([]);
  const [excelData, setExcelData] = useState<TableData>([]);
  const [projectsTableData, setProjectsTableData] = useState<any[]>([]);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  //keep track of rows that have been edited
  const [editedProjects, setEditedProjects] = useState<Record<string, any>>({});

  const bulkMutation = useMutation({
    mutationFn: async (newBulkUpload: IBulkProjectUpload) => {
      await axiosInstance.post(MUTATE_PROJECTS_KEY, newBulkUpload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const projectsTableColumns = useMemo<MRT_ColumnDef<any>[]>(
    () =>
      excelData.length > 0
        ? Object.keys(excelDataToJson(excelData).slice(0, 1)[0]).map((key) => ({
            accessorKey: key,
            header: key,
            id: key,
            mantineEditTextInputProps: ({ cell, row }) => ({
              type: "text",
              required: true,
              error: validationErrors?.[cell.id],
              //store edited project in state to be saved later
              onBlur: (event) => {
                const validationError = !validateRequired(
                  event.currentTarget.value
                )
                  ? "Required"
                  : undefined;
                setValidationErrors({
                  ...validationErrors,
                  [cell.id]: validationError,
                });
                setEditedProjects({
                  ...editedProjects,
                  [row.id]: row.original,
                });
                handleSaveCell(cell, event.currentTarget.value);
              },
            }),
          }))
        : [],
    [editedProjects, validationErrors, excelData]
  );

  useEffect(() => {
    if (excelData.length > 0 && active < 2) {
      const dt = excelDataToJson(excelData);
      setProjectsTableData(dt);
    }
  }, [excelData, active]);

  const projectsTable = useMantineReactTable({
    columns: projectsTableColumns,
    data: projectsTableData, // transformToTableResponse(columnMappingTable, projectsData)
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "table", // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    getRowId: (row, idx) => idx.toString(),
    initialState: {
      density: "xs", //set default density to compact
    },
    // mantineToolbarAlertBannerProps: isLoadingProjectsError
    //   ? {
    //       color: 'red',
    //       children: 'Error loading data',
    //     }
    //   : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row }) => (
      <Tooltip label="Delete">
        <ActionIcon color="red" onClick={() => console.log(row)}>
          <IconTrash />
        </ActionIcon>
      </Tooltip>
    ),
    // renderBottomToolbarCustomActions: () => (
    //   <Button
    //     color="blue"
    //     onClick={handleSaveProjects}
    //     disabled={
    //       Object.keys(editedProjects).length === 0 ||
    //       Object.values(validationErrors).some((error) => !!error)
    //     }
    //     //loading={isUpdatingProjects}
    //   >
    //     Save
    //   </Button>
    // ),
    // state: {
    //   isLoading: isLoadingProjects,
    //   //isSaving: isCreatingUser || isUpdating || isDeletingUser,
    //   showAlertBanner: isLoadingProjectsError,
    //   showProgressBars: isFetchingProjects,
    // },
  });

  const handleSaveCell = (cell: MRT_Cell<any>, value: string) => {
    //@ts-ignore
    setProjectsTableData((prevItems) => {
      // Create a new array with the updated item
      const updatedItems = [...prevItems];
      updatedItems[+cell.row.index] = {
        ...updatedItems[+cell.row.index],
        [cell.column.id]: value,
      };
      return updatedItems;
    });
  };

  const handleFileRemove = (path: string) =>
    setUploadedFiles(uploadedFiles.filter((file) => file.path !== path));

  console.log("render . . .");

  return (
    <>
      <Stepper
        active={active}
        onStepClick={setActive}
        breakpoint="sm"
        size="sm"
        my="xl"
      >
        <Stepper.Step
          label="Import CSV"
          description="Load data from CSV"
          allowStepSelect={false}
        >
          <Grid>
            <Grid.Col sm={12} md={12} lg={12}>
              <Dropzone
                onDrop={async (files) => {
                  setUploadedFiles(files);
                  try {
                    const data = await readExcelFile(files[0]);
                    const jsonData = excelDataToJson(data);
                    setExcelData(data);
                    setProjectsData(jsonData);
                  } catch (error) {}
                  //handleFile(files[0], setExcelData, setProjectsData);
                }}
                onReject={(files) => {
                  for (let i = 0; i < files[0].errors.length; i++) {
                    notifications.show({
                      title: "Error",
                      message: files[0].errors[i].message,
                      icon: <IconX />,
                      color: "red",
                    });
                  }
                }}
                maxSize={20 * 1024 ** 2}
                accept={[...MS_EXCEL_MIME_TYPE, "text/csv"]}
                maxFiles={1}
                my="lg"
              >
                <Group
                  position="center"
                  spacing="xl"
                  style={{ minHeight: rem(220), pointerEvents: "none" }}
                >
                  <Dropzone.Accept>
                    <IconUpload
                      size="3.2rem"
                      stroke={1.5}
                      color={
                        theme.colors[theme.primaryColor][
                          theme.colorScheme === "dark" ? 4 : 6
                        ]
                      }
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX
                      size="3.2rem"
                      stroke={1.5}
                      color={
                        theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                      }
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <IconPhoto size="3.2rem" stroke={1.5} />
                  </Dropzone.Idle>

                  <div>
                    <Text size="md" inline>
                      Drag project files here or click to select files
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}>
                      {`${
                        uploadedFiles.length > 0
                          ? `${uploadedFiles.length} file uploaded`
                          : "Each file should not exceed 20mb"
                      }`}
                    </Text>
                  </div>
                </Group>
              </Dropzone>
              <SimpleGrid
                cols={1}
                breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                mt={uploadedFiles.length > 0 ? "xl" : 0}
              >
                {uploadedFiles.map((file, index) => {
                  return (
                    <MediaPreview
                      key={index}
                      icon={getIcon(file.type)}
                      name={file.name}
                      size={formatFileSize(file.size)}
                      path={file.path ? file.path : ""}
                      handleRemove={handleFileRemove}
                      isUrl={false}
                    />
                  );
                })}
              </SimpleGrid>
            </Grid.Col>
          </Grid>
        </Stepper.Step>
        <Stepper.Step
          label="Preview Projects"
          description="View uploaded projects"
          allowStepSelect={false}
        >
          <Stack spacing="xl">
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title=""
              variant="light"
            >
              Click on a field item to edit
            </Alert>
            {/* <UpdateProjectsTable
              projectsData={projectsData}
              isLoadingProjectsError={isLoadingProjectsError}
              isFetchingProjects={isFetchingProjects}
              isLoadingProjects={isLoadingProjects}
              rawExcelData={excelData}
              toCompareColumns={columnMappingTable}
            /> */}
            <MantineReactTable table={projectsTable} />
          </Stack>
        </Stepper.Step>
        <Stepper.Step
          label="Import Projects"
          description="Create projects from uploaded data"
          allowStepSelect={false}
        >
          <Center mt="xl">
            <Flex
              gap="lg"
              justify="center"
              align="center"
              direction="column"
              wrap="wrap"
            >
              <Group mt="xl" spacing="sm">
                <IconAlertCircle size="2rem" />
                <Text fz="xl">
                  <span
                    style={{ fontWeight: "bolder" }}
                  >{`${projectsData.length}`}</span>{" "}
                  projects will be uploaded
                </Text>
              </Group>
              <Text fz="sm">
                The total number of projects found in the CSV file.
              </Text>
            </Flex>
          </Center>
        </Stepper.Step>
        <Stepper.Completed>
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title=""
            color="violet"
            variant="light"
          >
            All projects were successfully uploaded
          </Alert>
        </Stepper.Completed>
      </Stepper>
      {active === 3 ? (
        <Group position="center" mt="xl">
          <Button
            leftIcon={<IconCheck />}
            onClick={() => navigate(PROJECTS.view.link)}
          >
            Finish
          </Button>
        </Group>
      ) : (
        <Group position="right" mt="xl">
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
          <Button
            disabled={active === 0 && uploadedFiles.length === 0}
            onClick={async () => {
              if (active === 2) {
                try {
                  await bulkMutation.mutate(
                    {
                      projects: projectsTableData.map((pData) => ({
                        activities: pData.Activities ? pData.Activities : "",
                        amount: pData.Amount ? Number(pData.Amount) : 0,
                        constituency_name: pData.Constituency
                          ? pData.Constituency
                          : "",
                        contractor: pData.Contractor ? pData.Contractor : "",
                        department_name: pData.Department
                          ? pData.Department
                          : "",
                        feedback: pData.Feedback ? pData.Feedback : "",
                        geo: getGeo(pData.Geo),
                        impact: pData.Impact ? pData.Impact : "",
                        name: pData.Name ? pData.Name : "",
                        payment_status: pData["Payment Status"]
                          ? pData["Payment Status"]
                          : "",
                        status: pData.Status ? pData.Status : "",
                        status_description: pData["Status Description"]
                          ? pData["Status Description"]
                          : "",
                        ward_name: pData.Ward ? pData.Ward : "",
                        year: pData.Year ? pData.Year : "",
                      })),
                    },
                    {
                      onSuccess: () => {
                        notifications.show({
                          title: "Success",
                          message: "All projects have been uploaded",
                          icon: <IconCheck />,
                          color: "teal",
                        });
                        nextStep();
                      },
                      onError: (
                        error: any,
                        variables: IBulkProjectUpload,
                        context: unknown
                      ) => {
                        notifications.show({
                          title: "Error",
                          message:
                            error && error.data
                              ? error.data
                              : "An error occured, try again",
                          icon: <IconX />,
                          color: "red",
                        });
                      },
                    }
                  );
                } catch (error: any) {
                  notifications.show({
                    title: "Error",
                    message:
                      error && error.data
                        ? error.data
                        : "An error occured, try again",
                    icon: <IconX />,
                    color: "red",
                  });
                }
              } else {
                nextStep();
              }
            }}
          >
            Next step
          </Button>
        </Group>
      )}
    </>
  );
};

export default BulkUpload;
