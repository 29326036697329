import { useForm } from "@mantine/form";
import {
  TextInput,
  Grid,
  ColorInput,
  Button,
  Group,
  Drawer,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";

import {
  DEPARTMENTS_KEY,
  INewDepartment,
  MUTATE_DEPARTMENTS_KEY,
} from "../utils/types";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuthContext } from "../store/AuthContext";
import { INewDataProps } from "./NewSector";

const NewDepartment = ({ closeDrawer, drawerOpened }: INewDataProps) => {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newDepartment: INewDepartment) => {
      await axiosInstance.post(MUTATE_DEPARTMENTS_KEY, newDepartment, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const form = useForm({
    initialValues: {
      name: "",
      color: "",
    },
    validate: {
      name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      color: (value) =>
        value.length < 4 ? "Choose a color for this department" : null,
    },
  });

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="xl"
      onClose={closeDrawer}
      title="Add Department"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            await mutation.mutate(values, {
              onSuccess: () => {
                notifications.show({
                  title: "Success",
                  message: "Department Added",
                  icon: <IconCheck />,
                  color: "teal",
                });
                form.reset();
              },
              onSettled(data, error, variables, context) {
                queryClient.invalidateQueries([DEPARTMENTS_KEY]);
              },
              onError: (
                error: any,
                variables: INewDepartment,
                context: unknown
              ) => {
                notifications.show({
                  title: "Error",
                  message:
                    error && error.data
                      ? error.data
                      : "An error occured, try again",
                  icon: <IconX />,
                  color: "red",
                });
              },
            });
          } catch (error) {}
        })}
      >
        <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <ColorInput
              format="hex"
              withAsterisk
              label="Color"
              swatches={[
                "#25262b",
                "#868e96",
                "#fa5252",
                "#e64980",
                "#be4bdb",
                "#7950f2",
                "#4c6ef5",
                "#228be6",
                "#15aabf",
                "#12b886",
                "#40c057",
                "#82c91e",
                "#fab005",
                "#fd7e14",
              ]}
              {...form.getInputProps("color")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group position="right" spacing="xl">
              <Button
                leftIcon={<IconPlus size="1rem" />}
                type="submit"
                variant="filled"
                loading={mutation.isLoading}
              >
                Submit
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Drawer>
  );
};

export default NewDepartment;
