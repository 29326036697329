import { useQuery } from "@tanstack/react-query";
import { fetchSabasiData } from "../utils/fns";

const useFetchSabasiData = <T>(url: string, token: string) => {
  const {
    isFetching,
    isLoading,
    data: fetchedData,
    isError,
  } = useQuery([url], {
    queryFn: async () => fetchSabasiData<T>(`${url}`, token),
    //enabled: false,
  });

  return { isFetching, isLoading, fetchedData, isError };
};

export default useFetchSabasiData;
