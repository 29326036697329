import { Anchor, Breadcrumbs } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";

export const BreadCrumb = ({
  pathname,
  path,
}: {
  pathname: string;
  path: string;
}) => {
  const items = [
    { title: "Home", href: "/" },
    { title: pathname, href: path },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      sx={{
        textTransform: "capitalize",
      }}
    >
      {item.title}
    </Anchor>
  ));

  return (
    <Breadcrumbs separator={<IconChevronRight size={18} />}>
      {items}
    </Breadcrumbs>
  );
};
