import { useQuery } from "@tanstack/react-query";
import { fetchApiData } from "../utils/fns";
import { IIndicatorsApiResponse } from "../utils/types";

const useFetchCrudIndicators = (url: string,
    token: string ) => {
  const {
    isFetching,
    isLoading,
    data: fetchedIndicators,
    isError,
  } = useQuery([url], {
    queryFn: async () =>
      fetchApiData<IIndicatorsApiResponse>(
        `${url}`,
        token
      ),
      //enabled: token.length > 0
  })

  return { isFetching, isLoading, fetchedIndicators, isError };
};

export default useFetchCrudIndicators;
