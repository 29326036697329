import { Alert, Center, Loader } from "@mantine/core";
import { IconAlertCircle, IconBuildingHospital } from "@tabler/icons-react";
import { useAuthContext } from "../store/AuthContext";
import useFetchSabasiData from "../hooks/useFetchSabasiData";
import { IHealthDataApiResponse, SABASI_DATA_KEY } from "../utils/types";
import { getHealthData } from "../utils/fns";
import { PRIMARY_COLOR } from "../utils/config";
import { resolveHours } from "./HealthDetails";

interface IMapPopUp {
  healthCode: number;
  facility: string;
}

const MapPopUp = ({ healthCode, facility }: IMapPopUp) => {
  const { token } = useAuthContext();
  const {
    fetchedData: fetchedHealthData,
    isError: isHealthDataError,
    // isFetching: isFetchingDepartments,
    isLoading: isLoadingHealthData,
  } = useFetchSabasiData<IHealthDataApiResponse>(
    `${SABASI_DATA_KEY}/${healthCode}`,
    token ? token : ""
  );

  if (isHealthDataError) {
    return (
      <Alert icon={<IconAlertCircle size="1rem" />} title="Error!" color="red">
        Error fetching facility data
      </Alert>
    );
  }

  return isLoadingHealthData ? (
    <Center>
      <Loader size="xs" variant="dots" color={PRIMARY_COLOR} />
    </Center>
  ) : (
    <div className="flex flex-col p-2 bg-white">
      <div className="flex flex-row space-x-4 -mt-2">
        <div className="my-4">
          <IconBuildingHospital />;
        </div>
        <div>
          <h3 className="text-base">{facility}</h3>
        </div>
      </div>
      <div className="-mt-3 mb-2">
        <hr className="h-px bg-gray-300 border-0 "></hr>
      </div>
      <div className="flex flex-row justify-between">
        <div className="mr-8 text-sm">
          <p>{`Bed Capacity: ${getHealthData(fetchedHealthData).beds}`}</p>
          <p>{`Infrastructure Quality: ${
            getHealthData(fetchedHealthData).infrastructure
          }`}</p>
          <p className="text-blue-800">Click pin for more details </p>
        </div>
        {resolveHours(getHealthData(fetchedHealthData).open_hours)}
      </div>
    </div>
  );
};

export default MapPopUp;
