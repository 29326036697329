import { useForm } from "@mantine/form";
import {
  Grid,
  Button,
  Group,
  Drawer,
  Title,
  TextInput,
  FileInput,
  rem,
  Image,
} from "@mantine/core";

import { IconPlus, IconUpload } from "@tabler/icons-react";
import { INewDataProps } from "./NewSector";
import { useEffect, useState } from "react";
import {
  COUNTY_INFO_KEY,
  CountyInfo,
  MUTATE_COUNTY_INFO_KEY,
} from "../utils/types";
import RichtextEditor from "../components/RichTextEditor";
import axiosInstance from "../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "../store/AuthContext";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";

const getContact = (
  contactInfo: { name: string; value: string }[],
  toCheck: "phone" | "email" | "website" | "address"
): string => {
  const val = contactInfo.filter((cInfo) => cInfo.name === toCheck);
  if (val.length > 0) {
    return val[0].value;
  }
  return "";
};

export interface IUpdateAboutUsProps extends INewDataProps {
  foundAboutUs?: CountyInfo;
}

const ViewAboutUs = ({
  closeDrawer,
  drawerOpened,
  foundAboutUs,
}: IUpdateAboutUsProps) => {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const [about, setAbout] = useState<string>("");
  const [countyIcon, setCountyIcon] = useState<File | null>(null);

  const mutateCountyInfo = useMutation({
    mutationFn: async (newAboutUs: FormData) => {
      await axiosInstance.post(MUTATE_COUNTY_INFO_KEY, newAboutUs, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const form = useForm({
    initialValues: {
      county_name: "",
      county_code: "",
      county_area: "",
      county_population: "",
      website: "",
      phone: "",
      email: "",
      postal_address: "",
    },
    validate: {
      county_name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      county_code: (value) =>
        value.length === 0 ? "Provide the county code" : null,
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "provide a valid email",
      website: (value) =>
        /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/.test(value)
          ? null
          : "provide a valid website url",
    },
  });

  useEffect(() => {
    if (foundAboutUs) {
      if (foundAboutUs.description) {
        setAbout(foundAboutUs.description);
        form.setValues({
          county_name: foundAboutUs.name,
          county_code: foundAboutUs.code,
          county_area: foundAboutUs.area,
          county_population:
            foundAboutUs.population && foundAboutUs.population.length > 0
              ? foundAboutUs.population[0].total
              : "",
        });
      }

      if (foundAboutUs.contacts && foundAboutUs.contacts.length > 0) {
        form.setValues({
          phone: getContact(foundAboutUs.contacts, "phone"),
          email: getContact(foundAboutUs.contacts, "email"),
          website: getContact(foundAboutUs.contacts, "website"),
          postal_address: getContact(foundAboutUs.contacts, "address"),
        });
      }
    }
  }, [foundAboutUs]);

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="xl"
      onClose={closeDrawer}
      title="County Information"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          const {
            county_area,
            county_code,
            county_name,
            county_population,
            email,
            phone,
            postal_address,
            website,
          } = values;
          const formData = new FormData();
          formData.append("name", county_name);
          formData.append("code", county_code);
          formData.append("area", county_area);
          const population = [
            {
              year: new Date().getFullYear(),
              total: county_population,
            },
          ];
          for (let i = 0; i < population.length; i++) {
            formData.append(
              `population[${i}][year]`,
              population[i].year.toString()
            );
            formData.append(`population[${i}][total]`, population[i].total);
          }
          const contacts = [
            {
              name: "phone",
              value: phone,
            },
            {
              name: "email",
              value: email,
            },
            {
              name: "website",
              value: website,
            },
            {
              name: "address",
              value: postal_address,
            },
          ];
          for (let i = 0; i < contacts.length; i++) {
            formData.append(`contacts[${i}][name]`, contacts[i].name);
            formData.append(`contacts[${i}][value]`, contacts[i].value);
          }
          formData.append("description", about);
          if (countyIcon) {
            formData.append("logo", countyIcon);
          }
          try {
            await mutateCountyInfo.mutate(formData, {
              onSuccess() {
                notifications.show({
                  title: "Success",
                  message: "County Information Updated",
                  icon: <IconCheck />,
                  color: "teal",
                });
              },
              onError(error: any) {
                notifications.show({
                  title: "Error",
                  message:
                    error && error.data
                      ? error.data
                      : "An error occured, try again",
                  icon: <IconX />,
                  color: "red",
                });
              },
              onSettled() {
                queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                closeDrawer();
              },
            });
          } catch (error) {}
        })}
      >
        <Grid gutterXs="md" gutterMd="md">
          <Grid.Col sm={12} md={6}>
            <TextInput
              withAsterisk
              label="County Name"
              placeholder="County Name"
              {...form.getInputProps("county_name")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <FileInput
              label="County Logo"
              placeholder="County Logo"
              icon={<IconUpload size={rem(14)} />}
              value={countyIcon}
              accept="image/png,image/jpeg"
              onChange={setCountyIcon}
            />
          </Grid.Col>
          {countyIcon && (
            <Grid.Col span={12}>
              <Image
                src={countyIcon && URL.createObjectURL(countyIcon)}
                maw={160}
                mx="auto"
                imageProps={{
                  onLoad: () =>
                    URL.revokeObjectURL(
                      countyIcon ? URL.createObjectURL(countyIcon) : ""
                    ),
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col sm={12} md={4}>
            <TextInput
              withAsterisk
              label="County Code"
              placeholder="County Code"
              {...form.getInputProps("county_code")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={4}>
            <TextInput
              withAsterisk
              label="County Area in SQ(km)"
              placeholder="County Area in SQ(km)"
              {...form.getInputProps("county_area")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={4}>
            <TextInput
              withAsterisk
              label="County Population"
              placeholder="County Population"
              {...form.getInputProps("county_population")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <RichtextEditor
              setText={setAbout}
              placeholder="About Us"
              initialContent={about}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Title order={3}>County Contacts</Title>
          </Grid.Col>

          <Grid.Col sm={12} md={6}>
            <TextInput
              withAsterisk
              label="Phone"
              placeholder="Phone"
              {...form.getInputProps("phone")}
            />
          </Grid.Col>

          <Grid.Col sm={12} md={6}>
            <TextInput
              withAsterisk
              label="Email"
              placeholder="Email"
              {...form.getInputProps("email")}
            />
          </Grid.Col>

          <Grid.Col sm={12} md={6}>
            <TextInput
              withAsterisk
              label="Website"
              placeholder="Website"
              {...form.getInputProps("website")}
            />
          </Grid.Col>

          <Grid.Col sm={12} md={6}>
            <TextInput
              withAsterisk
              label="Postal Address"
              placeholder="Postal Address"
              {...form.getInputProps("postal_address")}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Group position="right" spacing="xl">
              <Button
                leftIcon={<IconPlus size="1rem" />}
                type="submit"
                variant="filled"
                loading={mutateCountyInfo.isLoading}
              >
                Submit
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Drawer>
  );
};

export default ViewAboutUs;
