import { Grid, Tabs } from "@mantine/core";
import {
  IconCash,
  IconDelta,
  IconInputCheck,
  IconInputSearch,
} from "@tabler/icons-react";
import RevenueStreams from "../sections/RevenueStreamsTable";
import ExpenditureStreams from "../sections/ExpenditureStreamsTable";
import BudgetsTable from "../sections/BudgetsTable";
import useFetchData from "../hooks/useFetchBudgets";
import {
  EXPENDITURE_DATA_KEY,
  EXPENDITURE_KEY,
  IExpenditure,
  IExpenditureApiResponse,
  IRevenue,
  IRevenueApiResponse,
  IRevenueStream,
  IRevenueStreamApiResponse,
  REVENUE_DATA_KEY,
  REVENUE_KEY,
} from "../utils/types";
import { useAuthContext } from "../store/AuthContext";
import {
  getExpenditureData,
  getRevenue,
  getRevenueData,
  resolveName,
} from "../utils/fns";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import ExpenditureTable from "../sections/ExpenditureTable";

const Budgets = () => {
  const { token } = useAuthContext();

  const {
    fetchedData: fetchedRevenueData,
    isError: isLoadingRevenuesError,
    isFetching: isFetchingRevenues,
    isLoading: isLoadingRevenues,
  } = useFetchData<IRevenueApiResponse>(REVENUE_DATA_KEY, token ? token : "");

  const {
    fetchedData: fetchedExpenditure,
    isError: isLoadingExpenditureError,
    isLoading: isLoadingExpenditure,
  } = useFetchData<IRevenueStreamApiResponse>(
    EXPENDITURE_KEY,
    token ? token : ""
  );

  const {
    fetchedData: fetchedRevenue,
    isError: isLoadingRevenueError,
    isLoading: isLoadingRevenue,
  } = useFetchData<IRevenueStreamApiResponse>(REVENUE_KEY, token ? token : "");

  const {
    fetchedData: fetchedExpenditureData,
    isError: isLoadingExpenditureDataError,
    isFetching: isFetchingExpenditureData,
    isLoading: isLoadingExpenditureData,
  } = useFetchData<IExpenditureApiResponse>(
    EXPENDITURE_DATA_KEY,
    token ? token : ""
  );

  const mutation = useMutation({
    mutationFn: async (newRevenue: IRevenue) => {
      await axiosInstance.post(
        REVENUE_DATA_KEY,
        {
          ...newRevenue,
          revenue_id: resolveName(
            getRevenue(fetchedRevenue).map((d) => ({
              value: d.id!.toString(),
              label: d.name,
            })),
            newRevenue.Revenue ? newRevenue.Revenue : ""
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  const mutateExpenditureData = useMutation({
    mutationFn: async (newExpenditureData: IExpenditure) => {
      await axiosInstance.post(
        EXPENDITURE_DATA_KEY,
        {
          ...newExpenditureData,
          expenditure_id: resolveName(
            getRevenue(fetchedExpenditure).map((d) => ({
              value: d.id!.toString(),
              label: d.name,
            })),
            newExpenditureData.Expenditure ? newExpenditureData.Expenditure : ""
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  const mutateExpenditure = useMutation({
    mutationFn: async (newExpenditure: IRevenueStream) => {
      await axiosInstance.post(EXPENDITURE_KEY, newExpenditure, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const mutateRevenue = useMutation({
    mutationFn: async (newRevenue: IRevenueStream) => {
      await axiosInstance.post(REVENUE_KEY, newRevenue, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  return (
    <Grid>
      <Grid.Col span={12}>
        <Tabs defaultValue="revenue">
          <Tabs.List>
            <Tabs.Tab value="revenue" icon={<IconCash size="0.8rem" />}>
              Revenue
            </Tabs.Tab>
            <Tabs.Tab
              value="revenue_data"
              icon={<IconInputSearch size="0.8rem" />}
            >
              Revenue Data
            </Tabs.Tab>

            <Tabs.Tab
              value="expenditure"
              icon={<IconInputCheck size="0.8rem" />}
            >
              Expenditure
            </Tabs.Tab>
            <Tabs.Tab
              value="expenditure_data"
              icon={<IconDelta size="0.8rem" />}
            >
              Expenditure Data
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="revenue" pt="xs">
            <RevenueStreams
              revenueData={getRevenue(fetchedRevenue)}
              isLoadingRevenues={isLoadingRevenue}
              isLoadingRevenuesError={isLoadingRevenueError}
              mutateRevenue={mutateRevenue}
            />
          </Tabs.Panel>

          <Tabs.Panel value="revenue_data" pt="xs">
            <BudgetsTable
              revenueData={getRevenueData(fetchedRevenueData)}
              isFetchingRevenues={isFetchingRevenues}
              isLoadingRevenues={isLoadingRevenues}
              isLoadingRevenuesError={isLoadingRevenuesError}
              mutateRevenue={mutation}
              category={getRevenue(fetchedRevenue).map((d) => ({
                value: d.name,
                label: d.name,
              }))}
            />
          </Tabs.Panel>
          <Tabs.Panel value="expenditure" pt="xs">
            <ExpenditureStreams
              expenditureData={getRevenue(fetchedExpenditure)}
              isLoadingExpenditures={isLoadingExpenditure}
              isLoadingExpendituresError={isLoadingExpenditureError}
              mutateExpenditure={mutateExpenditure}
            />
          </Tabs.Panel>
          <Tabs.Panel value="expenditure_data" pt="xs">
            <ExpenditureTable
              expenditureData={getExpenditureData(fetchedExpenditureData)}
              isFetchingExpenditure={isFetchingExpenditureData}
              isLoadingExpenditure={isLoadingExpenditureData}
              isLoadingExpenditureError={isLoadingExpenditureDataError}
              mutateExpenditureData={mutateExpenditureData}
            />
          </Tabs.Panel>
        </Tabs>
      </Grid.Col>
    </Grid>
  );
};

export default Budgets;
