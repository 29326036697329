import { useMemo, useState } from "react";
import {
  MantineReactTable,
  // createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import { ActionIcon, Button, Flex, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  EXPENDITURE_DATA_KEY,
  EXPENDITURE_KEY,
  IExpenditure,
  IRevenueStreamApiResponse,
} from "../utils/types";
import {
  generateYearRanges,
  getRevenue,
  resolveName,
  validateRequired,
} from "../utils/fns";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { useAuthContext } from "../store/AuthContext";
import axiosInstance from "../utils/axios";
import useFetchData from "../hooks/useFetchBudgets";

interface IExpenditureData {
  expenditureData: IExpenditure[];
  isLoadingExpenditureError: boolean;
  isLoadingExpenditure: boolean;
  isFetchingExpenditure: boolean;
  mutateExpenditureData: UseMutationResult<
    void,
    unknown,
    IExpenditure,
    unknown
  >;
}

const ExpenditureTable = ({
  expenditureData,
  isLoadingExpenditureError,
  isLoadingExpenditure,
  isFetchingExpenditure,
  mutateExpenditureData,
}: IExpenditureData) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const queryClient = useQueryClient();
  const { token } = useAuthContext();

  const mutateUpdateExpenditure = useMutation({
    mutationFn: async (updatedExpenditure: IExpenditure) => {
      await axiosInstance.put(
        `${EXPENDITURE_DATA_KEY}/${updatedExpenditure.id}`,
        {
          ...updatedExpenditure,
          expenditure_id: resolveName(
            getRevenue(fetchedExpenditure).map((d) => ({
              value: d.id!.toString(),
              label: d.name,
            })),
            updatedExpenditure.Expenditure ? updatedExpenditure.Expenditure : ""
          ),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  const mutateDeleteExpenditure = useMutation({
    mutationFn: async (id: number) => {
      await axiosInstance.delete(`${EXPENDITURE_DATA_KEY}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const {
    fetchedData: fetchedExpenditure,
    isLoading: isLoadingExpenditureItems,
  } = useFetchData<IRevenueStreamApiResponse>(
    EXPENDITURE_KEY,
    token ? token : ""
  );

  const columns = useMemo<MRT_ColumnDef<IExpenditure>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
      {
        accessorFn(originalRow) {
          return originalRow.expenditure ? originalRow.expenditure.name : "-";
        },
        header: "Expenditure",
        editVariant: "select",
        mantineEditSelectProps: {
          required: true,
          data: getRevenue(fetchedExpenditure).map((d) => ({
            value: d.name,
            label: d.name,
          })),
          disabled: isLoadingExpenditureItems,
          error: validationErrors?.expenditure,
        },
      },
      {
        accessorKey: "period",
        header: "Period",
        editVariant: "select",
        mantineEditSelectProps: {
          required: true,
          data: generateYearRanges(2013).map((year) => ({
            value: year,
            label: year,
          })),
          error: validationErrors?.period,
        },
      },
      {
        accessorKey: "recurrent_allocation",
        header: "Recurrent Allocation",
        mantineEditTextInputProps: {
          type: "number",
          required: true,
          error: validationErrors?.recurrentAllocation,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              recurrentAllocation: undefined,
            }),
        },
      },
      {
        accessorKey: "development_allocation",
        header: "Development Allocation",
        editVariant: "text",
        mantineEditTextInputProps: {
          type: "number",
          required: true,
          error: validationErrors?.developmentAllocation,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              developmentAllocation: undefined,
            }),
        },
      },
      {
        accessorKey: "recurrent_exchequer",
        header: "Recurrent Exchequer",
        editVariant: "text",
        mantineEditTextInputProps: {
          type: "number",
          required: true,
          error: validationErrors?.recurrentExchequer,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              recurrentExchequer: undefined,
            }),
        },
      },
      {
        accessorKey: "development_exchequer",
        header: "Development Exchequer",
        editVariant: "text",
        mantineEditTextInputProps: {
          type: "number",
          required: true,
          error: validationErrors?.developmentExchequer,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              developmentExchequer: undefined,
            }),
        },
      },
      {
        accessorKey: "recurrent_expenditure",
        header: "Recurrent Expenditure",
        editVariant: "text",
        mantineEditTextInputProps: {
          type: "number",
          required: true,
          error: validationErrors?.recurrentExpenditure,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              recurrentExpenditure: undefined,
            }),
        },
      },
      {
        accessorKey: "development_expenditure",
        header: "Development Expenditure",
        editVariant: "text",
        mantineEditTextInputProps: {
          type: "number",
          required: true,
          error: validationErrors?.developmentExpenditure,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              developmentExpenditure: undefined,
            }),
        },
      },
    ],
    [validationErrors]
  );

  //CREATE action
  const handleCreateExpenditure: MRT_TableOptions<IExpenditure>["onCreatingRowSave"] =
    async ({ values, exitCreatingMode }) => {
      const newValidationErrors = validateExpenditure(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      mutateExpenditureData.mutate(values, {
        onSettled(data, error, variables, context) {
          queryClient.invalidateQueries([EXPENDITURE_DATA_KEY]);
        },
        onSuccess(data, variables, context) {
          notifications.show({
            title: "Success",
            message: "Item Added",
            icon: <IconCheck />,
            color: "teal",
          });
        },
        onError(error: any, variables, context) {
          notifications.show({
            title: "Error",
            message:
              error && error.data ? error.data : "An error occured, try again",
            icon: <IconX />,
            color: "red",
          });
        },
      });
      exitCreatingMode();
    };

  //UPDATE action
  const handleSaveExpenditure: MRT_TableOptions<IExpenditure>["onEditingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateExpenditure(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      mutateUpdateExpenditure.mutate(values, {
        onSettled(data, error, variables, context) {
          queryClient.invalidateQueries([EXPENDITURE_DATA_KEY]);
        },
        onSuccess(data, variables, context) {
          notifications.show({
            title: "Success",
            message: "Item Updated",
            icon: <IconCheck />,
            color: "teal",
          });
        },
        onError(error: any, variables, context) {
          notifications.show({
            title: "Error",
            message:
              error && error.data ? error.data : "An error occured, try again",
            icon: <IconX />,
            color: "red",
          });
        },
      });
      table.setEditingRow(null); //exit editing mode
    };

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<IExpenditure>) =>
    modals.openConfirmModal({
      title: "Confirm Delete",
      children: (
        <Text>
          Are you sure you want to delete{" "}
          <span style={{ fontWeight: "bold" }}>expenditure data</span> for
          period {row.original.period}? This action cannot be undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () =>
        mutateDeleteExpenditure.mutate(row.original.id!, {
          onSettled(data, error, variables, context) {
            queryClient.invalidateQueries([EXPENDITURE_DATA_KEY]);
          },
          onSuccess(data, variables, context) {
            notifications.show({
              title: "Success",
              message: "Item Deleted",
              icon: <IconCheck />,
              color: "teal",
            });
          },
          onError(error: any, variables, context) {
            notifications.show({
              title: "Error",
              message:
                error && error.data
                  ? error.data
                  : "An error occured, try again",
              icon: <IconX />,
              color: "red",
            });
          },
        }),
    });

  const table = useMantineReactTable({
    columns,
    data: expenditureData,
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row, idx) => idx.toString(),
    mantineToolbarAlertBannerProps: isLoadingExpenditureError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    initialState: {
      density: "xs", //set default density to compact
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateExpenditure,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveExpenditure,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => table.setEditingRow(row)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Create New
      </Button>
    ),
    state: {
      isLoading: isLoadingExpenditure,
      isSaving: mutateExpenditureData.isLoading,
      showAlertBanner: isLoadingExpenditureError,
      showProgressBars: false,
    },
  });

  return <MantineReactTable table={table} />;
};
export default ExpenditureTable;

function validateExpenditure(expenditure: IExpenditure) {
  return {
    expenditure: !validateRequired(
      expenditure.Expenditure ? expenditure.Expenditure : ""
    )
      ? "Expenditure is Required"
      : "",
    period: !validateRequired(expenditure.period) ? "Period is Required" : "",
  };
}
