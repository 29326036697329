import { Text } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_SortingState,
  MRT_Virtualizer,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { IProject } from "../utils/types";
import { useEffect, useMemo, useRef, useState } from "react";
import { fNumber } from "../utils/formatNumber";

const DashboardProjectsTable = ({
  projects,
  isLoadingProjects,
  isLoadingProjectsError,
}: {
  projects: IProject[];
  isLoadingProjectsError: boolean;
  isLoadingProjects: boolean;
}) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  useEffect(() => {
    try {
      //scroll to the top of the table when the sorting changes
      rowVirtualizerInstanceRef.current?.scrollToIndex(0);
    } catch (e) {
      console.log(e);
    }
  }, [sorting]);

  const columns = useMemo<MRT_ColumnDef<IProject>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "constituency_id",
        enableEditing: false,
        header: "Constituency",
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.constituency ? row.original.constituency.name : "-"}
            </Text>
          );
        },
      },
      {
        accessorKey: "status",
        enableEditing: false,
        header: "Status",
        Cell: ({ row }) => {
          return <Text>{row.original.status ? row.original.status : "-"}</Text>;
        },
      },
      {
        accessorKey: "amount",
        enableEditing: false,
        header: "Amount",
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.amount ? fNumber(row.original.amount) : "-"}
            </Text>
          );
        },
      },
      {
        accessorKey: "year",
        enableEditing: false,
        header: "Period",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: projects,
    enableEditing: false,
    enableRowActions: false,
    positionActionsColumn: "last",
    enablePagination: false,
    enableSorting: false,
    getRowId: (row) => (row.id ? row.id.toString() : ""),
    initialState: {
      density: "xs", //set default density to compact
    },
    mantineToolbarAlertBannerProps: isLoadingProjectsError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        maxHeight: "300px",
      },
    },
    state: {
      isLoading: isLoadingProjects,
      isSaving: false,
      showAlertBanner: isLoadingProjectsError,
      showProgressBars: false,
    },
    enableBottomToolbar: false,
    enableColumnResizing: true,
    enableColumnVirtualization: true,
    enableGlobalFilterModes: true,
    enablePinning: true,
    enableRowNumbers: true,
    enableRowVirtualization: true,
    onSortingChange: setSorting,
    rowVirtualizerInstanceRef, //optional
    rowVirtualizerProps: { overscan: 5 }, //optionally customize the row virtualizer
    columnVirtualizerProps: { overscan: 2 }, //optionally customize the column virtualizer
  });

  return <MantineReactTable table={table} />;
};

export default DashboardProjectsTable;
