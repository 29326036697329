import { useDisclosure } from "@mantine/hooks";
import { ConfigLayout } from "../sections/Config";
import NewSector from "../sections/NewSector";

const Sectors = () => {
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  return (
    <>
      <ConfigLayout openNewSectorDrawer={openDrawer} />
      <NewSector closeDrawer={closeDrawer} drawerOpened={drawerOpened} />
    </>
  );
};

export default Sectors;
