import {
  createContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useContext,
} from "react";
import { IUser } from "../utils/types";

interface IAuthContext {
  isLoggedIn: boolean;
  token: string | undefined;
  user: IUser | undefined;
  login: (user: IUser, token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface IAuthContextProvider {
  children: ReactNode;
}

const USER_KEY = "CMS_USER";
const TOKEN_KEY = "CMS_TOKEN";

export const AuthContextProvider = ({ children }: IAuthContextProvider) => {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<IUser | undefined>(undefined);

  const login = useCallback((user: IUser, token: string) => {
    setToken(token);
    setUser(user);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  }, []);

  const logout = useCallback(() => {
    setToken(undefined);
    setUser(undefined);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem(USER_KEY);
    const storedToken = localStorage.getItem(TOKEN_KEY);
    if (storedUser && storedToken) {
      const foundUser: IUser = JSON.parse(storedUser);
      const foundToken = JSON.parse(storedToken);
      login(foundUser, foundToken);
    } else {
      logout();
    }
  }, [logout, login]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        login,
        logout,
        token,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const ctx = useContext(AuthContext);

  if (!ctx) throw new Error("AuthContext hook must be used inside a provider");
  return ctx;
};
