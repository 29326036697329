import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AdminDashboard from "./sections/AdminDashboard";
import Dashboard from "./pages/Dashboard";
import Sectors from "./pages/Sectors";
import Projects from "./pages/Projects";
import Budgets from "./pages/Budgets";
import Indicators from "./pages/Indicators";
import Documents from "./pages/Documents";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import { AuthContextProvider } from "./store/AuthContext";
import { HEALTH_DASHBOARD_PATH, PRIMARY_COLOR } from "./utils/config";
import Users from "./pages/Users";
import Departments from "./pages/Departments";
// import Constituencies from "./pages/Constituencies";
// import Wards from "./pages/Wards";
import BulkIndicatorUpload from "./pages/BulkIndicatorUpload";
import CountyInformation from "./pages/CountyInformation";
import BulkUploadProjects from "./pages/BulkUploadProjects";
import ResetPassword from "./pages/ResetPassword";
import HealthDashboard from "./pages/HealthDashboard";
import FullAdminDashboard from "./sections/FullAdminDashboard";
import GuestDashboard from "./sections/GuestDashboard";
import IndicatorData from "./pages/IndicatorData";

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <GuestDashboard>
        <Login />
      </GuestDashboard>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <GuestDashboard>
        <ForgotPassword />
      </GuestDashboard>
    ),
  },
  {
    path: "/password-reset",
    element: (
      <GuestDashboard>
        <ResetPassword />
      </GuestDashboard>
    ),
  },
  {
    path: "/",
    element: (
      <AdminDashboard>
        <Dashboard />
      </AdminDashboard>
    ),
  },
  {
    path: HEALTH_DASHBOARD_PATH,
    element: (
      <FullAdminDashboard>
        <HealthDashboard />
      </FullAdminDashboard>
    ),
  },
  {
    path: "/sectors",
    element: (
      <AdminDashboard>
        <Sectors />
      </AdminDashboard>
    ),
  },
  {
    path: "/projects",
    element: (
      <AdminDashboard>
        <Projects />
      </AdminDashboard>
    ),
  },
  {
    path: "/projects/new",
    element: (
      <AdminDashboard heading="Upload">
        <BulkUploadProjects />
      </AdminDashboard>
    ),
  },
  {
    path: "/budgets",
    element: (
      <AdminDashboard>
        <Budgets />
      </AdminDashboard>
    ),
  },
  {
    path: "/indicators/new",
    element: (
      <AdminDashboard>
        <BulkIndicatorUpload />
      </AdminDashboard>
    ),
  },
  {
    path: "/indicators/data",
    element: (
      <AdminDashboard>
        <IndicatorData />
      </AdminDashboard>
    ),
  },
  {
    path: "/indicators",
    element: (
      <AdminDashboard>
        <Indicators />
      </AdminDashboard>
    ),
  },
  {
    path: "/documents",
    element: (
      <AdminDashboard>
        <Documents />
      </AdminDashboard>
    ),
  },
  {
    path: "/users",
    element: (
      <AdminDashboard>
        <Users />
      </AdminDashboard>
    ),
  },
  {
    path: "/departments",
    element: (
      <AdminDashboard>
        <Departments />
      </AdminDashboard>
    ),
  },
  // {
  //   path: "/constituencies",
  //   element: (
  //     <AdminDashboard>
  //       <Constituencies />
  //     </AdminDashboard>
  //   ),
  // },
  // {
  //   // /indicators/new
  //   path: "/wards",
  //   element: (
  //     <AdminDashboard>
  //       <Wards />
  //     </AdminDashboard>
  //   ),
  // },
  {
    // /indicators/new
    path: "/county-information",
    element: (
      <AdminDashboard>
        <CountyInformation />
      </AdminDashboard>
    ),
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <MantineProvider
      theme={{
        primaryColor: PRIMARY_COLOR,
        fontFamily: "Inter, sans-serif",
        primaryShade: 9,
      }}
    >
      <ModalsProvider>
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </AuthContextProvider>
        <Notifications position="top-right" />
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
