import {
  createStyles,
  Header,
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  SimpleGrid,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import UserNavIcon from "../components/UserNavIcon";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import { useLocation, NavLink } from "react-router-dom";
import {
  BUDGETS,
  DASHBOARD_PATH,
  HEALTH_DASHBOARD_PATH,
  MEDIA,
  PROJECTS,
  SECTORS,
  SETTINGS,
  submenuItems,
} from "../utils/config";
import MenuLinks from "../components/MenuLinks";
import { useAuthContext } from "../store/AuthContext";
import useFetchCountyLogo from "../hooks/useFetchCountyLogo";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    //color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.sm,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

const resolveNestedPathName = (
  pathname: string,
  nestedNavs: string[]
): boolean => {
  for (const nestedNav of nestedNavs) {
    if (pathname === nestedNav) return true;
  }
  return false;
};

export function AdminHeader() {
  const { logo, isLoading: isLoadingCountyLogo } = useFetchCountyLogo();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [settingsLinksOpened, { toggle: toggleSettingsLinks }] =
    useDisclosure(false);
  const [indicatorLinksOpened, { toggle: toggleIndicatorLinks }] =
    useDisclosure(false);
  const [projectsLinksOpened, { toggle: toggleProjectsLinks }] =
    useDisclosure(false);
  const { classes, theme } = useStyles();
  const { logout } = useAuthContext();

  const { pathname } = useLocation();

  return (
    <Box>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <div className="flex-shrink-0">
            {!isLoadingCountyLogo && (
              <div className="flex flex-col">
                <img className="h-12 w-full" src={logo} alt="The county" />
              </div>
            )}
          </div>

          <Group
            sx={{ height: "100%" }}
            spacing={0}
            className={classes.hiddenMobile}
          >
            <NavLink
              to={DASHBOARD_PATH}
              className={classes.link}
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "green" : "black",
                };
              }}
            >
              Dashboard
            </NavLink>
            <NavLink
              to={HEALTH_DASHBOARD_PATH}
              className={classes.link}
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "green" : "black",
                };
              }}
            >
              Health
            </NavLink>
            <HoverCard
              width={600}
              position="bottom"
              radius="md"
              shadow="md"
              withinPortal
            >
              <HoverCard.Target>
                <div
                  className={classes.link}
                  style={{
                    color: resolveNestedPathName(pathname, [
                      PROJECTS.view.link,
                      PROJECTS.add.link,
                    ])
                      ? "green"
                      : "black",
                  }}
                >
                  <Center inline>
                    <Box component="span" mr={5}>
                      Projects
                    </Box>
                    <IconChevronDown
                      size={16}
                      color={theme.fn.primaryColor()}
                    />
                  </Center>
                </div>
              </HoverCard.Target>

              <HoverCard.Dropdown sx={{ overflow: "hidden" }}>
                <SimpleGrid cols={2} spacing={0}>
                  {submenuItems.projects.map((item) => (
                    <MenuLinks
                      key={item.title}
                      description={item.description}
                      icon={item.icon}
                      link={item.link}
                      title={item.title}
                    />
                  ))}
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>
            <NavLink
              to={BUDGETS.home.link}
              className={classes.link}
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "green" : "black",
                };
              }}
            >
              Budgets
            </NavLink>
            <NavLink
              to={SECTORS.sectors.link}
              className={classes.link}
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "green" : "black",
                };
              }}
            >
              Sectors
            </NavLink>
            {/* <NavLink to="/indicators" className={classes.link}>
              Indicators
            </NavLink> */}
            <HoverCard
              width={600}
              position="bottom"
              radius="md"
              shadow="md"
              withinPortal
            >
              <HoverCard.Target>
                <div
                  className={classes.link}
                  style={{
                    color: resolveNestedPathName(pathname, [
                      SECTORS.indicators.link,
                      SECTORS.indicators.add,
                    ])
                      ? "green"
                      : "black",
                  }}
                >
                  <Center inline>
                    <Box component="span" mr={5}>
                      Indicators
                    </Box>
                    <IconChevronDown
                      size={16}
                      color={theme.fn.primaryColor()}
                    />
                  </Center>
                </div>
              </HoverCard.Target>

              <HoverCard.Dropdown sx={{ overflow: "hidden" }}>
                <SimpleGrid cols={2} spacing={0}>
                  {submenuItems.indicators.map((item) => (
                    <MenuLinks
                      key={item.title}
                      description={item.description}
                      icon={item.icon}
                      link={item.link}
                      title={item.title}
                    />
                  ))}
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>
            <NavLink
              to={MEDIA.link}
              className={classes.link}
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "green" : "black",
                };
              }}
            >
              Documents
            </NavLink>

            <HoverCard
              width={600}
              position="bottom"
              radius="md"
              shadow="md"
              withinPortal
            >
              <HoverCard.Target>
                <div
                  className={classes.link}
                  style={{
                    color: resolveNestedPathName(pathname, [
                      SETTINGS.users.link,
                      SETTINGS.wards.link,
                      SETTINGS.constituencies.link,
                      SETTINGS.departments.link,
                      SETTINGS.about.link,
                    ])
                      ? "green"
                      : "black",
                  }}
                >
                  <Center inline>
                    <Box component="span" mr={5}>
                      Settings
                    </Box>
                    <IconChevronDown
                      size={16}
                      color={theme.fn.primaryColor()}
                    />
                  </Center>
                </div>
              </HoverCard.Target>

              <HoverCard.Dropdown sx={{ overflow: "hidden" }}>
                <SimpleGrid cols={2} spacing={0}>
                  {submenuItems.settings.map((item) => (
                    <MenuLinks
                      key={item.title}
                      description={item.description}
                      icon={item.icon}
                      link={item.link}
                      title={item.title}
                    />
                  ))}
                </SimpleGrid>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>
          <UserNavIcon />

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title=""
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <NavLink
            to={"/"}
            className={classes.link}
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "green" : "black",
              };
            }}
          >
            Dashboard
          </NavLink>
          <UnstyledButton
            className={classes.link}
            onClick={toggleProjectsLinks}
          >
            <Center inline>
              <Box component="span" mr={5}>
                Projects
              </Box>
              <IconChevronDown size={16} color={theme.fn.primaryColor()} />
            </Center>
          </UnstyledButton>
          <Collapse in={projectsLinksOpened}>
            {submenuItems.projects.map((item) => (
              <MenuLinks
                key={item.title}
                description={item.description}
                icon={item.icon}
                link={item.link}
                title={item.title}
              />
            ))}
          </Collapse>
          <NavLink
            to={BUDGETS.home.link}
            className={classes.link}
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "green" : "black",
              };
            }}
          >
            Budgets
          </NavLink>
          <NavLink
            to={SECTORS.sectors.link}
            className={classes.link}
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "green" : "black",
              };
            }}
          >
            Sectors
          </NavLink>
          {/* <NavLink to="/indicators" className={classes.link}>
              Indicators
            </NavLink> */}
          <UnstyledButton
            className={classes.link}
            onClick={toggleIndicatorLinks}
          >
            <Center inline>
              <Box component="span" mr={5}>
                Indicators
              </Box>
              <IconChevronDown size={16} color={theme.fn.primaryColor()} />
            </Center>
          </UnstyledButton>
          <Collapse in={indicatorLinksOpened}>
            {submenuItems.indicators.map((item) => (
              <MenuLinks
                key={item.title}
                description={item.description}
                icon={item.icon}
                link={item.link}
                title={item.title}
              />
            ))}
          </Collapse>
          <NavLink
            to={MEDIA.link}
            className={classes.link}
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "green" : "black",
              };
            }}
          >
            Documents
          </NavLink>

          <UnstyledButton
            className={classes.link}
            onClick={toggleSettingsLinks}
          >
            <Center inline>
              <Box component="span" mr={5}>
                Settings
              </Box>
              <IconChevronDown size={16} color={theme.fn.primaryColor()} />
            </Center>
          </UnstyledButton>
          <Collapse in={settingsLinksOpened}>
            {submenuItems.settings.map((item) => (
              <MenuLinks
                key={item.title}
                description={item.description}
                icon={item.icon}
                link={item.link}
                title={item.title}
              />
            ))}
          </Collapse>

          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <Group position="center" grow pb="xl" px="md">
            {/* <Button variant="default">My Account</Button> */}
            <Button
              onClick={async () => {
                try {
                  console.log("Bye . . .");
                  logout();
                } catch (error: any) {
                  notifications.show({
                    message:
                      error && error.data
                        ? error.data
                        : "error signing you you in, please try again",
                    color: "red",
                    icon: <IconX />,
                  });
                }
              }}
            >
              Log out
            </Button>
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
