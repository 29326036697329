import { forwardRef } from "react";
import { Group, Text } from "@mantine/core";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap position="apart">
        <Text>{label}</Text>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </Group>
    </div>
  )
);

export default SelectItem;
