import {
  ActionIcon,
  Button,
  Flex,
  Grid,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
// import { useDisclosure } from "@mantine/hooks";
import { useAuthContext } from "../store/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  BOUNDARY_KEY,
  IBoundaryApiResponse,
  IExtraSelectValues,
  IIndicatorData,
  IIndicatorDataApiResponse,
  INDICATOR_KEY,
  INewIndicatorData,
  ISectorByIdApiResponse,
  ISectorsApiData,
  NEW_INDICATOR_DATA_KEY,
  SECTORS_KEY,
} from "../utils/types";
import axiosInstance from "../utils/axios";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconEdit, IconX } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { IconTrash } from "@tabler/icons-react";
import useFetchData from "../hooks/useFetchBudgets";
import axios, { AxiosResponse } from "axios";
// import NewIndicatorData from "../sections/NewIndicatorData";
// import ViewIndicatorData from "../sections/ViewIndicatorData";
import {
  generateYears,
  getConstituenciesFromBoundary,
  getSectors,
  validateRequired,
} from "../utils/fns";
import useFetchIndicatorData from "../hooks/useFetchIndicatorData";

function validateIndicatorData(data: IIndicatorData) {
  return {
    key: !validateRequired(data.key) ? "Key is required" : "",
    period: !validateRequired(data.period) ? "Period is required" : "",
    period_type: !validateRequired(data.period_type)
      ? "Period type is required"
      : "",
    value: !validateRequired(data.value.toString()) ? "Provide the value" : "",
    value_type: !validateRequired(data.value.toString())
      ? "Provide the value type"
      : "",
    constituency: !validateRequired(data.value.toString())
      ? "Provide the Sub County"
      : "",
  };
}

export const getIndicatorData = (
  fetchedIndicatorData: IIndicatorDataApiResponse | undefined
): IIndicatorData[] =>
  fetchedIndicatorData &&
  fetchedIndicatorData.data &&
  Array.isArray(fetchedIndicatorData.data.data)
    ? fetchedIndicatorData.data.data
    : [];

const IndicatorData = () => {
  //   const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
  //     useDisclosure(false);
  //   const [
  //     updateDrawerOpened,
  //     { open: openUpdateDrawer, close: closeUpdateDrawer },
  //   ] = useDisclosure(false);
  //   const [foundIndicatorData, setFoundIndicatorData] = useState<
  //     IIndicatorData | undefined
  //   >(undefined);
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const [indicators, setIndicators] = useState<IExtraSelectValues[]>([]);
  const [selectedSector, setSelectedSector] = useState<string | null>(null);
  const [selectedIndicator, setSelectedIndicator] = useState<string | null>(
    null
  );
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const { fetchedData: fetchedSectors, isFetching: isFetchingSectors } =
    useFetchData<ISectorsApiData>(SECTORS_KEY, token ? token : "");
  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await axiosInstance.delete(`${NEW_INDICATOR_DATA_KEY}?trend_id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const {
    fetchedData: fetchedIndicatorData,
    isError: isIndicatorDataError,
    isFetching: isLoadingIndicatorData,
    // isLoading: isLoadingIndicatorData,
  } = useFetchIndicatorData<IIndicatorDataApiResponse>(
    `${INDICATOR_KEY}/${selectedIndicator}`,
    token ? token : "",
    !!selectedIndicator
  );

  const openDeleteConfirmModal = (row: MRT_Row<IIndicatorData>) =>
    modals.openConfirmModal({
      title: `Delete?`,
      children: (
        <Text>
          Are you sure you want to delete this record? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await deleteMutation.mutate(row.id, {
            onSuccess: () => {
              notifications.show({
                title: "Success",
                message: "Record deleted",
                icon: <IconCheck />,
                color: "teal",
              });
            },
            onError(error: any, variables, context) {
              notifications.show({
                title: "Error",
                message:
                  error && error.data
                    ? error.data
                    : "An error occured, try again",
                icon: <IconX />,
                color: "red",
              });
            },
            onSettled(data, error, variables, context) {
              queryClient.invalidateQueries([
                `${INDICATOR_KEY}/${selectedIndicator}`,
              ]);
            },
          });
        } catch (error) {}
      },
    });
  const {
    fetchedData: fetchedConstituencies,
    //isError,
    // isLoading,
    // isFetching: isFetchingConstituencies,
  } = useFetchData<IBoundaryApiResponse>(BOUNDARY_KEY, token ? token : "");

  const columns = useMemo<MRT_ColumnDef<IIndicatorData>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
      },
      {
        accessorKey: "constituency",
        header: "Sub County",
        editVariant: "select",
        mantineEditSelectProps: {
          required: true,
          data: getConstituenciesFromBoundary(fetchedConstituencies).map(
            (d) => ({
              value: d.name,
              label: d.name,
              name: d.name,
            })
          ),
          error: validationErrors?.constituency,
        },
      },
      {
        accessorKey: "key",
        header: "Key",
        mantineEditTextInputProps: {
          type: "text",
          required: true,
          error: validationErrors?.key,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              key: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },

      {
        accessorKey: "value",
        header: "Value",
        mantineEditTextInputProps: {
          type: "number",
          required: true,
          error: validationErrors?.value,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              value: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: "value_type",
        header: "Value Type",
        editVariant: "select",
        mantineEditSelectProps: {
          required: true,
          data: [
            {
              value: "Number",
              label: "Number",
            },
            {
              value: "Text",
              label: "Text",
            },
          ],
          error: validationErrors?.value_type,
        },
        // mantineEditTextInputProps: {
        //   type: "text",
        //   required: true,
        //   error: validationErrors?.value_type,
        //   //remove any previous validation errors when user focuses on the input
        //   onFocus: () =>
        //     setValidationErrors({
        //       ...validationErrors,
        //       value_type: undefined,
        //     }),
        // },
      },
      {
        accessorKey: "period",
        header: "Period",
        editVariant: "select",
        mantineEditSelectProps: {
          required: true,
          data: generateYears(2013).map((year) => ({
            value: year,
            label: year,
          })),
          error: validationErrors?.period,
        },
      },
      {
        accessorKey: "period_type",
        header: "Period Type",
        editVariant: "select",
        mantineEditSelectProps: {
          required: true,
          data: [
            {
              value: "Year",
              label: "Year",
            },
            {
              value: "Month",
              label: "Month",
            },
            {
              value: "Fiscal Year",
              label: "Fiscal Year",
            },
          ],
          error: validationErrors?.period_type,
        },
        // mantineEditTextInputProps: {
        //   type: "text",
        //   required: true,
        //   error: validationErrors?.period_type,
        //   //remove any previous validation errors when user focuses on the input
        //   onFocus: () =>
        //     setValidationErrors({
        //       ...validationErrors,
        //       period_type: undefined,
        //     }),
        //   //optionally add validation checking for onBlur or onChange
        // },
      },
      {
        accessorKey: "group",
        header: "Group",
        mantineEditTextInputProps: {
          type: "text",
          required: false,
          //optionally add validation checking for onBlur or onChange
        },
      },
    ],
    [validationErrors, fetchedConstituencies]
  );

  //   const handleUpdateIndicatorData = (row: MRT_Row<IIndicatorData>) => {
  //     setFoundIndicatorData(row.original);
  //     openUpdateDrawer();
  //   };

  const mutateIndicatorData = useMutation({
    mutationFn: async (newIndicatorData: INewIndicatorData) => {
      await axiosInstance.post(NEW_INDICATOR_DATA_KEY, newIndicatorData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  //CREATE action
  const handleCreateData: MRT_TableOptions<IIndicatorData>["onCreatingRowSave"] =
    async ({ values, exitCreatingMode }) => {
      const newValidationErrors = validateIndicatorData(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      if (!selectedIndicator) {
        notifications.show({
          title: "Error",
          message: "Select the indicator",
          icon: <IconX />,
          color: "red",
        });
        return;
      }

      const {
        constituency,
        group,
        key,
        measurement,
        period,
        period_type,
        unit,
        value,
        value_type,
      } = values;

      const foundC = getConstituenciesFromBoundary(
        fetchedConstituencies
      ).filter((c) => c.name === constituency)[0];

      mutateIndicatorData.mutate(
        {
          group,
          index: selectedIndicator,
          key,
          measurement,
          period,
          period_type,
          constituency_id: foundC.id,
          unit,
          value: Number(value),
          value_type,
        },
        {
          onSettled(data, error, variables, context) {
            queryClient.invalidateQueries([
              `${INDICATOR_KEY}/${selectedIndicator}`,
            ]);
          },
          onSuccess(data, variables, context) {
            notifications.show({
              title: "Success",
              message: "Item Added",
              icon: <IconCheck />,
              color: "teal",
            });
          },
          onError(error: any, variables, context) {
            notifications.show({
              title: "Error",
              message:
                error && error.message
                  ? error.message
                  : "An error occured, try again",
              icon: <IconX />,
              color: "red",
            });
          },
        }
      );
      exitCreatingMode();
    };

  const mutateUpdateIndicatorData = useMutation({
    mutationFn: async (
      updatedData: { trend_id: string } & INewIndicatorData
    ) => {
      await axiosInstance.put(
        `${NEW_INDICATOR_DATA_KEY}`,
        {
          ...updatedData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  //UPDATE action
  const handleSaveIndicatorData: MRT_TableOptions<IIndicatorData>["onEditingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateIndicatorData(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      const foundC = getConstituenciesFromBoundary(
        fetchedConstituencies
      ).filter((c) => c.name === values.constituency)[0];
      mutateUpdateIndicatorData.mutate(
        {
          trend_id: values.id,
          group: values.group,
          index: selectedIndicator ? selectedIndicator : "",
          key: values.key,
          measurement: values.measurement,
          period: values.period,
          period_type: values.period_type,
          constituency_id: foundC.id,
          unit: values.unit,
          value: Number(values.value),
          value_type: values.value_type,
        },
        {
          onSettled(data, error, variables, context) {
            queryClient.invalidateQueries([
              `${INDICATOR_KEY}/${selectedIndicator}`,
            ]);
          },
          onSuccess(data, variables, context) {
            notifications.show({
              title: "Success",
              message: "Item Updated",
              icon: <IconCheck />,
              color: "teal",
            });
          },
          onError(error: any, variables, context) {
            notifications.show({
              title: "Error",
              message:
                error && error.data
                  ? error.data
                  : "An error occured, try again",
              icon: <IconX />,
              color: "red",
            });
          },
        }
      );
      table.setEditingRow(null); //exit editing mode
    };

  useEffect(() => {
    if (
      fetchedSectors &&
      fetchedSectors.data &&
      selectedSector &&
      selectedSector.length > 0
    ) {
      const s = fetchedSectors.data.filter(
        (s) => s.id === Number(selectedSector)
      )[0];
      if (s) {
        axios
          .get(
            `${process.env.REACT_APP_BASEURL}/api/sectors/${s.id.toString()}`
          )
          .then((res: AxiosResponse<ISectorByIdApiResponse>) => {
            if (res.data && res.data.data) {
              const i = res.data.data.indicators.map((indicator) => ({
                value: indicator.index,
                label: `${indicator.index} - ${indicator.name}`,
                name: indicator.index,
              }));
              setIndicators(i);
            }
          });
      }
    }
  }, [selectedSector, fetchedSectors]);

  const table = useMantineReactTable({
    columns,
    data: getIndicatorData(fetchedIndicatorData),
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "row", // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    enablePagination: true,
    getRowId: (row) => (row.id ? row.id.toString() : ""),
    initialState: {
      density: "xs", //set default density to compact
    },
    mantineToolbarAlertBannerProps: isIndicatorDataError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateData,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveIndicatorData,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => table.setEditingRow(row)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Add a New Record
      </Button>
    ),
    state: {
      isLoading: isLoadingIndicatorData,
      isSaving:
        mutateIndicatorData.isLoading || mutateUpdateIndicatorData.isLoading,
      showAlertBanner: isIndicatorDataError,
      showProgressBars: false,
    },
  });

  return (
    <Grid>
      <Grid.Col sm={12} md={6} lg={6}>
        <Select
          label="Sector"
          placeholder="Sector"
          disabled={isFetchingSectors}
          withAsterisk
          value={selectedSector}
          onChange={setSelectedSector}
          data={getSectors(fetchedSectors).map((sector) => ({
            value: sector.id.toString(),
            label: sector.name,
          }))}
        />
      </Grid.Col>
      <Grid.Col sm={12} md={6} lg={6}>
        <Select
          label="Indicator"
          placeholder="Indicator"
          searchable
          disabled={indicators.length === 0}
          withAsterisk
          value={selectedIndicator}
          onChange={setSelectedIndicator}
          data={indicators}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <MantineReactTable table={table} />
      </Grid.Col>
      {/* <Grid.Col span={12}>
        <NewIndicatorData
          closeDrawer={closeDrawer}
          drawerOpened={drawerOpened}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <ViewIndicatorData
          closeDrawer={closeUpdateDrawer}
          drawerOpened={updateDrawerOpened}
          foundIndicatorData={foundIndicatorData}
          indicatorIndex={selectedIndicator ? selectedIndicator : ""}
          sectorId={selectedSector ? Number(selectedSector) : 0}
        />
      </Grid.Col> */}
    </Grid>
  );
};

export default IndicatorData;
