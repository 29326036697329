export const fetchGuestToken = async () => {
  try {
    const res = await fetch("http://localhost:8088/api/v1/security/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: "admin",
        username: "admin",
        refresh: true,
        provider: "db",
      }),
    });

    if (!res.ok) {
      throw new Error("error login");
    }

    const adminTkn = await res.json();

    const csrfRes = await fetch(
      "http://localhost:8088/api/v1/security/csrf_token/",
      {
        headers: {
          Authorization: `Bearer ${adminTkn.access_token}`,
        },
      }
    );
    if (!csrfRes.ok) {
      throw new Error("error CSRF token");
    }

    const csrfTkns = await csrfRes.json();

    const guestTkn = await fetch(
      "http://localhost:8088/api/v1/security/guest_token/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfTkns.result,
          Authorization: `Bearer ${adminTkn.access_token}`,
        },
        body: JSON.stringify({
          resources: [
            {
              id: "dace8231-6c24-423d-bacc-bc254637ed04",
              type: "dashboard",
            },
          ],
          rls: [],
          user: {
            first_name: "Integration",
            last_name: "User",
            username: "integration",
          },
        }),
      }
    );

    if (!guestTkn.ok) {
      throw new Error("error fetching Guest token");
    }

    const resGuest = await guestTkn.json();
    console.log(resGuest);
    return resGuest.token;
  } catch (error) {
    console.log(error);
  }
};
