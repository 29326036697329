import { ReactNode } from "react";

// import { AppNavbar } from "@/app/components/Navbar";
// import { BreadCrumb } from "../components/BreadCrumb";
import { AdminHeader } from "./MobileNav";
import AuthGuard from "../guards/AuthGuard";

const FullAdminDashboard = ({
  children,
  heading,
}: {
  children: ReactNode;
  heading?: string;
}) => {
  // const { pathname } = useLocation();
  return (
    <AuthGuard>
      <div className="flex flex-col min-h-screen">
        <main className="flex-1">
          <AdminHeader />
          <div className="mx-auto max-w-full pt-4 sm:px-6 lg:px-8">
            {children}
          </div>
        </main>
        {/* <AppFooter /> */}
      </div>
    </AuthGuard>
  );
};

export default FullAdminDashboard;
