import {
  Alert,
  Button,
  Center,
  Grid,
  Group,
  Image,
  Loader,
  Skeleton,
  Text,
  Title,
} from "@mantine/core";
import { COUNTY_INFO_KEY, ICountyInfoApiResponse } from "../utils/types";
import { useAuthContext } from "../store/AuthContext";
import { IconAlertCircle, IconEdit } from "@tabler/icons-react";
import ContactsTable from "../components/ContactsTable";
import ViewAboutUs from "../sections/ModifyAboutUs";
import { useDisclosure } from "@mantine/hooks";
import LeadersTable from "../components/LeadersTable";
import useFetchData from "../hooks/useFetchBudgets";
import ModifyCountyLeadership from "../sections/ModifyCountyLeadership";

// const dataArray = (contactObj: Contacts) =>
//   Object.keys(contactObj).map((key) => ({
//     name: key,
//     value: contactObj[key as keyof Contacts],
//   }));

const CountyInformation = () => {
  const { token } = useAuthContext();
  const {
    fetchedData: fetchedCountyInfo,
    isError,
    // isLoading,
    isFetching,
  } = useFetchData<ICountyInfoApiResponse>(COUNTY_INFO_KEY, token ? token : "");
  const [
    updateDrawerOpened,
    { open: openUpdateDrawer, close: closeUpdateDrawer },
  ] = useDisclosure(false);

  const [
    countyLeadershipDrawerOpened,
    {
      open: openCountyLeadershipUpdateDrawer,
      close: closeCountyLeadershipDrawer,
    },
  ] = useDisclosure(false);

  if (isError) {
    return (
      <Alert icon={<IconAlertCircle size="1rem" />} title="Error!" color="red">
        Unable to fetch the county information
      </Alert>
    );
  }

  if (isFetching) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (!fetchedCountyInfo) {
    return (
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title="Error!"
        color="yellow"
      >
        We could not find any information for this county
      </Alert>
    );
  }

  return (
    <Grid>
      <Grid.Col span={12}>
        <Group position="apart">
          <Title className="styled-heading styled-heading-large">
            County Information
          </Title>
          <Button leftIcon={<IconEdit />} onClick={openUpdateDrawer}>
            County Information
          </Button>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={3}>
        {fetchedCountyInfo.data && fetchedCountyInfo.data.logo ? (
          <Image
            maw={256}
            mt={10}
            withPlaceholder
            h={10}
            w="auto"
            mx="auto"
            src={
              fetchedCountyInfo.data && fetchedCountyInfo.data.logo
                ? fetchedCountyInfo.data.logo
                : ""
            }
            alt={
              fetchedCountyInfo.data && fetchedCountyInfo.data.name
                ? fetchedCountyInfo.data.name
                : ""
            }
          />
        ) : (
          <Skeleton height={120} animate={false} />
        )}
      </Grid.Col>
      <Grid.Col xs={12} sm={6} md={9}>
        <Text
          fz="sm"
          dangerouslySetInnerHTML={{
            __html:
              fetchedCountyInfo.data && fetchedCountyInfo.data.description
                ? fetchedCountyInfo.data.description
                : "No information found",
          }}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Title className="styled-heading styled-heading-small">
          Contact Information
        </Title>
      </Grid.Col>
      <Grid.Col xs={12} sm={12} md={12}>
        <ContactsTable
          contacts={
            fetchedCountyInfo &&
            fetchedCountyInfo.data &&
            fetchedCountyInfo.data.contacts
              ? fetchedCountyInfo.data.contacts
              : []
          }
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Group position="apart">
          <Title className="styled-heading styled-heading-small">
            Leadership
          </Title>
          <Button
            leftIcon={<IconEdit />}
            onClick={openCountyLeadershipUpdateDrawer}
          >
            Leadership
          </Button>
        </Group>
      </Grid.Col>
      <Grid.Col xs={12} sm={12} md={12}>
        {/* <LeadersTable
          leaders={
            fetchedCountyInfo &&
            fetchedCountyInfo.data &&
            fetchedCountyInfo.data.leaders &&
            fetchedCountyInfo.data.leaders.length > 0
              ? fetchedCountyInfo.data.leaders
              : []
          }
        /> */}
        <LeadersTable
          leaders={
            fetchedCountyInfo &&
            fetchedCountyInfo.data &&
            fetchedCountyInfo.data.leaders &&
            fetchedCountyInfo.data.leaders.length > 0
              ? fetchedCountyInfo.data.leaders
              : []
          }
        />
      </Grid.Col>
      <Grid.Col xs={12} sm={12} md={12}>
        <ViewAboutUs
          closeDrawer={closeUpdateDrawer}
          drawerOpened={updateDrawerOpened}
          foundAboutUs={fetchedCountyInfo.data}
        />
      </Grid.Col>
      <Grid.Col xs={12} sm={12} md={12}>
        <ModifyCountyLeadership
          closeDrawer={closeCountyLeadershipDrawer}
          drawerOpened={countyLeadershipDrawerOpened}
          foundAboutUs={fetchedCountyInfo.data}
        />
      </Grid.Col>
    </Grid>
  );
};

export default CountyInformation;
