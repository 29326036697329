import { useMemo, useState } from "react";
import {
  Button,
  Group,
  Avatar,
  Text,
  Flex,
  Tooltip,
  ActionIcon,
  Grid,
  Title,
} from "@mantine/core";

import { IUser, IUserApiResponse, USERS_KEY } from "../utils/types";
import { IconEdit } from "@tabler/icons-react";
import { PRIMARY_COLOR } from "../utils/config";
import { useAuthContext } from "../store/AuthContext";
import { useDisclosure } from "@mantine/hooks";
import {
  MRT_ColumnDef,
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { getInitials } from "../utils/fns";
import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import NewUser from "../sections/NewUser";
import ViewUser from "../sections/ViewUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import useFetchData from "../hooks/useFetchBudgets";

const Users = () => {
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [
    updateDrawerOpened,
    { open: openUpdateDrawer, close: closeUpdateDrawer },
  ] = useDisclosure(false);
  const [foundUser, setFoundUser] = useState<IUser | undefined>(undefined);
  const queryClient = useQueryClient();
  const { token } = useAuthContext();

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await axiosInstance.delete(`/cms${USERS_KEY}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const columns = useMemo<MRT_ColumnDef<IUser>[]>(
    () => [
      {
        accessorKey: "name",
        enableEditing: false,
        header: "User",
        Cell: ({ row }) => {
          return (
            <Group spacing="sm">
              <Avatar size={32} radius={26}>
                {getInitials(row.original.name)}
              </Avatar>
              <Text size="sm" fw={500}>
                {row.original.name}
              </Text>
            </Group>
          );
        },
      },
      {
        accessorKey: "email",
        header: "Email",
      },
    ],
    []
  );

  const {
    fetchedData: fetchedUsers,
    isError: isLoadingUsersError,
    //isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useFetchData<IUserApiResponse>(`/cms${USERS_KEY}`, token ? token : "");

  const openDeleteConfirmModal = (row: MRT_Row<IUser>) =>
    modals.openConfirmModal({
      title: "Are you sure you want to delete this user?",
      children: (
        <Text>
          Are you sure you want to delete {row.original.name} ? This action
          cannot be undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await deleteMutation.mutate(row.id, {
            onSuccess: () => {
              notifications.show({
                title: "Success",
                message: "User deleted",
                icon: <IconCheck />,
                color: "teal",
              });
            },
            onError(error: any, variables, context) {
              notifications.show({
                title: "Error",
                message:
                  error && error.data
                    ? error.data
                    : "An error occured, try again",
                icon: <IconX />,
                color: "red",
              });
            },
            onSettled(data, error, variables, context) {
              queryClient.invalidateQueries([`/cms${USERS_KEY}`]);
            },
          });
        } catch (error) {}
      },
    });

  const handleUpdateUser = (row: MRT_Row<IUser>) => {
    setFoundUser(row.original);
    openUpdateDrawer();
  };

  const table = useMantineReactTable({
    columns,
    data:
      fetchedUsers && Array.isArray(fetchedUsers.data) ? fetchedUsers.data : [],
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "cell", // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    getRowId: (row) => (row.id ? row.id.toString() : ""),
    initialState: {
      density: "xs", //set default density to compact
    },
    mantineToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            color={PRIMARY_COLOR}
            onClick={() => {
              handleUpdateUser(row);
            }}
          >
            <IconEdit size={24} />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button onClick={openDrawer} variant="filled" size="sm">
        Create New User
      </Button>
    ),
    state: {
      isLoading: isLoadingUsers,
      showAlertBanner: isLoadingUsersError,
      //showProgressBars: isFetchingUsers,
    },
  });

  return (
    <Grid>
      <Grid.Col span={12}>
        <Title className="styled-heading styled-heading-large">Users</Title>
      </Grid.Col>
      <Grid.Col span={12}>
        <MantineReactTable table={table} />
      </Grid.Col>
      <Grid.Col span={12}>
        <NewUser closeDrawer={closeDrawer} drawerOpened={drawerOpened} />
      </Grid.Col>
      <Grid.Col span={12}>
        <ViewUser
          closeDrawer={closeUpdateDrawer}
          drawerOpened={updateDrawerOpened}
          foundUser={foundUser}
        />
      </Grid.Col>
    </Grid>
  );
};

export default Users;
