import { useForm } from "@mantine/form";
import {
  Grid,
  Button,
  Group,
  Drawer,
  Title,
  TextInput,
  FileInput,
  rem,
  Avatar,
  Stack,
} from "@mantine/core";

import { IconPlus, IconUpload } from "@tabler/icons-react";
import { INewDataProps } from "./NewSector";
import { useEffect, useState } from "react";
import {
  COUNTY_INFO_KEY,
  CountyInfo,
  Leader,
  MUTATE_LEADERS_INFO_KEY,
} from "../utils/types";
import axiosInstance from "../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "../store/AuthContext";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";

const getLeaderAvi = (
  leadersInfo: Leader[],
  toCheck: "governor" | "deputy_governor" | "senator" | "women_rep"
): string => {
  const val = leadersInfo.filter((leaderInfo) => leaderInfo.key === toCheck);
  if (val.length > 0) {
    return val[0].avatar ? val[0].avatar : "";
  }
  return "";
};

const getLeader = (
  leadersInfo: Leader[],
  toCheck: "governor" | "deputy_governor" | "senator" | "women_rep"
): Leader | undefined => {
  return leadersInfo.filter((leaderInfo) => leaderInfo.key === toCheck)[0];
};

export interface IUpdateAboutUsProps extends INewDataProps {
  foundAboutUs?: CountyInfo;
}

const ModifyCountyLeadership = ({
  closeDrawer,
  drawerOpened,
  foundAboutUs,
}: IUpdateAboutUsProps) => {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const [governorImage, setGovernorImage] = useState<File | null>(null);
  const [deputyGovernorImage, setDeputyGovernorImage] = useState<File | null>(
    null
  );
  const [senatorImage, setSenatorImage] = useState<File | null>(null);
  const [womenRepImage, setWomenRepImage] = useState<File | null>(null);

  const mutateLeaders = useMutation({
    mutationFn: async (newLeaders: FormData) => {
      await axiosInstance.post(MUTATE_LEADERS_INFO_KEY, newLeaders, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const updateLeaders = useMutation({
    mutationFn: async (leadersInfo: { data: FormData; id: number }) => {
      await axiosInstance.post(
        `${MUTATE_LEADERS_INFO_KEY}/${leadersInfo.id}`,
        leadersInfo.data,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  const form = useForm({
    initialValues: {
      governor_name: "",
      governor_social: "",
      deputy_governor_name: "",
      deputy_governor_social: "",
      senator_name: "",
      senator_social: "",
      womenrep_name: "",
      womenrep_social: "",
    },
    validate: {
      governor_name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      deputy_governor_name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      senator_name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      womenrep_name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
    },
  });

  useEffect(() => {
    if (
      foundAboutUs &&
      foundAboutUs.leaders &&
      foundAboutUs.leaders.length > 0
    ) {
      const g = foundAboutUs.leaders.find((l) => l.key === "governor");
      if (g) {
        form.setValues({
          governor_name: g.name,
          governor_social: g.twitter ? g.twitter : "",
        });
      }
      const dg = foundAboutUs.leaders.find((l) => l.key === "deputy_governor");
      if (dg) {
        form.setValues({
          deputy_governor_name: dg.name,
          deputy_governor_social: dg.twitter ? dg.twitter : "",
        });
      }
      const s = foundAboutUs.leaders.find((l) => l.key === "senator");
      if (s) {
        form.setValues({
          senator_name: s.name,
          senator_social: s.twitter ? s.twitter : "",
        });
      }
      const w = foundAboutUs.leaders.find((l) => l.key === "women_rep");
      if (w) {
        form.setValues({
          womenrep_name: w.name,
          womenrep_social: w.twitter ? w.twitter : "",
        });
      }
    }
  }, [foundAboutUs]);

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="xl"
      onClose={closeDrawer}
      title="Leadership"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          const {
            deputy_governor_name,
            deputy_governor_social,
            governor_name,
            governor_social,
            senator_name,
            senator_social,
            womenrep_name,
            womenrep_social,
          } = values;

          let gov: Leader | undefined = undefined;
          let dGov: Leader | undefined = undefined;
          let senator: Leader | undefined = undefined;
          let women_rep: Leader | undefined = undefined;
          if (foundAboutUs) {
            gov = getLeader(foundAboutUs.leaders, "governor");
            dGov = getLeader(foundAboutUs.leaders, "deputy_governor");
            senator = getLeader(foundAboutUs.leaders, "senator");
            women_rep = getLeader(foundAboutUs.leaders, "women_rep");
          }

          const governorFormData = new FormData();
          const deputyGovernorFormData = new FormData();
          const senatorFormData = new FormData();
          const womenRepFormData = new FormData();

          const leaderItems = [
            {
              age: "",
              avatar: governorImage,
              key: "governor",
              name: governor_name,
              twitter: governor_social,
            },
            {
              age: "",
              avatar: deputyGovernorImage,
              key: "deputy_governor",
              name: deputy_governor_name,
              twitter: deputy_governor_social,
            },
            {
              age: "",
              avatar: senatorImage,
              key: "senator",
              name: senator_name,
              twitter: senator_social,
            },
            {
              age: "",
              avatar: womenRepImage,
              key: "women_rep",
              name: womenrep_name,
              twitter: womenrep_social,
            },
          ];

          governorFormData.append("age", "");
          if (leaderItems[0].avatar) {
            governorFormData.append("avatar", leaderItems[0].avatar);
          }
          governorFormData.append("key", leaderItems[0].key);
          governorFormData.append("twitter", leaderItems[0].twitter);
          governorFormData.append("name", governor_name);
          if (gov) {
            governorFormData.append("_method", "PUT");
          }

          deputyGovernorFormData.append("age", "");
          if (leaderItems[1].avatar) {
            deputyGovernorFormData.append("avatar", leaderItems[1].avatar);
          }
          deputyGovernorFormData.append("key", leaderItems[1].key);
          deputyGovernorFormData.append("twitter", leaderItems[1].twitter);
          deputyGovernorFormData.append("name", deputy_governor_name);
          if (dGov) {
            deputyGovernorFormData.append("_method", "PUT");
          }

          senatorFormData.append("age", "");
          if (leaderItems[2].avatar) {
            senatorFormData.append("avatar", leaderItems[2].avatar);
          }
          senatorFormData.append("key", leaderItems[2].key);
          senatorFormData.append("twitter", leaderItems[2].twitter);
          senatorFormData.append("name", senator_name);
          if (senator) {
            senatorFormData.append("_method", "PUT");
          }

          womenRepFormData.append("age", "");
          if (leaderItems[3].avatar) {
            womenRepFormData.append("avatar", leaderItems[3].avatar);
          }
          womenRepFormData.append("key", leaderItems[3].key);
          womenRepFormData.append("twitter", leaderItems[3].twitter);
          womenRepFormData.append("name", womenrep_name);
          if (women_rep) {
            womenRepFormData.append("_method", "PUT");
          }

          // add leaders if they did not exist : POST

          try {
            // mutate leaders
            if (!foundAboutUs) {
              await mutateLeaders.mutate(governorFormData, {
                onSuccess() {
                  notifications.show({
                    title: "Success",
                    message: "Governor Information Saved",
                    icon: <IconCheck />,
                    color: "teal",
                  });
                },
                onError(error: any) {
                  notifications.show({
                    title: "Error",
                    message:
                      error && error.data
                        ? error.data
                        : "An error occured, try again",
                    icon: <IconX />,
                    color: "red",
                  });
                  return;
                },
              });
              await mutateLeaders.mutate(deputyGovernorFormData, {
                onSuccess() {
                  notifications.show({
                    title: "Success",
                    message: "Deputy Governor Information Saved",
                    icon: <IconCheck />,
                    color: "teal",
                  });
                },
                onError(error: any) {
                  notifications.show({
                    title: "Error",
                    message:
                      error && error.data
                        ? error.data
                        : "An error occured, try again",
                    icon: <IconX />,
                    color: "red",
                  });
                  return;
                },
              });
              await mutateLeaders.mutate(senatorFormData, {
                onSuccess() {
                  notifications.show({
                    title: "Success",
                    message: "Senator Information Saved",
                    icon: <IconCheck />,
                    color: "teal",
                  });
                },
                onError(error: any) {
                  notifications.show({
                    title: "Error",
                    message:
                      error && error.data
                        ? error.data
                        : "An error occured, try again",
                    icon: <IconX />,
                    color: "red",
                  });
                  return;
                },
                onSettled() {},
              });
              await mutateLeaders.mutate(womenRepFormData, {
                onSuccess() {
                  notifications.show({
                    title: "Success",
                    message: "Women Rep Information Saved",
                    icon: <IconCheck />,
                    color: "teal",
                  });
                },
                onError(error: any) {
                  notifications.show({
                    title: "Error",
                    message:
                      error && error.data
                        ? error.data
                        : "An error occured, try again",
                    icon: <IconX />,
                    color: "red",
                  });
                  return;
                },
                onSettled() {
                  queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                  closeDrawer();
                },
              });
            } else {
              // update governor

              if (gov) {
                await updateLeaders.mutate(
                  {
                    id: gov.id,
                    data: governorFormData,
                  },
                  {
                    onSuccess() {
                      notifications.show({
                        title: "Success",
                        message: "Governor Information Saved",
                        icon: <IconCheck />,
                        color: "teal",
                      });
                    },
                    onError(error: any) {
                      notifications.show({
                        title: "Error",
                        message:
                          error && error.data
                            ? error.data
                            : "An error occured, try again",
                        icon: <IconX />,
                        color: "red",
                      });
                      return;
                    },
                    onSettled(data, error, variables, context) {
                      queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                      closeDrawer();
                    },
                  }
                );
              } else {
                await mutateLeaders.mutate(governorFormData, {
                  onSuccess() {
                    notifications.show({
                      title: "Success",
                      message: "Governor Information Saved",
                      icon: <IconCheck />,
                      color: "teal",
                    });
                  },
                  onSettled() {
                    queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                    closeDrawer();
                  },
                  onError(error: any) {
                    notifications.show({
                      title: "Error",
                      message:
                        error && error.data
                          ? error.data
                          : "An error occured, try again",
                      icon: <IconX />,
                      color: "red",
                    });
                    return;
                  },
                });
              }

              // update deputy governor

              if (dGov) {
                await updateLeaders.mutate(
                  {
                    id: dGov.id,
                    data: deputyGovernorFormData,
                  },
                  {
                    onSuccess() {
                      notifications.show({
                        title: "Success",
                        message: "Deputy Governor Information Saved",
                        icon: <IconCheck />,
                        color: "teal",
                      });
                    },
                    onError(error: any) {
                      notifications.show({
                        title: "Error",
                        message:
                          error && error.data
                            ? error.data
                            : "An error occured, try again",
                        icon: <IconX />,
                        color: "red",
                      });
                    },
                    onSettled(data, error, variables, context) {
                      queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                      closeDrawer();
                    },
                  }
                );
              } else {
                await mutateLeaders.mutate(deputyGovernorFormData, {
                  onSuccess() {
                    notifications.show({
                      title: "Success",
                      message: "Deputy Governor Information Saved",
                      icon: <IconCheck />,
                      color: "teal",
                    });
                  },
                  onError(error: any) {
                    notifications.show({
                      title: "Error",
                      message:
                        error && error.data
                          ? error.data
                          : "An error occured, try again",
                      icon: <IconX />,
                      color: "red",
                    });
                  },
                  onSettled() {
                    queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                    closeDrawer();
                  },
                });
              }

              // update senator

              if (senator) {
                await updateLeaders.mutate(
                  {
                    id: senator.id,
                    data: senatorFormData,
                  },
                  {
                    onSuccess() {
                      //   notifications.show({
                      //     title: "Success",
                      //     message: "Senator Information Saved",
                      //     icon: <IconCheck />,
                      //     color: "teal",
                      //   });
                    },
                    onError(error: any) {
                      notifications.show({
                        title: "Error",
                        message:
                          error && error.data
                            ? error.data
                            : "An error occured, try again",
                        icon: <IconX />,
                        color: "red",
                      });
                    },
                    onSettled(data, error, variables, context) {
                      queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                      closeDrawer();
                    },
                  }
                );
              } else {
                await mutateLeaders.mutate(senatorFormData, {
                  onSuccess() {
                    notifications.show({
                      title: "Success",
                      message: "Senator Information Saved",
                      icon: <IconCheck />,
                      color: "teal",
                    });
                  },
                  onError(error: any) {
                    notifications.show({
                      title: "Error",
                      message:
                        error && error.data
                          ? error.data
                          : "An error occured, try again",
                      icon: <IconX />,
                      color: "red",
                    });
                  },
                  onSettled() {
                    queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                    closeDrawer();
                  },
                });
              }

              // update women rep
              if (women_rep) {
                await updateLeaders.mutate(
                  {
                    id: women_rep.id,
                    data: womenRepFormData,
                  },
                  {
                    onSuccess() {
                      console.log("women_rep . . .");
                    },
                    onError(error: any) {
                      notifications.show({
                        title: "Error",
                        message:
                          error && error.data
                            ? error.data
                            : "An error occured, try again",
                        icon: <IconX />,
                        color: "red",
                      });
                    },
                    onSettled(data, error, variables, context) {
                      queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                      closeDrawer();
                    },
                  }
                );
              } else {
                await mutateLeaders.mutate(womenRepFormData, {
                  onSuccess() {
                    notifications.show({
                      title: "Success",
                      message: "Women Rep Information Saved",
                      icon: <IconCheck />,
                      color: "teal",
                    });
                  },
                  onError(error: any) {
                    notifications.show({
                      title: "Error",
                      message:
                        error && error.data
                          ? error.data
                          : "An error occured, try again",
                      icon: <IconX />,
                      color: "red",
                    });
                    return;
                  },
                  onSettled() {
                    queryClient.invalidateQueries([COUNTY_INFO_KEY]);
                    closeDrawer();
                  },
                });
              }
            }
          } catch (error) {}
        })}
      >
        <Grid gutterXs="md" gutterMd="md">
          <Grid.Col span={12}>
            <Title order={3}>Governor</Title>
          </Grid.Col>
          {foundAboutUs &&
          foundAboutUs.leaders &&
          foundAboutUs.leaders.length > 0 ? (
            <Grid.Col span={12}>
              <Group grow>
                <Avatar
                  src={getLeaderAvi(foundAboutUs.leaders, "governor")}
                  size={220}
                />
                <Stack align="stretch" justify="center">
                  <TextInput
                    withAsterisk
                    label="Governor Name"
                    placeholder="Governor Name"
                    {...form.getInputProps("governor_name")}
                  />
                  <TextInput
                    label="Governor's Social Media"
                    placeholder="Governor's Social Media"
                    {...form.getInputProps("governor_social")}
                  />
                  <FileInput
                    label="Governor's Image"
                    placeholder="Governor's Image"
                    icon={<IconUpload size={rem(14)} />}
                    value={governorImage}
                    accept="image/png,image/jpeg"
                    onChange={setGovernorImage}
                  />
                </Stack>
              </Group>
            </Grid.Col>
          ) : (
            <Grid.Col span={12}>
              <Group grow>
                <Avatar size={220} />
                <Stack align="stretch" justify="center">
                  <TextInput
                    withAsterisk
                    label="Governor Name"
                    placeholder="Governor Name"
                    {...form.getInputProps("governor_name")}
                  />
                  <TextInput
                    label="Governor's Social Media"
                    placeholder="Governor's Social Media"
                    {...form.getInputProps("governor_social")}
                  />
                  <FileInput
                    label="Governor's Image"
                    placeholder="Governor's Image"
                    icon={<IconUpload size={rem(14)} />}
                    value={governorImage}
                    accept="image/png,image/jpeg"
                    onChange={setGovernorImage}
                  />
                </Stack>
              </Group>
            </Grid.Col>
          )}

          <Grid.Col span={12}>
            <Title order={3}>Deputy Governor</Title>
          </Grid.Col>

          {foundAboutUs &&
          foundAboutUs.leaders &&
          foundAboutUs.leaders.length > 0 ? (
            <Grid.Col sm={12} md={12}>
              <Group grow>
                <Avatar
                  src={getLeaderAvi(foundAboutUs.leaders, "deputy_governor")}
                  size={220}
                />
                <Stack>
                  <TextInput
                    withAsterisk
                    label="Deputy Governor Name"
                    placeholder="Deputy Governor Name"
                    {...form.getInputProps("deputy_governor_name")}
                  />
                  <TextInput
                    label="Deputy Governor's Social Media"
                    placeholder="Deputy Governor's Social Media"
                    {...form.getInputProps("deputy_governor_social")}
                  />
                  <FileInput
                    label="Deputy Governor's Image"
                    placeholder="Deputy Governor's Image"
                    icon={<IconUpload size={rem(14)} />}
                    value={deputyGovernorImage}
                    accept="image/png,image/jpeg"
                    onChange={setDeputyGovernorImage}
                  />
                </Stack>
              </Group>
            </Grid.Col>
          ) : (
            <Grid.Col sm={12} md={12}>
              <Group grow>
                <Avatar size={220} />
                <Stack>
                  <TextInput
                    withAsterisk
                    label="Deputy Governor Name"
                    placeholder="Deputy Governor Name"
                    {...form.getInputProps("deputy_governor_name")}
                  />
                  <TextInput
                    label="Deputy Governor's Social Media"
                    placeholder="Deputy Governor's Social Media"
                    {...form.getInputProps("deputy_governor_social")}
                  />
                  <FileInput
                    label="Deputy Governor's Image"
                    placeholder="Deputy Governor's Image"
                    icon={<IconUpload size={rem(14)} />}
                    value={deputyGovernorImage}
                    accept="image/png,image/jpeg"
                    onChange={setDeputyGovernorImage}
                  />
                </Stack>
              </Group>
            </Grid.Col>
          )}

          <Grid.Col span={12}>
            <Title order={3}>Senator</Title>
          </Grid.Col>

          {foundAboutUs &&
          foundAboutUs.leaders &&
          foundAboutUs.leaders.length > 0 ? (
            <Grid.Col sm={12} md={12}>
              <Group grow>
                <Avatar
                  src={getLeaderAvi(foundAboutUs.leaders, "senator")}
                  size={220}
                />
                <Stack>
                  <TextInput
                    withAsterisk
                    label="Senator Name"
                    placeholder="Senator Name"
                    {...form.getInputProps("senator_name")}
                  />
                  <TextInput
                    label="Senator's Social Media"
                    placeholder="Senator's Social Media"
                    {...form.getInputProps("senator_social")}
                  />

                  <FileInput
                    label="Senator's Image"
                    placeholder="Senator's Image"
                    icon={<IconUpload size={rem(14)} />}
                    value={senatorImage}
                    accept="image/png,image/jpeg"
                    onChange={setSenatorImage}
                  />
                </Stack>
              </Group>
            </Grid.Col>
          ) : (
            <Grid.Col sm={12} md={12}>
              <Group grow>
                <Avatar size={220} />
                <Stack>
                  <TextInput
                    withAsterisk
                    label="Senator Name"
                    placeholder="Senator Name"
                    {...form.getInputProps("senator_name")}
                  />
                  <TextInput
                    label="Senator's Social Media"
                    placeholder="Senator's Social Media"
                    {...form.getInputProps("senator_social")}
                  />

                  <FileInput
                    label="Senator's Image"
                    placeholder="Senator's Image"
                    icon={<IconUpload size={rem(14)} />}
                    value={senatorImage}
                    accept="image/png,image/jpeg"
                    onChange={setSenatorImage}
                  />
                </Stack>
              </Group>
            </Grid.Col>
          )}

          <Grid.Col span={12}>
            <Title order={3}>Women Rep</Title>
          </Grid.Col>

          {foundAboutUs &&
          foundAboutUs.leaders &&
          foundAboutUs.leaders.length > 0 ? (
            <Grid.Col sm={12} md={12}>
              <Group grow>
                <Avatar
                  src={getLeaderAvi(foundAboutUs.leaders, "women_rep")}
                  size={220}
                />
                <Stack>
                  <TextInput
                    withAsterisk
                    label="Women Representative Name"
                    placeholder="Women Representative Name"
                    {...form.getInputProps("womenrep_name")}
                  />
                  <TextInput
                    label="Women Representative's Social Media"
                    placeholder="Women Representative's Social Media"
                    {...form.getInputProps("womenrep_social")}
                  />
                  <FileInput
                    label="Women Representative's Image"
                    placeholder="Women Representative's Image"
                    icon={<IconUpload size={rem(14)} />}
                    value={womenRepImage}
                    accept="image/png,image/jpeg"
                    onChange={setWomenRepImage}
                  />
                </Stack>
              </Group>
            </Grid.Col>
          ) : (
            <Grid.Col sm={12} md={12}>
              <Group grow>
                <Avatar size={220} />
                <Stack>
                  <TextInput
                    withAsterisk
                    label="Women Representative Name"
                    placeholder="Women Representative Name"
                    {...form.getInputProps("womenrep_name")}
                  />
                  <TextInput
                    label="Women Representative's Social Media"
                    placeholder="Women Representative's Social Media"
                    {...form.getInputProps("womenrep_social")}
                  />
                  <FileInput
                    label="Women Representative's Image"
                    placeholder="Women Representative's Image"
                    icon={<IconUpload size={rem(14)} />}
                    value={womenRepImage}
                    accept="image/png,image/jpeg"
                    onChange={setWomenRepImage}
                  />
                </Stack>
              </Group>
            </Grid.Col>
          )}

          <Grid.Col span={12}>
            <Group position="right" spacing="xl">
              <Button
                leftIcon={<IconPlus size="1rem" />}
                type="submit"
                variant="filled"
                loading={mutateLeaders.isLoading || updateLeaders.isLoading}
              >
                Submit
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Drawer>
  );
};

export default ModifyCountyLeadership;
