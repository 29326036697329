import { useForm } from "@mantine/form";
import {
  TextInput,
  Grid,
  Button,
  Drawer,
  Select,
  Textarea,
  Flex,
  SelectItem,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";

import {
  BOUNDARY_KEY,
  DEPARTMENTS_KEY,
  IBoundaryApiResponse,
  IDepartmentsApiResponse,
  IProject,
  IProjectMutate,
  MUTATE_PROJECTS_KEY,
  PROJECTS_KEY,
} from "../utils/types";
import { IconCheck, IconMapPlus, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuthContext } from "../store/AuthContext";
import { INewDataProps } from "./NewSector";
import {
  generateYearRanges,
  getConstituenciesFromBoundary,
  getDepartments,
} from "../utils/fns";
import { useEffect, useState } from "react";
import useFetchData from "../hooks/useFetchBudgets";

export interface IViewProjectProps extends INewDataProps {
  foundProject: IProject | undefined;
}

const ViewProject = ({
  closeDrawer,
  drawerOpened,
  foundProject,
}: IViewProjectProps) => {
  const { token } = useAuthContext();
  const [wards, setWards] = useState<readonly (string | SelectItem)[]>([]);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (updatedProject: IProjectMutate) => {
      await axiosInstance.post(MUTATE_PROJECTS_KEY, updatedProject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const form = useForm({
    initialValues: {
      name: "",
      amount: "",
      department_id: "",
      period: "",
      constituency_id: "",
      ward_id: "",
      status: "",
      status_description: "",
      contractor: "",
      activities: "",
      feedback: "",
      payment_status: "",
      latitude: "",
      longitude: "",
      description: "",
      impact: "",
      objective: "",
    },
    validate: {
      name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      constituency_id: (value) =>
        value.length === 0 ? "Choose the constituency" : null,
      amount: (value) => (value.length < 4 ? "Provide a valid amoount" : null),
      status_description: (value) =>
        value.length < 4 ? "Provide a valid status description" : null,
      impact: (value) => (value.length < 4 ? "Fill in the impact field" : null),
    },
  });

  useEffect(() => {
    if (foundProject) {
      form.setValues({
        activities: foundProject.activities ? foundProject.activities : "",
        amount: foundProject.amount ? foundProject.amount.toString() : "",
        constituency_id: foundProject.constituency_id
          ? foundProject.constituency_id.toString()
          : "",
        contractor: foundProject.contractor ? foundProject.contractor : "",
        department_id: foundProject.department_id
          ? foundProject.department_id.toString()
          : "",
        description: foundProject.description ? foundProject.description : "",
        feedback: foundProject.feedback ? foundProject.feedback : "",
        impact: foundProject.impact ? foundProject.impact : "",
        latitude:
          foundProject.geo && foundProject.geo.split(",")[0]
            ? foundProject.geo.split(",")[0]
            : "",
        longitude:
          foundProject.geo && foundProject.geo.split(",")[1]
            ? foundProject.geo.split(",")[1]
            : "",
        name: foundProject.name ? foundProject.name : "",
        objective: foundProject.objective ? foundProject.objective : "",
        payment_status: foundProject.payment_status
          ? foundProject.payment_status
          : "",
        period: foundProject.year ? foundProject.year : "",
        status: foundProject.status ? foundProject.status : "",
        status_description: foundProject.status_description
          ? foundProject.status_description
          : "",
        ward_id: foundProject.ward_id ? foundProject.ward_id.toString() : "",
      });
    }
  }, [foundProject]);

  const {
    fetchedData: fetchedConstituencies,
    //isError,
    // isLoading,
    // isFetching: isFetchingConstituencies,
  } = useFetchData<IBoundaryApiResponse>(BOUNDARY_KEY, token ? token : "");

  useEffect(() => {
    const foundConstituency = getConstituenciesFromBoundary(
      fetchedConstituencies
    ).find(
      (constituency) => constituency.id === Number(form.values.constituency_id)
    );
    if (foundConstituency) {
      const filteredWards: SelectItem[] = foundConstituency.wards.map(
        (ward) => ({ value: ward.id.toString(), label: ward.name })
      );
      setWards(filteredWards);
    }
  }, [fetchedConstituencies, form.values.constituency_id]);

  const {
    fetchedData: fetchedDepartments,
    //isError: isLoadingDepartmentsError,
    // isFetching: isFetchingDepartments,
    isLoading: isLoadingDepartments,
  } = useFetchData<IDepartmentsApiResponse>(
    DEPARTMENTS_KEY,
    token ? token : ""
  );

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="xl"
      onClose={closeDrawer}
      title="Modify Project"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            if (foundProject) {
              await mutation.mutate(
                {
                  ...values,
                  amount: Number(values.amount),
                  geo: `${values.latitude}, ${values.longitude}`,
                  department_id: Number(values.department_id),
                  year: values.period,
                  constituency_id: Number(values.constituency_id),
                  ward_id: Number(values.ward_id),
                  id: foundProject.id,
                },
                {
                  onSuccess: () => {
                    notifications.show({
                      title: "Success",
                      message: "Project Added",
                      icon: <IconCheck />,
                      color: "teal",
                    });
                    closeDrawer();
                  },
                  onSettled(data, error, variables, context) {
                    queryClient.invalidateQueries([PROJECTS_KEY]);
                  },
                  onError: (
                    error: any,
                    variables: IProjectMutate,
                    context: unknown
                  ) => {
                    notifications.show({
                      title: "Error",
                      message:
                        error && error.data
                          ? error.data
                          : "An error occured, try again",
                      icon: <IconX />,
                      color: "red",
                    });
                  },
                }
              );
            }
          } catch (error) {}
        })}
      >
        <Grid>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              mt="md"
              withAsterisk
              label="Project Name"
              placeholder="Project Name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              mt="md"
              withAsterisk
              type="number"
              min={0}
              label="Amount in KES"
              placeholder="Amount in KES"
              {...form.getInputProps("amount")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <Select
              label="Department"
              placeholder="Department"
              {...form.getInputProps("department_id")}
              searchable
              disabled={isLoadingDepartments}
              data={getDepartments(fetchedDepartments).map((d) => ({
                value: d.id.toString(),
                label: d.name,
                name: d.name,
              }))}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <Select
              label="Period"
              placeholder="Period"
              {...form.getInputProps("period")}
              data={generateYearRanges(2013).map((year) => ({
                value: year,
                label: year,
              }))}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <Select
              label="Sub County"
              withAsterisk
              searchable
              placeholder="Sub-County"
              {...form.getInputProps("constituency_id")}
              data={getConstituenciesFromBoundary(fetchedConstituencies).map(
                (d) => ({
                  value: d.id.toString(),
                  label: d.name,
                  name: d.name,
                })
              )}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <Select
              label="Ward"
              withAsterisk
              placeholder="Ward"
              {...form.getInputProps("ward_id")}
              searchable
              data={wards}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <Select
              label="Project Status"
              placeholder="Project Status"
              withAsterisk
              {...form.getInputProps("status")}
              data={[
                { value: "incomplete", label: "Incomplete" },
                { value: "complete", label: "Complete" },
                { value: "not-started", label: "Not Started" },
                { value: "tendering", label: "Tendering Stage" },
              ]}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              label="Status Description"
              placeholder="Status Description"
              {...form.getInputProps("status_description")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              label="Contractor"
              placeholder="Contractor"
              {...form.getInputProps("contractor")}
            />
          </Grid.Col>

          <Grid.Col sm={12} md={6} lg={6}>
            <Select
              label="Payment Status"
              placeholder="Payment Status"
              withAsterisk
              {...form.getInputProps("payment_status")}
              data={[
                { value: "fully-paid", label: "Fully Paid" },
                { value: "not-paid", label: "Not Paid" },
                { value: "partially-paid", label: "Partially Paid" },
              ]}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              mt="md"
              label="Latitude"
              placeholder="Latitude"
              {...form.getInputProps("latitude")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              mt="md"
              label="Longitude"
              placeholder="Longitude"
              {...form.getInputProps("longitude")}
            />
          </Grid.Col>

          {/* <Grid.Col sm={12} md={6} lg={6}>
            <Textarea
              placeholder="Provide the project description"
              label="Description"
              {...form.getInputProps("description")}
              autosize
              minRows={3}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <Textarea
              placeholder="Provide the project objective"
              label="Objective"
              autosize
              {...form.getInputProps("objective")}
              minRows={3}
            />
          </Grid.Col> */}

          <Grid.Col sm={12} md={6} lg={6}>
            <Textarea
              placeholder="Project Activities"
              label="Activities"
              autosize
              {...form.getInputProps("activities")}
              minRows={3}
            />
          </Grid.Col>

          <Grid.Col sm={12} md={6} lg={6}>
            <Textarea
              placeholder="Provide Feedback"
              label="Feedback"
              autosize
              {...form.getInputProps("feedback")}
              minRows={3}
            />
          </Grid.Col>

          <Grid.Col sm={12} md={12} lg={12}>
            <Flex
              gap="md"
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                leftIcon={<IconMapPlus size="1rem" />}
                type="submit"
                variant="filled"
                loading={mutation.isLoading}
              >
                Submit
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </form>{" "}
    </Drawer>
  );
};

export default ViewProject;
