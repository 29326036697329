import { ReactNode } from "react";
import { useAuthContext } from "../store/AuthContext";
import { Navigate } from "react-router-dom";
import { DASHBOARD_PATH } from "../utils/config";

const GuestGuard = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn } = useAuthContext();

  if (isLoggedIn) {
    return <Navigate to={DASHBOARD_PATH} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
