import { ReactNode } from "react";
import GuestGuard from "../guards/GuestGuard";

const GuestDashboard = ({
  children,
  heading,
}: {
  children: ReactNode;
  heading?: string;
}) => {
  // const { pathname } = useLocation();
  return (
    <GuestGuard>
      <div className="flex flex-col min-h-screen">
        <main className="flex-1"> {children}</main>
        {/* 
        <AppFooter /> */}
      </div>
    </GuestGuard>
  );
};

export default GuestDashboard;
