import { useDisclosure } from "@mantine/hooks";
import { useAuthContext } from "../store/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import {
  IIndicator,
  INDICATORS_KEY,
  MUTATE_INDICATORS_KEY,
} from "../utils/types";
import useFetchCrudIndicators from "../hooks/useFetchCrudIndicator";
import { ActionIcon, Button, Grid, Text, Title, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { IconCheck } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import NewIndicator from "../sections/NewIndicator";
import { PRIMARY_COLOR } from "../utils/config";
import ViewIndicator from "../sections/ViewIndicator";

const Indicators = () => {
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [
    updateDrawerOpened,
    { open: openUpdateDrawer, close: closeUpdateDrawer },
  ] = useDisclosure(false);
  const [foundIndicator, setFoundIndicator] = useState<IIndicator | undefined>(
    undefined
  );
  const { token } = useAuthContext();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await axiosInstance.delete(`${MUTATE_INDICATORS_KEY}?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const {
    isError: isLoadingIndicatorsError,
    // isFetching: isFetchingIndicators,
    isLoading: isLoadingIndicators,
    fetchedIndicators,
  } = useFetchCrudIndicators(INDICATORS_KEY, token ? token : "");

  const openDeleteConfirmModal = (row: MRT_Row<IIndicator>) =>
    modals.openConfirmModal({
      title: `Delete ${row.original.name}`,
      children: (
        <Text>
          Are you sure you want to delete this indicator? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await deleteMutation.mutate(row.id, {
            onSuccess: () => {
              notifications.show({
                title: "Success",
                message: "Indicator deleted",
                icon: <IconCheck />,
                color: "teal",
              });
            },
            onError(error: any, variables, context) {
              notifications.show({
                title: "Error",
                message:
                  error && error.data
                    ? error.data
                    : "An error occured, try again",
                icon: <IconX />,
                color: "red",
              });
            },
            onSettled(data, error, variables, context) {
              queryClient.invalidateQueries([INDICATORS_KEY]);
            },
          });
        } catch (error) {}
      },
    });

  const columns = useMemo<MRT_ColumnDef<IIndicator>[]>(
    () => [
      {
        accessorKey: "index",
        header: "Index",
        enableEditing: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        enableEditing: false,
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 200,
        enableEditing: false,
      },
      {
        accessorKey: "sector.name",
        header: "Sector",
        enableEditing: false,
        Cell: ({ row }) => {
          return <Text size="sm">{row.original.sector.name}</Text>;
        },
      },
      {
        accessorKey: "source",
        header: "Source",
        enableEditing: false,
      },
      // {
      //   accessorKey: "created_at",
      //   header: "Date Created",
      //   enableEditing: false,
      //   Cell: ({ cell }) => {
      //     return (
      //       <Text size="sm">
      //         {new Date(cell.row.original.created_at).toDateString()}
      //       </Text>
      //     );
      //   },
      // },
    ],
    []
  );

  const handleUpdateIndicator = (row: MRT_Row<IIndicator>) => {
    setFoundIndicator(row.original);
    openUpdateDrawer();
  };

  const table = useMantineReactTable({
    columns,
    data:
      fetchedIndicators && Array.isArray(fetchedIndicators.data)
        ? fetchedIndicators.data
        : [],
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "cell", // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    positionActionsColumn: "last",
    getRowId: (row) => (row.id ? row.id.toString() : ""),
    initialState: {
      density: "xs", //set default density to compact
      sorting: [
        {
          id: "index",
          desc: false,
        },
      ],
    },
    mantineToolbarAlertBannerProps: isLoadingIndicatorsError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row }) => (
      <div className="flex flex-row">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => handleUpdateIndicator(row)}>
            <IconEdit color={PRIMARY_COLOR} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </div>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="p-2">
        <Button variant="filled" size="sm" onClick={openDrawer}>
          Add Indicator
        </Button>
      </div>
    ),
    state: {
      isLoading: isLoadingIndicators,
      isSaving: false,
      showAlertBanner: isLoadingIndicatorsError,
      showProgressBars: false,
    },
  });

  return (
    <Grid>
      <Grid.Col span={12}>
        <Title className="styled-heading styled-heading-large">
          Indicators
        </Title>
      </Grid.Col>
      <Grid.Col span={12}>
        <MantineReactTable table={table} />
      </Grid.Col>
      <Grid.Col span={12}>
        <NewIndicator closeDrawer={closeDrawer} drawerOpened={drawerOpened} />
      </Grid.Col>
      <Grid.Col span={12}>
        <ViewIndicator
          closeDrawer={closeUpdateDrawer}
          drawerOpened={updateDrawerOpened}
          foundIndicator={foundIndicator}
        />
      </Grid.Col>
    </Grid>
  );
};

export default Indicators;
