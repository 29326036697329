import { useForm } from "@mantine/form";
import { TextInput, Button, Grid, Drawer, Group } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import { INewUser, IUser, USERS_KEY } from "../utils/types";
import { IconCheck, IconX, IconPlus } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { PRIMARY_COLOR } from "../utils/config";
import { useAuthContext } from "../store/AuthContext";
import { INewDataProps } from "./NewSector";
import { useEffect } from "react";

export interface IUpdateUserProps extends INewDataProps {
  foundUser: IUser | undefined;
}

const ViewUser = ({
  closeDrawer,
  drawerOpened,
  foundUser,
}: IUpdateUserProps) => {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updatedUser: IUser) => {
      await axiosInstance.post(`/cms${USERS_KEY}`, updatedUser, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
    },
    validate: {
      name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "provide a valid email",
    },
  });

  useEffect(() => {
    if (foundUser) {
      form.setValues({
        name: foundUser.name ? foundUser.name : "",
        email: foundUser.email ? foundUser.email : "",
      });
    }
  }, [foundUser]);

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="xl"
      onClose={closeDrawer}
      title="Modify User"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            if (foundUser) {
              await mutation.mutate(
                {
                  ...values,
                  created_at: foundUser.created_at,
                  email_verified_at: foundUser.email_verified_at,
                  id: foundUser.id,
                  updated_at: foundUser.updated_at,
                },
                {
                  onSuccess: () => {
                    form.reset();
                    closeDrawer();
                    notifications.show({
                      title: "Success",
                      message: "User updated",
                      icon: <IconCheck />,
                      color: PRIMARY_COLOR,
                    });
                  },
                  onSettled(data, error, variables, context) {
                    queryClient.invalidateQueries([`/cms${USERS_KEY}`]);
                  },
                  onError: (
                    error: any,
                    variables: INewUser,
                    context: unknown
                  ) => {
                    notifications.show({
                      title: "Error",
                      message:
                        error && error.data
                          ? error.data
                          : "An error occured, try again",
                      icon: <IconX />,
                      color: "red",
                    });
                  },
                }
              );
            }
          } catch (error) {}
        })}
      >
        <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              withAsterisk
              label="Email"
              placeholder="email"
              {...form.getInputProps("email")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={12} lg={12}>
            <Group position="right">
              <Button
                type="submit"
                leftIcon={<IconPlus />}
                loading={mutation.isLoading}
              >
                Submit
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Drawer>
  );
};

export default ViewUser;
