import { useQuery } from "@tanstack/react-query";
import { fetchApiData } from "../utils/fns";

const useFetchIndicatorData = <T>(
  url: string,
  token: string,
  enabled: boolean
) => {
  const {
    isFetching,
    isLoading,
    data: fetchedData,
    isError,
  } = useQuery([url], {
    queryFn: async () => fetchApiData<T>(`${url}`, token),
    enabled,
  });

  return { isFetching, isLoading, fetchedData, isError };
};

export default useFetchIndicatorData;
