import { Paper, Text, Title, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import classes from "./TopItemBg.module.css";
import { SETTINGS } from "../utils/config";

interface CardProps {
  image: string;
  title: string;
  category: string;
}

const TopItemCard = ({ image, title, category }: CardProps) => {
  const navigate = useNavigate();
  return (
    <Paper
      shadow="md"
      p="xl"
      radius="md"
      style={{ backgroundImage: `url(${image})` }}
      className={classes.card}
    >
      <div>
        <Text className={classes.category} size="xs">
          {category}
        </Text>
        <Title order={5} className={classes.title}>
          {title}
        </Title>
      </div>
      <Button
        variant="white"
        color="dark"
        onClick={() => navigate(SETTINGS.about.link)}
      >
        View County Information
      </Button>
    </Paper>
  );
};

export default TopItemCard;
