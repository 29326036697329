import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

// import { AppNavbar } from "@/app/components/Navbar";
import { BreadCrumb } from "../components/BreadCrumb";
import { AdminHeader } from "./MobileNav";
import AuthGuard from "../guards/AuthGuard";

const AdminDashboard = ({
  children,
  heading,
}: {
  children: ReactNode;
  heading?: string;
}) => {
  const { pathname } = useLocation();
  return (
    <AuthGuard>
      <div className="flex flex-col min-h-screen">
        <main className="flex-1">
          <AdminHeader />
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <BreadCrumb pathname={pathname.split("/")[1]} path={pathname} />
            <h3 className="capitalize mb-4">
              {heading || pathname.split("/")[2]}
            </h3>
            {children}
          </div>
        </main>
        {/* <AppFooter /> */}
      </div>
    </AuthGuard>
  );
};

export default AdminDashboard;
