import axios, { AxiosResponse } from "axios";
import axiosInstance from "./axios";
import { utils, read, writeFile } from "xlsx";
import {
  IBoundaryApiResponse,
  IConstituencyApiResponse,
  ICoordinates,
  IDepartmentsApiResponse,
  IDocApiResponse,
  IExpenditure,
  IExpenditureApiResponse,
  IExtraSelectValues,
  IHealth,
  IHealthData,
  IHealthDataApiResponse,
  IHealthDataCountiesApiResponse,
  IHealthGeo,
  IHealthGeoApiResponse,
  IIndicatorTemplate,
  IIndicatorsApiResponse,
  IProjectsApiResponse,
  IRevenue,
  IRevenueApiResponse,
  IRevenueStream,
  IRevenueStreamApiResponse,
  ISectorsApiData,
  IWardApiResponse,
} from "./types";
import { SelectItem } from "@mantine/core";
import { LatLngExpression } from "leaflet";

const SABASI_BASE_URL = "https://api.sabasi.mobi/api/nandi";

export const fetchApiData = async <T>(
  url: string,
  token: string
): Promise<T> => {
  const { data }: AxiosResponse<T> = await axiosInstance.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const fetchSabasiData = async <T>(
  url: string,
  token: string
): Promise<T> => {
  const { data }: AxiosResponse<T> = await axios.get(
    `${SABASI_BASE_URL}${url}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getProjects = (
  fetchedProjects: IProjectsApiResponse | undefined
) =>
  fetchedProjects && Array.isArray(fetchedProjects.data)
    ? fetchedProjects.data
    : [];
export const getSectors = (fetchedSectors: ISectorsApiData | undefined) =>
  fetchedSectors && Array.isArray(fetchedSectors.data)
    ? fetchedSectors.data
    : [];

export const getIndicators = (
  fetchedIndicators: IIndicatorsApiResponse | undefined
) =>
  fetchedIndicators && Array.isArray(fetchedIndicators.data)
    ? fetchedIndicators.data
    : [];

export const getConstituencies = (
  fetchedConstituencies: IConstituencyApiResponse | undefined
) =>
  fetchedConstituencies && Array.isArray(fetchedConstituencies.data)
    ? fetchedConstituencies.data
    : [];
export const getConstituenciesFromBoundary = (
  fetchedConstituencies: IBoundaryApiResponse | undefined
) =>
  fetchedConstituencies && Array.isArray(fetchedConstituencies.data)
    ? fetchedConstituencies.data
    : [];

export const getDocs = (fetchedDocs: IDocApiResponse | undefined) =>
  fetchedDocs && Array.isArray(fetchedDocs.data) ? fetchedDocs.data : [];

export const getHealthData = (
  fetchedHealthData: IHealthDataApiResponse | undefined
): IHealthData => {
  if (fetchedHealthData && fetchedHealthData.data) {
    return fetchedHealthData.data;
  }
  return {
    beds: "",
    cadres: "",
    created_at: "",
    deleted_at: "",
    departments: "",
    email: "",
    facility: "",
    gps: "",
    id: 0,
    infrastructure: "",
    nature: "",
    open_hours: "",
    owner: "",
    phone: "",
    population: "",
    respondent: "",
    respondent_position: "",
    sector: "",
    status: "",
    type: "",
    updated_at: "",
    utilities: "",
    ward: "",
  };
};

export const getRevenue = (
  fetchedBudgets: IRevenueStreamApiResponse | undefined
): IRevenueStream[] =>
  fetchedBudgets && fetchedBudgets.data && Array.isArray(fetchedBudgets.data)
    ? fetchedBudgets.data
    : [];

export const getHealthCoordinates = (
  fetchedCoordinates: IHealthGeoApiResponse | undefined
): IHealthGeo[] =>
  fetchedCoordinates &&
  fetchedCoordinates.data &&
  Array.isArray(fetchedCoordinates.data)
    ? fetchedCoordinates.data
    : [];

export const getExpenditureData = (
  fetchedExpenditure: IExpenditureApiResponse | undefined
): IExpenditure[] =>
  fetchedExpenditure &&
  fetchedExpenditure.data &&
  Array.isArray(fetchedExpenditure.data)
    ? fetchedExpenditure.data
    : [];

export const getHealthDataArry = (
  fetchedHealthData: IHealthDataCountiesApiResponse | undefined
): IHealthData[] =>
  fetchedHealthData &&
  fetchedHealthData.data &&
  Array.isArray(fetchedHealthData.data.data)
    ? fetchedHealthData.data.data
    : [];

export const getRevenueData = (
  fetchedRevenue: IRevenueApiResponse | undefined
): IRevenue[] =>
  fetchedRevenue && fetchedRevenue.data && Array.isArray(fetchedRevenue.data)
    ? fetchedRevenue.data
    : [];

export const getWards = (fetchedWards: IWardApiResponse | undefined) =>
  fetchedWards && Array.isArray(fetchedWards.data) ? fetchedWards.data : [];
export const getDepartments = (
  fetchedDepartments: IDepartmentsApiResponse | undefined
) =>
  fetchedDepartments && Array.isArray(fetchedDepartments.data)
    ? fetchedDepartments.data
    : [];

export const getInitials = (name: string): string => {
  const words = name.split(" ");
  const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");
  return initials;
};

export const generateYearRanges = (startYear: number): string[] => {
  const currentYear: number = new Date().getFullYear();
  //const startYear: number = 2017; // Change this to your desired start year
  const ranges: string[] = [];

  for (let year: number = startYear; year <= currentYear; year++) {
    const nextYear: number = year + 1;
    const yearRange: string = `${year}-${nextYear}`;
    ranges.push(yearRange);
  }

  return ranges;
};

export const generateYears = (startYear: number): string[] => {
  const currentYear: number = new Date().getFullYear();
  //const startYear: number = 2017; // Change this to your desired start year
  const ranges: string[] = [];

  for (let year: number = startYear; year <= currentYear; year++) {
    ranges.push(year.toString());
  }

  return ranges;
};

export const findMatchingLatLng = (
  a: IHealth[],
  b: ICoordinates
): IHealth | undefined => {
  return a.find((item) => {
    return (
      item.coordinates.latitude === b.latitude &&
      item.coordinates.longitude === b.longitude
    );
  });
};

export function formatFileSize(bytes: number): string {
  if (bytes >= 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    return (bytes / 1024).toFixed(1) + " kB";
  } else {
    return bytes + " bytes";
  }
}

// -----------------

export function readExcelFile(file: File): Promise<any[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      resolve(jsonData);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export const downloadCSV = (
  jsonData: IIndicatorTemplate[],
  fileName = `indicator-data-${new Date().toISOString()}`
) => {
  // Create a new workbook (this is required for the library to work)
  const wb = utils.book_new();

  // Convert JSON to worksheet
  const ws = utils.json_to_sheet(jsonData);

  // Add the worksheet to the workbook
  utils.book_append_sheet(wb, ws, "Sheet1");

  // Generate a CSV file from the workbook
  writeFile(wb, `${fileName}.csv`, { bookType: "csv" });
};

export function toSnakeCase(text: string): string {
  return text.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
}

function keysToSnakeCase(obj: Record<string, any>): Record<string, any> {
  const snakeCaseObj: Record<string, any> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const snakeCaseKey = key.replace(/ /g, "_").toLowerCase();
      snakeCaseObj[snakeCaseKey] = obj[key];
    }
  }

  return snakeCaseObj;
}

export function arrayKeysToSnakeCase(
  data: Record<string, any>[]
): Record<string, any>[] {
  return data.map((item) => keysToSnakeCase(item));
}

export function excelDataToJson(excelData: any[][]): any[] {
  const [headers, ...rows] = excelData; // Separate the headers and data rows
  const jsonData = rows
    .filter((row) =>
      row.some((cell) => cell !== null && cell !== undefined && cell !== "")
    )
    .map((row) => {
      const obj: Record<string, any> = {}; // Provide type annotation for obj
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });
  return jsonData;
}

export const transformResponseToJSON = (response: any[]): any[] => {
  const keys = response[0];
  const jsonData = [];

  for (let i = 1; i < response.length; i++) {
    const obj: any = { id: i - 1 }; // Subtracting 1 to start id from 0
    for (let j = 0; j < keys.length; j++) {
      obj[keys[j]] = response[i][j] || null;
    }
    jsonData.push(obj);
  }
  return jsonData;
};

export const validateRequired = (value: string) => !!value?.length;
export const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const formatColumnName = (columnName: string): string => {
  // Split the column name by spaces and underscores
  const words = columnName.split(/[\s_]+/);

  // Capitalize each word and join them with a space
  const formattedName = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedName;
};

export const getIndidicatorFromIndex = (
  indicators: IExtraSelectValues[],
  idx: string | null
): IExtraSelectValues | string => {
  const foundInd = indicators.filter((indicator) => indicator.value === idx)[0];
  if (!foundInd) return "";
  return foundInd;
};

export const getIndicatorLabelFromIndex = (
  indicators: IExtraSelectValues[],
  idx: string | null
): string => {
  const foundInd = indicators.filter((indicator) => indicator.value === idx)[0];
  if (!foundInd) return "";
  return foundInd.label;
};

export const resolveName = (
  categories: SelectItem[],
  selectedItem: string
): number => {
  const itemFound = categories.filter((cat) => cat.label === selectedItem);
  if (itemFound.length === 1) {
    return Number(itemFound[0].value);
  } else {
    return 0;
  }
};

export const getCoords = (geo?: string): LatLngExpression => {
  if (!geo) {
    return [0, 0];
  }

  const splatted = geo.replace(/\s/g, "").split(",");

  if (splatted.length === 2) {
    const itm = splatted.map((d) => Number(d));
    return [itm[0], itm[1]];
  } else {
    return [0, 0];
  }
};

export const getUtilities = (utils: string): string[] =>
  utils.split(",").map((util) => util.trim());
