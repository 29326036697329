import { Grid, Title } from "@mantine/core";
import DashboardStatsCard, {
  IDashboardStatsCard,
} from "../components/DashboardStatsCard";
import {
  DEPARTMENTS_KEY,
  DOCUMENTS_KEY,
  IDepartmentsApiResponse,
  IDocApiResponse,
  IIndicatorsApiResponse,
  INDICATORS_KEY,
  IProjectsApiResponse,
  ISectorsApiData,
  PROJECTS_KEY,
  SECTORS_KEY,
} from "../utils/types";
import { useAuthContext } from "../store/AuthContext";
import {
  getDepartments,
  getDocs,
  getIndicators,
  getProjects,
  getSectors,
} from "../utils/fns";
import {
  IconBuildingCommunity,
  IconChartBar,
  IconFileCheck,
  IconFileUpload,
  IconListCheck,
  IconListDetails,
  IconUsersGroup,
} from "@tabler/icons-react";
import DashboardProjectsTable from "../components/DashboardProjectsTable";
import TopItemCard from "../components/TopItem";
import { BUDGETS, MEDIA, SECTORS, SETTINGS } from "../utils/config";
import useFetchData from "../hooks/useFetchBudgets";
import MidDashboardItem from "../components/MidDashboardItem";

const midItems: IDashboardStatsCard[] = [
  {
    count: "Revenue Streams",
    label: "Update Revenue for a specific fiscal period",
    icon: <IconListCheck color="green" size={48} />,
    to: BUDGETS.home.link,
  },
  {
    count: "Expenditure Data",
    label: "Update Expenditure for a specific fiscal period",
    icon: <IconListDetails color="green" size={48} />,
    to: BUDGETS.home.link,
  },
  {
    count: "Documents",
    label: "Upload documents published by the county",
    icon: <IconFileCheck color="green" size={48} />,
    to: MEDIA.link,
  },
  {
    count: "Indicators",
    label: "Upload data for a specific indicator",
    icon: <IconFileUpload color="green" size={48} />,
    to: SECTORS.indicators.add,
  },
];

const Dashboard = () => {
  const { token } = useAuthContext();
  const {
    fetchedData: fetchedProjects,
    isError: isLoadingProjectsError,
    // isFetching: isFetchingDepartments,
    isLoading: isLoadingProjects,
  } = useFetchData<IProjectsApiResponse>(PROJECTS_KEY, token ? token : "");

  const { fetchedData: fetchedSectors } = useFetchData<ISectorsApiData>(
    SECTORS_KEY,
    token ? token : ""
  );

  const { fetchedData: fetchedIndicators } =
    useFetchData<IIndicatorsApiResponse>(INDICATORS_KEY, token ? token : "");

  const { fetchedData: fetchedDocs } = useFetchData<IDocApiResponse>(
    DOCUMENTS_KEY,
    token ? token : ""
  );

  return (
    <Grid>
      <Grid.Col span={12}>
        <Title className="styled-heading styled-heading-small">Dashboard</Title>
      </Grid.Col>
      <Grid.Col sm={12} md={3}>
        <TopItemCard
          category=""
          image="/harvest-tea.jpg"
          title="The County Goverment of Nandi CMS"
        />
      </Grid.Col>
      <Grid.Col sm={12} md={9}>
        <Grid gutter="lg">
          <Grid.Col sm={12} md={6}>
            <DashboardStatsCard
              count={getProjects(fetchedProjects).length}
              label="Projects"
              icon={<IconBuildingCommunity color="green" size={48} />}
              to={SETTINGS.departments.link}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <DashboardStatsCard
              count={getSectors(fetchedSectors).length}
              label="Sectors"
              icon={<IconChartBar color="green" size={48} />}
              to={SECTORS.sectors.link}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <DashboardStatsCard
              count={getIndicators(fetchedIndicators).length}
              label="Indicators"
              icon={<IconListDetails color="green" size={48} />}
              to={SECTORS.indicators.link}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <DashboardStatsCard
              count={getDocs(fetchedDocs).length}
              label="Documents"
              icon={<IconFileCheck color="green" size={48} />}
              to={MEDIA.link}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={12}>
        <Title className="styled-heading styled-heading-small">
          Data & Uploads
        </Title>
      </Grid.Col>
      {midItems.map((midItem) => (
        <Grid.Col key={midItem.count} sm={12} md={6}>
          <MidDashboardItem items={midItem} />
        </Grid.Col>
      ))}
      <Grid.Col span={12}>
        <Title className="styled-heading styled-heading-small">Projects</Title>
      </Grid.Col>
      <Grid.Col span={12}>
        <DashboardProjectsTable
          isLoadingProjects={isLoadingProjects}
          isLoadingProjectsError={isLoadingProjectsError}
          projects={getProjects(fetchedProjects)}
        />
      </Grid.Col>
    </Grid>
  );
};

export default Dashboard;
