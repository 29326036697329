import { useForm } from "@mantine/form";
import { TextInput, Grid, Button, Group, Drawer, Select } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import {
  SECTORS_KEY,
  INDICATORS_KEY,
  INewIndicator,
  MUTATE_INDICATORS_KEY,
  ISectorsApiData,
} from "../utils/types";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuthContext } from "../store/AuthContext";
import { INewDataProps } from "./NewSector";
import useFetchData from "../hooks/useFetchBudgets";

const NewIndicator = ({ closeDrawer, drawerOpened }: INewDataProps) => {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newWard: INewIndicator) => {
      await axiosInstance.post(MUTATE_INDICATORS_KEY, newWard, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const form = useForm({
    initialValues: {
      name: "",
      sector_id: "",
      index: "",
      description: "",
      source: "",
    },
    validate: {
      name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      sector_id: (value) => (value.length === 0 ? "Choose the sector" : null),
      index: (value) =>
        value.length === 0 ? "provide the indicator index" : null,
    },
  });

  const {
    fetchedData: fetchedSectors,
    //isError: isLoadingSectorsError,
    //isLoading: isLoadingSectors,
    isFetching: isFetchingSectors,
  } = useFetchData<ISectorsApiData>(SECTORS_KEY, token ? token : "");

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="xl"
      onClose={closeDrawer}
      title="Add Indicator"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            await mutation.mutate(
              {
                ...values,
                sector_id: Number(values.sector_id),
                method: "SUM",
              },
              {
                onSuccess: () => {
                  notifications.show({
                    title: "Success",
                    message: "Indicator Added",
                    icon: <IconCheck />,
                    color: "teal",
                  });
                  form.reset();
                  closeDrawer();
                },
                onSettled(data, error, variables, context) {
                  queryClient.invalidateQueries([INDICATORS_KEY, SECTORS_KEY]);
                },
                onError: (
                  error: any,
                  variables: INewIndicator,
                  context: unknown
                ) => {
                  notifications.show({
                    title: "Error",
                    message:
                      error && error.data
                        ? error.data
                        : "An error occured, try again",
                    icon: <IconX />,
                    color: "red",
                  });
                },
              }
            );
          } catch (error) {}
        })}
      >
        <Grid>
          <Grid.Col sm={12} md={12} lg={12}>
            <Select
              label="Sector"
              placeholder="Sector"
              disabled={isFetchingSectors}
              withAsterisk
              {...form.getInputProps("sector_id")}
              data={
                fetchedSectors && Array.isArray(fetchedSectors.data)
                  ? fetchedSectors.data.map((sector) => ({
                      value: sector.id.toString(),
                      label: sector.name,
                    }))
                  : []
              }
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              withAsterisk
              label="Indicator Index"
              placeholder="index"
              {...form.getInputProps("index")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>

          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              label="Description"
              placeholder="Description"
              {...form.getInputProps("description")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              label="Source"
              placeholder="Source"
              {...form.getInputProps("source")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group position="right" spacing="xl">
              <Button
                leftIcon={<IconPlus size="1rem" />}
                type="submit"
                variant="filled"
                loading={mutation.isLoading}
              >
                Submit
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Drawer>
  );
};

export default NewIndicator;
