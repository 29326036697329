import "leaflet/dist/leaflet.css";
import "react-leaflet-fullscreen/styles.css";
import {
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Center,
  Divider,
  Grid,
  Group,
  Menu,
  MultiSelect,
  Rating,
  SegmentedControl,
  Stack,
  Text,
  Title,
  Tooltip as MantineTooltip,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import {
  IconDashboard,
  IconDotsVertical,
  IconDownload,
  IconFileDownload,
  IconListCheck,
  IconMap,
} from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import SelectItem from "../components/SelectItem";
import { PRIMARY_COLOR, REPORTS_URL } from "../utils/config";
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  GeoJSON,
} from "react-leaflet";
import { icon } from "leaflet";
import {
  MRT_ColumnDef,
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import ItemKeyInsights from "../components/ItemKeyInsights";
import MapPopUp from "../components/MapPopUp";
import "leaflet.heat";
import QualitySelectItem from "../components/QualitySelectItem";

import geojson from "../utils/nandi-subcounties.geojson.json";
import HealthDetails, {
  IHealthDetails,
  getRating,
} from "../components/HealthDetails";
import MarkerClusterGroup from "react-leaflet-cluster";
import { FullscreenControl } from "react-leaflet-fullscreen";
import {
  IHealthData,
  IHealthDataCountiesApiResponse,
  IHealthGeoApiResponse,
  SABASI_DATA_KEY,
  SABASI_GEO_KEY,
} from "../utils/types";
import useFetchSabasiData from "../hooks/useFetchSabasiData";
import { useAuthContext } from "../store/AuthContext";
import {
  getCoords,
  getHealthCoordinates,
  getHealthDataArry,
} from "../utils/fns";
import { useDebouncedValue } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { IconChevronRight } from "@tabler/icons-react";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { fetchGuestToken } from "../utils/fetchGuestToken";
import { embedDashboard } from "@superset-ui/embedded-sdk";

const VIEWS = {
  overview: "overview",
  filters: "filters",
  download: "download",
};

const DATA_VIEWS = {
  map: "map",
  reports: "reports",
  data_table: "data table",
  live_data: "live data",
};

interface IDropDownFilters {
  name: string;
  description: string;
  value: string;
  label: string;
  count?: number;
}

const SUB_COUNTIES: IDropDownFilters[] = [
  {
    name: "Aldai",
    description: "44%",
    value: "Aldai",
    label: "Aldai",
  },
  {
    name: "Mosop",
    value: "Mosop",
    label: "Mosop",
    description: "20%",
  },
];

const AVAILABLE_UTILITIES: IDropDownFilters[] = [
  {
    name: "Water",
    description: "44%",
    value: "Water",
    label: "Water",
  },
  {
    name: "Electricity",
    value: "Electricity",
    label: "Electricity",
    description: "20%",
  },
  {
    name: "Internet",
    value: "Internet",
    label: "Internet",
    description: "20%",
  },
];

const OPERATION_HOURS: IDropDownFilters[] = [
  {
    name: "12 Hours",
    description: "44%",
    value: "12 Hours",
    label: "12 Hours",
  },
  {
    name: "24 Hours",
    value: "24 Hours",
    label: "24 Hours",
    description: "20%",
  },
];

const INFRA_QUALITY: IDropDownFilters[] = [
  {
    name: "Very Poor",
    description: "24%",
    value: "Very Poor",
    label: "Very Poor",
    count: 1,
  },
  {
    name: "Poor",
    description: "16%",
    value: "Poor",
    label: "Poor",
    count: 2,
  },
  {
    name: "Fair",
    description: "20%",
    value: "Fair",
    label: "Fair",
    count: 3,
  },
  {
    name: "Good",
    description: "20%",
    value: "Good",
    label: "Good",
    count: 4,
  },
  {
    name: "Very Good",
    description: "20%",
    value: "Very Good",
    label: "Very Good",
    count: 5,
  },
];

const KEY_INSIGHTS = [
  {
    title: "Total Number of Health facilities",
    quantity: "135",
    isTooltip: false,
  },
  {
    title: "Average distance to health facility",
    quantity: "3 km",
    isTooltip: false,
  },
  {
    title: "Average operation hours",
    quantity: "9 Hours",
    isTooltip: false,
  },
  {
    title: "Average Infrastructure Quality",
    quantity: "Good",
    isTooltip: true,
  },
  {
    title: "Total Population",
    quantity: "4,397,073",
    isTooltip: false,
  },
];

// const POPULATION: IPopulationInfo[] = [
//   {
//     color: "red",
//     figure: ">500000",
//   },
//   {
//     color: "yellow",
//     figure: ">400000",
//   },
//   {
//     color: "orange",
//     figure: ">300000",
//   },
//   {
//     color: "green",
//     figure: ">200000",
//   },
//   {
//     color: "lime",
//     figure: ">100000",
//   },
// ];

export interface IHealthListItems {
  id: number;
  name: string;
  subCounty: string;
  ward: string;
  operation: string;
  quality: number;
  contact: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

// const HeatMap = () => {
//   const map = useMap();
//   useEffect(() => {
//     map.setView(map.getCenter(), map.getZoom());
//     const points = addressPoints
//       ? addressPoints.map((p) => {
//           return [p[0], p[1]];
//         })
//       : [];
//     // @ts-ignore
//     const heatLayer = L.heatLayer(points);

//     map.addLayer(heatLayer);
//     return () => {
//       map.removeLayer(heatLayer);
//     };
//   }, []);
//   return null;
// };

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const HealthDashboard = () => {
  const [view, setView] = useState(VIEWS.overview);
  const [dataView, setDataView] = useState(DATA_VIEWS.map);
  const { token } = useAuthContext();
  // const [checked, setChecked] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [healthDetailsItem, setHealthDetailsItem] = useState<
    IHealthDetails | undefined
  >(undefined);

  const { fetchedData: fetchedHealthData } =
    useFetchSabasiData<IHealthDataCountiesApiResponse>(
      `${SABASI_DATA_KEY}?sector=Health`,
      token ? token : ""
    );

  const handleExportRows = (rows: MRT_Row<IHealthData>[]) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(
      rowData.map((r) => ({
        ward: r.ward,
        facility: r.facility,
        type: r.type,
        owner: r.owner,
        nature: r.nature,
        status: r.status,
        open_hours: r.open_hours,
        cadres: r.cadres,
        population: r.population,
        departments: r.departments,
        utilities: r.utilities,
        infrastructure: r.infrastructure,
        beds: r.beds,
        phone: r.phone,
        email: r.email,
        respondent: r.respondent,
        respondent_position: r.respondent_position,
        gps: r.gps,
      }))
    );
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(
      getHealthDataArry(fetchedHealthData).map((r) => ({
        ward: r.ward,
        facility: r.facility,
        type: r.type,
        owner: r.owner,
        nature: r.nature,
        status: r.status,
        open_hours: r.open_hours,
        cadres: r.cadres,
        population: r.population,
        departments: r.departments,
        utilities: r.utilities,
        infrastructure: r.infrastructure,
        beds: r.beds,
        phone: r.phone,
        email: r.email,
        respondent: r.respondent,
        respondent_position: r.respondent_position,
        gps: r.gps,
      }))
    );
    download(csvConfig)(csv);
  };

  // const handleExportPDFRows = (rows: MRT_Row<IHealthData>[]) => {
  //   const doc = new jsPDF();
  //   const tableData = rows.map((row) => Object.values(row.original));
  //   const tableHeaders = columns.map((c) => c.header);

  //   autoTable(doc, {
  //     head: [tableHeaders],
  //     body: tableData,
  //   });

  //   doc.save(`${new Date().toISOString()}_nandi-health.pdf`);
  // };

  useEffect(() => {
    const embed = async () => {
      await embedDashboard({
        id: "dace8231-6c24-423d-bacc-bc254637ed04", // given by the Superset embedding UI
        supersetDomain: "http://localhost:8088",
        mountPoint: document.getElementById("my-superset-container")!, // html element in which iframe render
        fetchGuestToken: async () => await fetchGuestToken(),
        dashboardUiConfig: {
          hideTitle: false,
          hideChartControls: false,
          hideTab: false,
        },
      });
    };
    if (document.getElementById("my-superset-container")) {
      embed();
    }
  }, []);

  const columns = useMemo<MRT_ColumnDef<IHealthData>[]>(
    () => [
      {
        accessorKey: "facility",
        enableEditing: false,
        header: "Facility Name",
      },
      {
        accessorKey: "type",
        header: "Type",
        enableEditing: false,
      },
      {
        accessorKey: "phone",
        header: "Contact",
        enableEditing: false,
      },
      {
        accessorKey: "open_hours",
        header: "Operation",
        enableEditing: false,
      },
      {
        accessorKey: "beds",
        header: "Beds",
        enableEditing: false,
      },
      {
        accessorKey: "infrastructure",
        header: "Infrastructure",
        enableEditing: false,
        Cell: ({ row }) => {
          return (
            <div className="flex items-center gap-3">
              <Rating
                fractions={getRating(row.original.infrastructure)}
                value={getRating(row.original.infrastructure)}
                defaultValue={getRating(row.original.infrastructure)}
                readOnly
                size="sm"
              />
            </div>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        enableEditing: false,
      },
      {
        accessorKey: "utilities",
        header: "Utilities",
        enableEditing: false,
      },
      {
        accessorKey: "ward",
        header: "Ward",
        enableEditing: false,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: getHealthDataArry(fetchedHealthData),
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "cell", // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: false,
    enableRowActions: false,
    getRowId: (row) => (row.id ? row.id.toString() : ""),
    renderTopToolbarCustomActions: ({ table }) => (
      <Menu shadow="md">
        <Menu.Target>
          <MantineTooltip withArrow label="Download">
            <Button leftIcon={<IconDotsVertical />} variant="light">
              Download Data
            </Button>
          </MantineTooltip>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>CSV</Menu.Label>
          <Menu.Item
            icon={<IconDownload size={14} />}
            onClick={handleExportData}
          >
            Export All Data
          </Menu.Item>
          <Menu.Item
            icon={<IconDownload size={14} />}
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
          >
            Export All Rows
          </Menu.Item>
          <Menu.Item
            icon={<IconDownload size={14} />}
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
          >
            Export Page Rows
          </Menu.Item>
          <Menu.Item
            icon={<IconDownload size={14} />}
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          >
            Export Selected Rows
          </Menu.Item>

          <Menu.Divider />

          {/* <Menu.Label>PDF</Menu.Label>
          <Menu.Item
            icon={<IconDownload size={14} />}
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportPDFRows(table.getPrePaginationRowModel().rows)
            }
          >
            Export All Rows
          </Menu.Item>
          <Menu.Item
            icon={<IconDownload size={14} />}
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportPDFRows(table.getRowModel().rows)}
          >
            Export Page Rows
          </Menu.Item>
          <Menu.Item
            icon={<IconDownload size={14} />}
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() =>
              handleExportPDFRows(table.getSelectedRowModel().rows)
            }
          >
            Export Selected Rows
          </Menu.Item> */}
        </Menu.Dropdown>
      </Menu>
      // <Box
      //   sx={{
      //     display: "flex",
      //     gap: "16px",
      //     padding: "8px",
      //     flexWrap: "wrap",
      //   }}
      // >
      //   <Button
      //     color="lightblue"
      //     //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
      //     onClick={handleExportData}
      //     leftIcon={<IconDownload />}
      //     variant="filled"
      //   >
      //     Export All Data
      //   </Button>
      //   <Button
      //     disabled={table.getPrePaginationRowModel().rows.length === 0}
      //     //export all rows, including from the next page, (still respects filtering and sorting)
      //     onClick={() =>
      //       handleExportRows(table.getPrePaginationRowModel().rows)
      //     }
      //     leftIcon={<IconDownload />}
      //     variant="filled"
      //   >
      //     Export All Rows
      //   </Button>
      //   <Button
      //     disabled={table.getRowModel().rows.length === 0}
      //     //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
      //     onClick={() => handleExportRows(table.getRowModel().rows)}
      //     leftIcon={<IconDownload />}
      //     variant="filled"
      //   >
      //     Export Page Rows
      //   </Button>
      //   <Button
      //     disabled={
      //       !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
      //     }
      //     //only export selected rows
      //     onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
      //     leftIcon={<IconDownload />}
      //     variant="filled"
      //   >
      //     Export Selected Rows
      //   </Button>
      // </Box>
    ),
    initialState: {
      density: "xs", //set default density to compact
    },
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    state: {
      isLoading: false,
      isSaving: false,
      showAlertBanner: false,
      showProgressBars: false,
    },
  });

  const healthForm = useForm<{
    facilityName: string;
    subCounty: string[];
    wards: string[];
    utilities: string[];
    operationHours: string[];
    infastructureQuality: string[];
  }>({
    initialValues: {
      subCounty: [],
      wards: [],
      utilities: [],
      infastructureQuality: [],
      operationHours: [],
      facilityName: "",
    },
  });
  const [debounced] = useDebouncedValue(healthForm.values.facilityName, 200);
  const { fetchedData: fetchedHealthCoordinates } =
    useFetchSabasiData<IHealthGeoApiResponse>(
      `${SABASI_GEO_KEY}?sector=Health`,
      token ? token : ""
    );

  const items = [
    { title: "Home", href: "/" },
    { title: "Nandi Health", href: "/health" },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      sx={{
        textTransform: "capitalize",
      }}
    >
      {item.title}
    </Anchor>
  ));

  return (
    <Grid>
      <Grid.Col span={12}>
        <Group position="apart">
          <Breadcrumbs separator={<IconChevronRight size={18} />}>
            {items}
          </Breadcrumbs>
          <SegmentedControl
            size="sm"
            color={PRIMARY_COLOR}
            fullWidth
            sx={{
              background: "transparent",
            }}
            value={dataView}
            onChange={setDataView}
            data={[
              {
                value: DATA_VIEWS.map,
                label: (
                  <Center>
                    <IconMap size="1rem" />
                    <Box ml={5}>Map</Box>
                  </Center>
                ),
              },
              {
                value: DATA_VIEWS.reports,
                label: (
                  <Center>
                    <IconDashboard size="1rem" />
                    <Box ml={5}>Reports</Box>
                  </Center>
                ),
              },
              {
                value: DATA_VIEWS.data_table,
                label: (
                  <Center>
                    <IconListCheck size="1rem" />
                    <Box ml={5}>Data Table</Box>
                  </Center>
                ),
              },
            ]}
          />
        </Group>
      </Grid.Col>
      <Grid.Col span={12}>
        {dataView === DATA_VIEWS.map ? (
          <Grid>
            <Grid.Col sm={12} md={3}>
              {/* <SegmentedControl
                size="md"
                color={PRIMARY_COLOR}
                fullWidth
                value={view}
                onChange={setView}
                data={[
                  {
                    value: VIEWS.overview,
                    label: (
                      <Center>
                        <IconInfoCircle size="1rem" />
                        <Box ml={5}>Overview</Box>
                      </Center>
                    ),
                  },
                  // {
                  //   value: VIEWS.filters,
                  //   label: (
                  //     <Center>
                  //       <IconFilter size="1rem" />
                  //       <Box ml={5}>Filters</Box>
                  //     </Center>
                  //   ),
                  // },
                  // {
                  //   value: VIEWS.download,
                  //   label: (
                  //     <Center>
                  //       <IconDownload size="1rem" />
                  //       <Box ml={5}>Download</Box>
                  //     </Center>
                  //   ),
                  // },
                ]}
              /> */}
              {view === VIEWS.overview ? (
                <Grid>
                  <Grid.Col span={12}>
                    <Title order={3} mb="md">
                      Overview of Nandi Health
                    </Title>
                    <Text fz="sm">
                      Based on a comprehensive Nandi County survey, this report
                      offers insights on healthcare infrastructure, empowering
                      data-driven decisions to enhance local healthcare
                      services.
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Title order={4}>Key Insights</Title>
                  </Grid.Col>
                  {KEY_INSIGHTS.map((insight) => (
                    <Grid.Col span={12} key={insight.title}>
                      <ItemKeyInsights
                        quantity={insight.quantity}
                        title={insight.title}
                        isTooltip={insight.isTooltip}
                        infra="Good"
                      />
                      <Divider mt="md" />
                    </Grid.Col>
                  ))}
                  <Grid.Col span={12}>
                    {/* <Button
                      mt="sm"
                      variant="subtle"
                      leftIcon={<IconDownload />}
                    >
                      Download Full Report
                    </Button> */}
                    {/* <Flex
                      mih={{
                        sm: 80,
                        md: 200,
                      }}
                      gap="md"
                      justify="flex-start"
                      align="flex-end"
                      direction="row"
                      wrap="wrap"
                    >
                      <Group position="apart">
                        <Text fz="xs">{`Last Update: ${new Date().toDateString()}`}</Text>
                        <Text fz="xs">
                          {`Data Source: `}
                          <a
                            href="https://sabasi.mobi/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Sabasi
                          </a>
                        </Text>
                      </Group>
                    </Flex> */}
                  </Grid.Col>
                  {/* <Grid.Col span={12}>
              <Title order={4}>Population-To-Facility Ratio</Title>
            </Grid.Col> */}
                  {/* <Grid.Col span={12}>
              <Group>
                <IconBuildingHospital color="red" />
                <Text fz="lg">
                  <span style={{ fontWeight: "bolder" }}>1</span> Facility to{" "}
                  <span style={{ fontWeight: "bolder" }}>2,345</span> People
                </Text>
              </Group>
            </Grid.Col> */}
                </Grid>
              ) : view === VIEWS.filters ? (
                <form>
                  <Grid mt="md">
                    <Grid.Col span={12}>
                      <Title order={3}>Filters</Title>
                    </Grid.Col>
                    {/* <Grid.Col sm={12} md={12} lg={12}>
                <Switch
                  checked={checked}
                  onChange={(event) => setChecked(event.currentTarget.checked)}
                  label="Population Heatmap Visibility"
                  color={PRIMARY_COLOR}
                />
              </Grid.Col> */}
                    {/* <Grid.Col sm={12} md={12} lg={12}>
                      <TextInput
                        label="Search Facility By Name"
                        disabled={dataView === DATA_VIEWS.data_table}
                        placeholder="Start typing . . "
                        {...healthForm.getInputProps("facilityName")}
                      />
                    </Grid.Col> */}
                    <Grid.Col sm={12} md={12} lg={12}>
                      <MultiSelect
                        data={SUB_COUNTIES}
                        label="Subcounty"
                        searchable
                        disabled={dataView === DATA_VIEWS.data_table}
                        placeholder="Pick all that you like"
                        itemComponent={SelectItem}
                        {...healthForm.getInputProps("subCounty")}
                      />
                    </Grid.Col>
                    <Grid.Col sm={12} md={12} lg={12}>
                      <MultiSelect
                        data={[]}
                        disabled={dataView === DATA_VIEWS.data_table}
                        label="Ward"
                        searchable
                        placeholder="Pick all that you like"
                        {...healthForm.getInputProps("wards")}
                      />
                    </Grid.Col>
                    <Grid.Col sm={12} md={12} lg={12}>
                      <MultiSelect
                        data={AVAILABLE_UTILITIES}
                        disabled={dataView === DATA_VIEWS.data_table}
                        label="Available Utilities"
                        searchable
                        placeholder="Pick all that you like"
                        itemComponent={SelectItem}
                        {...healthForm.getInputProps("utilities")}
                      />
                    </Grid.Col>
                    <Grid.Col sm={12} md={12} lg={12}>
                      <MultiSelect
                        data={OPERATION_HOURS}
                        disabled={dataView === DATA_VIEWS.data_table}
                        label="Operation Hours"
                        searchable
                        placeholder="Pick all that you like"
                        itemComponent={SelectItem}
                        {...healthForm.getInputProps("operationHours")}
                      />
                    </Grid.Col>
                    <Grid.Col sm={12} md={12} lg={12}>
                      <MultiSelect
                        data={INFRA_QUALITY}
                        label="Infrastructure Quality"
                        disabled={dataView === DATA_VIEWS.data_table}
                        searchable
                        placeholder="Pick all that you like"
                        itemComponent={QualitySelectItem}
                        {...healthForm.getInputProps("infastructureQuality")}
                      />
                    </Grid.Col>
                    {/* <Grid.Col sm={12} md={6} lg={6}>
                <Button
                  leftIcon={<IconX />}
                  color={"red"}
                  variant="white"
                  type="button"
                  onClick={() => healthForm.reset()}
                >
                  {"Clear Filter"}
                </Button>
              </Grid.Col>
              <Grid.Col sm={12} md={6} lg={6}>
                <Button
                  leftIcon={<IconFilter />}
                  color={"teal"}
                  //disabled={selectedFilters.length === 0}
                  variant="light"
                  type="submit"
                >
                  {"Apply Filter"}
                </Button>
              </Grid.Col> */}
                  </Grid>
                </form>
              ) : (
                <Grid mt="md">
                  <Title order={3}>Download</Title>
                  <Grid.Col span={12}>
                    <Stack align="stretch" justify="center" spacing="xl">
                      <Button
                        variant="subtle"
                        leftIcon={<IconFileDownload />}
                        disabled={
                          dataView === DATA_VIEWS.reports ||
                          dataView === DATA_VIEWS.map
                        }
                      >
                        Download Excel
                      </Button>
                    </Stack>
                  </Grid.Col>
                </Grid>
              )}
            </Grid.Col>
            <Grid.Col sm={12} md={9}>
              <MapContainer
                id="map-container"
                center={[0.1836, 35.1269]}
                zoom={10}
                scrollWheelZoom={false}
                style={{ height: "760px", width: "100%", zIndex: 0 }}
              >
                {/* {checked && <HeatMap />} */}
                <div
                  className="max-w-2xl absolute top-2 left-12 p-1"
                  style={{ zIndex: 999 }}
                >
                  {[
                    ...healthForm.values.subCounty,
                    ...healthForm.values.wards,
                    ...healthForm.values.operationHours,
                    ...healthForm.values.utilities,
                    ...healthForm.values.infastructureQuality,
                  ].map((item) => (
                    <Badge
                      variant="outline"
                      sx={{
                        backgroundColor: "white",
                      }}
                      p="sm"
                      size="lg"
                      key={item}
                      m={8}
                      // rightSection={
                      //   <ActionIcon size="xs" color="blue" radius="xl" variant="transparent">
                      //     <IconX size={rem(10)} />
                      //   </ActionIcon>
                      // }
                    >
                      {item}
                    </Badge>
                  ))}
                </div>
                {isVisible && (
                  <div
                    className="max-w-md absolute bottom-0 right-1 p-1"
                    style={{ zIndex: 999 }}
                  >
                    <HealthDetails
                      healthCode={
                        healthDetailsItem ? healthDetailsItem.healthCode : 0
                      }
                      setIsVisible={setIsVisible}
                    />
                  </div>
                )}
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <FullscreenControl forceSeparateButton position="topright" />
                <GeoJSON
                  // @ts-ignore
                  data={geojson}
                  style={{
                    weight: 2,
                    opacity: 1,
                    color: "#FF3514",
                    fillOpacity: 0.1,
                    fillColor: "transparent",
                  }}
                />
                <MarkerClusterGroup chunkedLoading>
                  {getHealthCoordinates(fetchedHealthCoordinates).map(
                    (data) => (
                      <Marker
                        key={data.code}
                        position={getCoords(data.gps)}
                        icon={icon({
                          iconUrl: "/marker-icon.png",
                          iconSize: [32, 32],
                        })}
                        eventHandlers={{
                          click: () => {
                            setIsVisible(true);
                            setHealthDetailsItem({
                              setIsVisible: setIsVisible,
                              healthCode: data.code,
                            });
                          },
                        }}
                      >
                        <Tooltip className="request-popup" interactive>
                          <MapPopUp
                            healthCode={data.code}
                            facility={data.facility}
                          />
                        </Tooltip>
                      </Marker>
                    )
                  )}
                  {/* {LIST_ITEMS.filter((p) => p.coordinates !== null).map(
                    (proj) => (
                      <Marker
                        key={proj.id}
                        position={[
                          proj.coordinates.latitude,
                          proj.coordinates.longitude,
                        ]}
                        icon={icon({
                          iconUrl: "/marker-icon.png",
                          iconSize: [24, 24],
                        })}
                        eventHandlers={{
                          click: (e) => {
                            setIsVisible(true);
                            setHealthDetailsItem({
                              setIsVisible: setIsVisible,
                              data: {
                                title: proj.name,
                                contact: proj.contact,
                                email: "-",
                                operation: proj.operation,
                                quality: proj.quality,
                                responder: RESPONDENTS,
                                utilities: ["Water", "Electricity", "Internet"],
                              },
                            });
                          },
                        }}
                      >
                        {/* <Popup className="request-popup">
                        <MapPopUp
                          healthItem={proj}
                          
                        />
                      </Popup> */}
                  {/* <Tooltip className="request-popup" interactive>
                          <MapPopUp healthItem={proj} />
                        </Tooltip>
                      </Marker>
                    )
                  )} */}
                </MarkerClusterGroup>
                {/* {checked && (
                  <div
                    className="min-w-min absolute bottom-2 right-0 p-4"
                    style={{ zIndex: 999 }}
                  >
                    <PopulationInfo item={POPULATION} />
                  </div>
                )} */}
              </MapContainer>
            </Grid.Col>
          </Grid>
        ) : dataView === DATA_VIEWS.reports ? (
          <iframe
            width="100%"
            height="800px"
            seamless
            title="Nandi Health Data"
            style={{ border: 0, overflow: "hidden" }}
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src={REPORTS_URL}
          ></iframe>
        ) : (
          // <div
          //   style={{
          //     width: "100%",
          //     height: "100%",
          //   }}
          // >
          //   <div id="my-superset-container" className="embedded-superset" />
          // </div>
          <Grid>
            <Grid.Col span={12}>
              <MantineReactTable table={table} />
            </Grid.Col>
          </Grid>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default HealthDashboard;
