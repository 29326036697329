import { useForm } from "@mantine/form";
import { TextInput, Grid, Button, Group, Drawer, Select } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import {
  SECTORS_KEY,
  INDICATORS_KEY,
  INewIndicator,
  IIndicator,
  MUTATE_INDICATORS_KEY,
  ISectorsApiData,
} from "../utils/types";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../utils/axios";
import { useAuthContext } from "../store/AuthContext";
import { INewDataProps } from "./NewSector";
import { useEffect } from "react";
import useFetchData from "../hooks/useFetchBudgets";

export interface IUpdateIndicatorProps extends INewDataProps {
  foundIndicator: IIndicator | undefined;
}

const ViewIndicator = ({
  closeDrawer,
  drawerOpened,
  foundIndicator,
}: IUpdateIndicatorProps) => {
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updateIndicator: IIndicator) => {
      await axiosInstance.post(MUTATE_INDICATORS_KEY, updateIndicator, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const form = useForm({
    initialValues: {
      name: "",
      sector_id: "",
      index: "",
      description: "",
      source: "",
    },
    validate: {
      name: (value) =>
        value.length < 4 ? "Name must have at least 4 letters" : null,
      sector_id: (value) => (value.length === 0 ? "Choose the sector" : null),
      index: (value) =>
        value.length === 0 ? "provide the indicator index" : null,
    },
  });

  const {
    fetchedData: fetchedSectors,
    //isError: isLoadingSectorsError,
    //isLoading: isLoadingSectors,
    isFetching: isFetchingSectors,
  } = useFetchData<ISectorsApiData>(SECTORS_KEY, token ? token : "");

  useEffect(() => {
    if (foundIndicator) {
      form.setValues({
        name: foundIndicator.name ? foundIndicator.name : "",
        description: foundIndicator.description
          ? foundIndicator.description
          : "",
        index: foundIndicator.index ? foundIndicator.index : "",
        sector_id: foundIndicator.sector_id
          ? foundIndicator.sector_id.toString()
          : "",
        source: foundIndicator.source ? foundIndicator.source : "",
      });
    }
  }, [foundIndicator]);

  return (
    <Drawer
      opened={drawerOpened}
      position="right"
      size="xl"
      onClose={closeDrawer}
      title="Update Indicator"
      overlayProps={{ opacity: 0.5, blur: 4 }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            if (foundIndicator) {
              await mutation.mutate(
                {
                  ...values,
                  sector_id: Number(values.sector_id),
                  created_at: foundIndicator.created_at,
                  id: foundIndicator.id,
                  updated_at: foundIndicator.updated_at,
                  sector: foundIndicator.sector,
                  method:
                    foundIndicator.method.length > 0
                      ? foundIndicator.method
                      : "SUM",
                },
                {
                  onSuccess: () => {
                    notifications.show({
                      title: "Success",
                      message: "Indicator Updated",
                      icon: <IconCheck />,
                      color: "teal",
                    });
                    closeDrawer();
                  },
                  onSettled(data, error, variables, context) {
                    queryClient.invalidateQueries([INDICATORS_KEY]);
                  },
                  onError: (
                    error: any,
                    variables: INewIndicator,
                    context: unknown
                  ) => {
                    notifications.show({
                      title: "Error",
                      message:
                        error && error.data
                          ? error.data
                          : "An error occured, try again",
                      icon: <IconX />,
                      color: "red",
                    });
                  },
                }
              );
            }
          } catch (error) {}
        })}
      >
        <Grid>
          <Grid.Col sm={12} md={12} lg={12}>
            <Select
              label="Sector"
              placeholder="Sector"
              disabled={isFetchingSectors}
              withAsterisk
              {...form.getInputProps("sector_id")}
              data={
                fetchedSectors && Array.isArray(fetchedSectors.data)
                  ? fetchedSectors.data.map((sector) => ({
                      value: sector.id.toString(),
                      label: sector.name,
                    }))
                  : []
              }
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              withAsterisk
              label="Indicator Index"
              placeholder="index"
              {...form.getInputProps("index")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              withAsterisk
              label="Name"
              placeholder="name"
              {...form.getInputProps("name")}
            />
          </Grid.Col>

          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              label="Description"
              placeholder="Description"
              {...form.getInputProps("description")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={6}>
            <TextInput
              label="Source"
              placeholder="Source"
              {...form.getInputProps("source")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group position="right" spacing="xl">
              <Button
                leftIcon={<IconPlus size="1rem" />}
                type="submit"
                variant="filled"
                loading={mutation.isLoading}
              >
                Submit
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Drawer>
  );
};

export default ViewIndicator;
