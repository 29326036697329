import { ActionIcon, Button, Grid, Text, Title, Tooltip } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_SortingState,
  MRT_Virtualizer,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import NewProject from "../sections/NewProject";
import { useDisclosure } from "@mantine/hooks";
import { useAuthContext } from "../store/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IProject,
  IProjectsApiResponse,
  MUTATE_PROJECTS_KEY,
  PROJECTS_KEY,
} from "../utils/types";
import axiosInstance from "../utils/axios";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconEdit, IconX } from "@tabler/icons-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { getProjects } from "../utils/fns";
import { IconTrash } from "@tabler/icons-react";
import { fNumber } from "../utils/formatNumber";
import ViewProject from "../sections/ViewProject";
import useFetchData from "../hooks/useFetchBudgets";

const Projects = () => {
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [
    updateDrawerOpened,
    { open: openUpdateDrawer, close: closeUpdateDrawer },
  ] = useDisclosure(false);
  const [foundProject, setFoundProject] = useState<IProject | undefined>(
    undefined
  );
  const { token } = useAuthContext();
  const queryClient = useQueryClient();
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  useEffect(() => {
    try {
      //scroll to the top of the table when the sorting changes
      rowVirtualizerInstanceRef.current?.scrollToIndex(0);
    } catch (e) {
      console.log(e);
    }
  }, [sorting]);

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await axiosInstance.delete(`${MUTATE_PROJECTS_KEY}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const {
    fetchedData: fetchedProjects,
    isError: isLoadingProjectsError,
    // isFetching: isFetchingDepartments,
    isLoading: isLoadingProjects,
  } = useFetchData<IProjectsApiResponse>(PROJECTS_KEY, token ? token : "");

  const openDeleteConfirmModal = (row: MRT_Row<IProject>) =>
    modals.openConfirmModal({
      title: `Delete ${row.original.name}`,
      children: (
        <Text>
          Are you sure you want to delete this project? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await deleteMutation.mutate(row.id, {
            onSuccess: () => {
              notifications.show({
                title: "Success",
                message: "Project deleted",
                icon: <IconCheck />,
                color: "teal",
              });
            },
            onError(error: any, variables, context) {
              notifications.show({
                title: "Error",
                message:
                  error && error.data
                    ? error.data
                    : "An error occured, try again",
                icon: <IconX />,
                color: "red",
              });
            },
            onSettled(data, error, variables, context) {
              queryClient.invalidateQueries([PROJECTS_KEY]);
            },
          });
        } catch (error) {}
      },
    });

  const columns = useMemo<MRT_ColumnDef<IProject>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "department.name",
        enableEditing: false,
        header: "Department",
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.department ? row.original.department.name : "-"}
            </Text>
          );
        },
      },
      {
        accessorKey: "constituency.name",
        enableEditing: false,
        header: "Constituency",
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.constituency ? row.original.constituency.name : "-"}
            </Text>
          );
        },
      },
      {
        accessorKey: "ward.name",
        enableEditing: false,
        header: "Ward",
        Cell: ({ row }) => {
          return (
            <Text>{row.original.ward ? row.original.ward.name : "-"}</Text>
          );
        },
      },
      {
        accessorKey: "amount",
        enableEditing: false,
        header: "Amount",
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.amount ? fNumber(row.original.amount) : "-"}
            </Text>
          );
        },
      },
      {
        accessorKey: "year",
        enableEditing: false,
        header: "Period",
      },
    ],
    []
  );

  const handleUpdateProject = (row: MRT_Row<IProject>) => {
    setFoundProject(row.original);
    openUpdateDrawer();
  };

  const table = useMantineReactTable({
    columns,
    data: getProjects(fetchedProjects),
    createDisplayMode: "row", // ('modal', and 'custom' are also available)
    editDisplayMode: "cell", // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    enablePagination: false,
    getRowId: (row) => (row.id ? row.id.toString() : ""),
    initialState: {
      density: "xs", //set default density to compact
    },
    mantineToolbarAlertBannerProps: isLoadingProjectsError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderRowActions: ({ row }) => (
      <div className="flex flex-row">
        <Tooltip label="View">
          <ActionIcon onClick={() => handleUpdateProject(row)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </div>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="p-2">
        <Button variant="filled" size="sm" onClick={openDrawer}>
          Add Project
        </Button>
      </div>
    ),
    state: {
      isLoading: isLoadingProjects,
      isSaving: false,
      showAlertBanner: isLoadingProjectsError,
      showProgressBars: false,
    },
    enableBottomToolbar: false,
    enableColumnResizing: true,
    enableColumnVirtualization: true,
    enableGlobalFilterModes: true,
    enablePinning: true,
    enableRowNumbers: true,
    enableRowVirtualization: true,
    onSortingChange: setSorting,
    rowVirtualizerInstanceRef, //optional
    rowVirtualizerProps: { overscan: 5 }, //optionally customize the row virtualizer
    columnVirtualizerProps: { overscan: 2 }, //optionally customize the column virtualizer
  });

  return (
    <Grid>
      <Grid.Col span={12}>
        <Title className="styled-heading styled-heading-large">Projects</Title>
      </Grid.Col>
      <Grid.Col span={12}>
        <MantineReactTable table={table} />
      </Grid.Col>
      <Grid.Col span={12}>
        <NewProject closeDrawer={closeDrawer} drawerOpened={drawerOpened} />
      </Grid.Col>
      <Grid.Col span={12}>
        <ViewProject
          closeDrawer={closeUpdateDrawer}
          drawerOpened={updateDrawerOpened}
          foundProject={foundProject}
        />
      </Grid.Col>
    </Grid>
  );
};

export default Projects;
