import { Group, Paper, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { IDashboardStatsCard } from "./DashboardStatsCard";

const MidDashboardItem = ({ items }: { items: IDashboardStatsCard }) => {
  const { count, icon, label, to } = items;
  return (
    <Paper withBorder radius="md" p="xl" shadow="md">
      <Group position="apart">
        <div>
          <Text fw={700} size="md" component={Link} to={to}>
            {count}
          </Text>
          <Text c="dimmed" size="xs" fw={700}>
            {label}
          </Text>
        </div>
        {icon}
      </Group>
    </Paper>
  );
};

export default MidDashboardItem;
