import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { TextInput, Button, PasswordInput } from "@mantine/core";
import { IconLogin, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { DASHBOARD_PATH, PRIMARY_COLOR } from "../utils/config";
import { useAuthContext } from "../store/AuthContext";
import { AxiosResponse } from "axios";
import { ILoginApiResponse } from "../utils/types";
import axiosInstance from "../utils/axios";
import useFetchCountyLogo from "../hooks/useFetchCountyLogo";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { logo, isLoading: isLoadingCountyLogo } = useFetchCountyLogo();
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      password: (value) =>
        value.length < 4 ? "Password must have at least 4 letters" : null,
      email: (value) =>
        /^\S+@\S+$/.test(value) ? null : "provide a valid email",
    },
  });

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        {!isLoadingCountyLogo && (
          <img
            src={logo}
            alt="cms"
            className="max-w-xs max-h-44 w-auto mx-auto object-cover"
          />
        )}
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="mb-2 w-80 max-w-screen-lg sm:w-96"
            onSubmit={form.onSubmit(async ({ email, password }) => {
              setIsLoading(true);
              try {
                const { data }: AxiosResponse<ILoginApiResponse> =
                  await axiosInstance.post(
                    "/token",
                    { email, password },
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );

                if (data.code !== 200) {
                  notifications.show({
                    message:
                      typeof data === "string"
                        ? data
                        : "error signing you you in, please try again",
                    color: "red",
                    icon: <IconX />,
                  });
                }
                login(data.data.user, data.data.token);
                setIsLoading(false);
                navigate(DASHBOARD_PATH, { replace: true });
              } catch (error) {
                setIsLoading(false);
                console.log(error);
                notifications.show({
                  message: "error signing you you in, please try again",
                  color: "red",
                  icon: <IconX />,
                });
              }
            })}
          >
            <div className="mb-4 flex flex-col gap-6">
              <TextInput
                mt="md"
                label="Email"
                placeholder="Email"
                {...form.getInputProps("email")}
              />
              <PasswordInput
                label="Password"
                placeholder="Password"
                {...form.getInputProps("password")}
              />
            </div>
            <Button
              leftIcon={<IconLogin size="1rem" />}
              type="submit"
              variant="filled"
              fullWidth
              loading={isLoading}
            >
              Submit
            </Button>
          </form>
          <p className="mt-10 text-center text-sm text-gray-500">
            Forgot Password?{" "}
            <Link
              to="/forgot-password"
              className={`font-semibold leading-6 text-${PRIMARY_COLOR}-700 hover:text-${PRIMARY_COLOR}-700`}
            >
              Click here to reset
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
